import ApiEasycatService from '@/api/new/services/api.easycat.service'

const state = {
  sharedTbs: [],
  tbLanguages: [],
  termBanks: [],
  tbFilter: {},
  tbPages: {
    current: 1,
    total: 1,
    totalSegments: 10,
    pageSize: 10,
    from: 1,
    to: 10
  },
  currentTb: null,
  terms: [],
  replaceTerms: [],
  replaceLanguage: '',
  searchLanguages: [],
  segmentsFilter: {},
  termsPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 15
  },
  editedTerm: null,
  term: {
    id: '',
    usage: [],
    history: []
  },
  isUsageLoading: false,
  isHistoryLoading: false,

  // Select TB
  selectedTbs: [],
  selectAllFiltered: false,

  // Select replace
  selectedReplaceSegments: [],
  selectReplaceAll: false
}

const mutations = {
  setSharedTbs(state, tbs) {
    state.sharedTbs = tbs
  },
  setTbLanguages(state, lang) {
    state.tbLanguages = lang
  },
  setTermBanks(state, tbs) {
    state.termBanks = tbs
  },
  updateTbFilter(state, { value, type }) {
    if (type && state.tbFilter[type] !== value) {
      state.tbFilter = {
        ...state.tbFilter,
        [type]: value
      }
      state.tbPages.current = 1
    }
  },
  resetTbFilter(state) {
    state.tbFilter = {}
  },
  setTbPages(state, tbPages) {
    state.tbPages.current = tbPages.current_page
    state.tbPages.total = tbPages.last_page
    state.tbPages.totalSegments = tbPages.total
    state.tbPages.pageSize = tbPages.per_page
    state.tbPages.from = tbPages.from
    state.tbPages.to = tbPages.to
  },
  setCurrentTb(state, tb) {
    state.currentTb = tb
  },
  setTermsPages(state, termsPages) {
    state.termsPages = termsPages
  },
  setTerms(state, { segments, replace }) {
    if (replace) state.replaceTerms = segments
    else {
      state.terms = segments
      state.replaceTerms = segments
    }
  },
  setReplaceLanguage(state, lang) {
    state.replaceLanguage = lang
  },
  setSearchLanguages(state, langs) {
    state.searchLanguages = langs
  },
  updateSegmentsFilter(state, filter) {
    state.segmentsFilter = { ...filter }
  },
  setEditedTerm(state, term) {
    state.editedTerm = term
  },
  setTerm(state, { id, usage, history }) {
    if (id) state.term.id = id
    if (usage) state.term.usage = usage
    if (history) state.term.history = history
  },
  setIsUsageLoading(state, bool) {
    state.isUsageLoading = bool
  },
  setIsHistoryLoading(state, bool) {
    state.isHistoryLoading = bool
  },

  // Select TB
  selectTb(state, tb) {
    if (!state.selectedTbs.includes(tb)) {
      state.selectedTbs = [...state.selectedTbs, tb]
    }
  },
  deselectTb(state, tb) {
    const index = state.selectedTbs.indexOf(tb)
    if (index > -1) {
      let result = [...state.selectedTbs]
      result.splice(index, 1)
      state.selectedTbs = result
    }
    if (state.selectedTbs.length === 0) {
      state.selectAllFiltered = false
    }
  },
  setSelectedTbs(state, tbs) {
    state.selectedTbs = tbs
  },
  setSelectAll(state, bool) {
    state.selectAllFiltered = bool
  },

  // Select replace
  selectReplaceSegment(state, tb) {
    if (!state.selectedReplaceSegments.includes(tb)) {
      state.selectedReplaceSegments = [...state.selectedReplaceSegments, tb]
    }
  },
  deselectReplaceSegment(state, tb) {
    const index = state.selectedReplaceSegments.indexOf(tb)
    if (index > -1) {
      let result = [...state.selectedReplaceSegments]
      result.splice(index, 1)
      state.selectedReplaceSegments = result
    }
    if (state.selectedReplaceSegments.length === 0) {
      state.selectReplaceAll = false
    }
  },
  setSelectedReplaceSegments(state, tbs) {
    state.selectedReplaceSegments = tbs
  },
  setSelectReplaceAll(state, bool) {
    state.selectReplaceAll = bool
  }
}

const actions = {
  // Term Banks
  async getStateSharedTbs({ state, dispatch }) {
    if (state.sharedTbs.length === 0) await dispatch('getSharedTbs')
  },
  async getSharedTbs({ commit, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/shared/all`
    )
      .then((res) => {
        commit('setSharedTbs', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateTbLanguages({ state, dispatch }) {
    if (state.tbLanguages.length === 0) await dispatch('getTbLanguages')
  },
  async getTbLanguages({ commit, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/languages`
    )
      .then((res) => {
        const langs = res.data
        commit('setTbLanguages', langs)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTermBanks({ state, commit, rootGetters }, payload) {
    const params = new URLSearchParams()
    params.append('page', payload?.page || state.tbPages.current)
    if (Object.values(state.tbFilter).length > 0) {
      Object.keys(state.tbFilter).forEach((key) => {
        if (state.tbFilter[key]) {
          if (key === 'languages') {
            state.tbFilter[key].forEach((l) => {
              params.append('languages[]', l)
            })
          } else {
            params.append(key, state.tbFilter[key])
          }
        }
      })
    }
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks`,
      { params }
    )
      .then((res) => {
        commit('setTermBanks', res.data.data)
        commit('setTbPages', res.data.meta)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async importTb({ dispatch, rootGetters }, payload) {
    const data = new FormData()
    data.append('data[type]', 'term_banks')
    data.append('data[attributes][name]', payload.name)

    if (payload.term_bank_id) {
      data.append('data[attributes][term_bank_id]', payload.term_bank_id)
    }

    const readFileAsBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64Content = reader.result.replace(/^data:.+;base64,/, '')
          resolve(base64Content)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }

    for (let y = 0; y < payload.files.length; y++) {
      try {
        const base64Content = await readFileAsBase64(payload.files[y])
        data.append(`data[attributes][content][${y}]`, base64Content)
      } catch (err) {
        throw new Error(err)
      }
    }

    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/import-from-csv`,
      data
    )
      .then(async () => {
        await dispatch('getSharedTbs')
        await dispatch('getTbLanguages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  async createTb({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks`,
      data
    )
      .then(async () => {
        await dispatch('getTermBanks')
        await dispatch('getTbLanguages')
        await dispatch('getSharedTbs')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTb({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${payload.id}`,
      data
    )
      .then(async () => {
        await dispatch('getTermBanks')
        await dispatch('getTb', { id: payload.id, getTerms: payload.getTerms })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTb({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ids: payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/destroy`,
      data
    )
      .then(async () => {
        await dispatch('getTermBanks')
        await dispatch('getTbLanguages')
        await dispatch('getSharedTbs')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportTb({ rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/export`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async getTb({ commit, dispatch, rootGetters }, payload) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${payload.id}`
    )
      .then(async (res) => {
        commit('setCurrentTb', res.data.data)
        if (payload.getTerms) await dispatch('getTerms', { page: 1 })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTerms({ state, commit, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const params = new URLSearchParams()
    params.append('page', payload?.page || state.termsPages.current)
    if (Object.values(state.segmentsFilter).length > 0) {
      Object.keys(state.segmentsFilter).forEach((key) => {
        if (state.segmentsFilter[key]) {
          if (key === 'languages') {
            state.segmentsFilter[key].forEach((l) => {
              params.append('languages[]', l)
            })
          } else {
            params.append(key, state.segmentsFilter[key])
          }
        }
      })
    }
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms`,
      { params }
    )
      .then((res) => {
        const pages = {
          current: res.data.current_page,
          total: res.data.last_page,
          totalSegments: res.data.total,
          pageSize: res.data.per_page
        }
        commit('setTerms', {
          segments: res.data.data,
          replace: payload?.replace || false
        })
        commit('setTermsPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  // Term
  async getTerm({ dispatch, commit, state }, id) {
    if (state.term.id === id) return
    await commit('setTerm', { id })
    dispatch('getTermUsage', id)
    dispatch('getTermHistory', id)
  },
  async createTerm({ state, dispatch, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms`,
      data
    )
      .then(async () => {
        if (payload?.concept_id === state?.term?.id)
          await dispatch('getTermHistory', payload.concept_id)
        await dispatch('getTerms')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTerm({ state, dispatch, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/${payload.termId}`,
      data
    )
      .then(async () => {
        await dispatch('getTermHistory', state.term.id)
        await dispatch('getTerms')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSourceType({ state, dispatch, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          terms_ids: [payload]
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/bulk-human`,
      data
    )
      .then(async () => {
        await dispatch('getTerms', { id: tbId })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTerm({ state, getters, dispatch, rootGetters }) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          ids: getters.termIds
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/destroy`,
      data
    )
      .then(async () => {
        await dispatch('getTerms', { id: tbId })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async copyTerm({ state, getters, dispatch, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          concept_ids: getters.conceptIds,
          target_term_bank_id: payload.targetTb
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/copy`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          {
            message: 'customer.toast.success.terms_copied',
            hasDynamicValues: true,
            boldOptions: true,
            options: [{ name: 'tb', value: payload.targetTbName }]
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async replaceTerm({ state, getters, dispatch, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          segment_ids: getters.replaceTermIds,
          language_code: state.replaceLanguage,
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/replace-all`,
      data
    )
      .then(async () => {
        await dispatch('getTerms')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportTerm({ state, getters, rootGetters }, payload) {
    const tbId = state.currentTb.id
    const data = {
      data: {
        type: 'terms',
        attributes: {
          ids: getters.termIds,
          ...(payload ? { email: payload } : {})
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/export`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async getTermHistory({ commit, state, rootGetters }, id) {
    const tbId = state.currentTb.id
    const perPage = state.currentTb.attributes.languages.length
    commit('setIsHistoryLoading', true)
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/${id}/history?perPage=${perPage}`
    )
      .then((res) => {
        if (state.term.id === id) commit('setTerm', { history: res.data.data })
        commit('setIsHistoryLoading', false)
      })
      .catch((err) => {
        commit('setIsHistoryLoading', false)
        throw new Error(JSON.stringify(err))
      })
  },
  async getTermUsage({ commit, state, rootGetters }, id) {
    const tbId = state.currentTb.id
    commit('setIsUsageLoading', true)
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${tbId}/terms/${id}/usage`
    )
      .then((res) => {
        if (state.term.id === id) commit('setTerm', { usage: res.data.data })
        commit('setIsUsageLoading', false)
      })
      .catch((err) => {
        commit('setIsUsageLoading', false)
        throw new Error(JSON.stringify(err))
      })
  },

  // Select
  async selectAll({ state, commit }, payload) {
    commit('setSelectAll', payload.isSelected)
    if (payload.overview) {
      const tbs = state.termBanks.map((tb) => tb.id)
      if (payload.isSelected) commit('setSelectedTbs', tbs)
      else commit('setSelectedTbs', [])
    } else {
      if (payload.isSelected) commit('setSelectedTbs', [...state.terms])
      else commit('setSelectedTbs', [])
    }
  },
  async selectAllReplace({ state, commit }, payload) {
    commit('setSelectReplaceAll', payload.isSelected)
    const terms = state.replaceTerms.map((s) => s.id)
    if (payload.isSelected) commit('setSelectedReplaceSegments', terms)
    else commit('setSelectedReplaceSegments', [])
  }
}

const getters = {
  conceptIds: (state) => {
    const termIds = state.selectedTbs.map((term) => term.id)
    return termIds
  },
  termIds: (state) => {
    const termIds = state.selectedTbs.flatMap((term) =>
      Object.values(term.attributes).map((lang) => lang.id)
    )
    return termIds
  },
  replaceTermIds: (state) => {
    const termIds = state.selectedReplaceSegments.flatMap((term) =>
      Object.values(term.attributes).map((lang) => lang.id)
    )
    return termIds
  }
}

export const termBanks = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default {
  "2FA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA"])},
  "GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
  "SSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO"])},
  "chat.title.project_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project messages"])},
  "customer.account_info.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to edit your account? Change your contact details? You can do that here."])},
  "customer.account_info.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello!"])},
  "customer.account_info.existing_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that there is already a registered account with your domain.\n\nThe owner of the account will receive a request to add your user into their account."])},
  "customer.account_structore.error.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only the parent account has the ability to view and create new accounts."])},
  "customer.account_structore.error.no_account.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find any accounts"])},
  "customer.account_structore.error.no_account.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Accounts"])},
  "customer.account_structore.modal.information.dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department / sister company"])},
  "customer.account_structore.modal.information.team_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team identifier"])},
  "customer.account_structore.modal.information.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account information"])},
  "customer.account_structore.modal.settings.terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I would like to transfer the ", _interpolate(_list(0)), " of this account to the new one."])},
  "customer.account_structore.modal.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "customer.account_structore.modal.settings.tos.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing details"])},
  "customer.account_structore.modal.settings.tos.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price list"])},
  "customer.account_structore.modal.settings.tos.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["translation team"])},
  "customer.account_structore.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create sub-account"])},
  "customer.account_structore.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My accounts"])},
  "customer.ai_file.error_codes.complex_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex XLSX found. Please remove the content inside the script tags (e.g. <script>)"])},
  "customer.ai_task.download.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File last generated: ", _interpolate(_named("timestamp")), "\nBefore downloading the file, ensure the timestamp above is after your last changes in the internal review. If it’s earlier, your changes aren’t included. Wait to download until the timestamp is updated."])},
  "customer.ai_task.download.wait_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your file will be ready for download in approx. ", _interpolate(_named("min")), " minutes"])},
  "customer.api.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want an even faster and easier process when ordering translations with us? With our API solution, we guarantee a seamless integration within your own platform."])},
  "customer.api.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart technology"])},
  "customer.api.api_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api documentation link"])},
  "customer.api.client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "customer.api.client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret"])},
  "customer.area_of_work.modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will help us improve your EasyTranslate experience"])},
  "customer.area_of_work.modal.localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation team"])},
  "customer.area_of_work.modal.marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing & Design team"])},
  "customer.area_of_work.modal.operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations team"])},
  "customer.area_of_work.modal.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "customer.area_of_work.modal.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product team"])},
  "customer.area_of_work.modal.tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software & Tech team"])},
  "customer.area_of_work.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What team are you on?"])},
  "customer.automation.ai_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our AI translation to translate your content"])},
  "customer.automation.ai_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI Translation"])},
  "customer.automation.complete_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish the project"])},
  "customer.automation.complete_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Project"])},
  "customer.automation.create_caas_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate product description text from keywords"])},
  "customer.automation.create_caas_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Generation"])},
  "customer.automation.create_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your content for translation"])},
  "customer.automation.create_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload source file"])},
  "customer.automation.inital.step.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment triggers"])},
  "customer.automation.inital.step.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the triggers that will initiate your workflow."])},
  "customer.automation.internal_caas_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your generated content"])},
  "customer.automation.internal_caas_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Content Review"])},
  "customer.automation.internal_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have your own internal user make a revision on the translated content"])},
  "customer.automation.internal_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Review"])},
  "customer.automation.machine_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our machine translation to translate your content"])},
  "customer.automation.machine_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Translation (MT)"])},
  "customer.automation.project_action.assets_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If no ", _interpolate(_named("asset")), " exists yet, go to <i>Language Assets</i> and create one by clicking <i>Add new</i>"])},
  "customer.automation.project_action.primary_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Primary"])},
  "customer.automation.project_action.secondary_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Secondary"])},
  "customer.automation.project_action.template_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you leave this field empty, a default template for your chosen source language will be created"])},
  "customer.automation.qa.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have your own internal supplier make a Quality Assurance on a sample from translated content"])},
  "customer.automation.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.automation.qa_action.accuracy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy"])},
  "customer.automation.qa_action.accuracy_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong meaning, errors in numbers and references, omissions or incorrect tags."])},
  "customer.automation.qa_action.file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum sample size"])},
  "customer.automation.qa_action.frequency_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
  "customer.automation.qa_action.frequency_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The frequency determines how often a QA task will be executed. Fx. if your frequency is set to 2 out of 10, it will roughly translate to every 5th task being QA'd. Please note that the QA task will always be counted based on the chosen target language and the subject chosen for QA"])},
  "customer.automation.qa_action.language_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "customer.automation.qa_action.language_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctuation, spelling, capitalization or grammar errors."])},
  "customer.automation.qa_action.locale_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locale"])},
  "customer.automation.qa_action.locale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors in country standards like date formats and currencies, and cultural considerations."])},
  "customer.automation.qa_action.performing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator performing QA"])},
  "customer.automation.qa_action.performing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the task will be assigned to the first translator you select. If this translator declines, it will be assigned to the following translators you have selected, in chronological order."])},
  "customer.automation.qa_action.style_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
  "customer.automation.qa_action.style_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stylistic errors, disregard of specific guidelines or language register/language variant errors."])},
  "customer.automation.qa_action.subject_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator subject to QA"])},
  "customer.automation.qa_action.subject_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that the translator you are selecting to be subject to the QA, is the same translator that you selected to be assigned to the task. If that supplier declines, your QA task will automatically be cancelled."])},
  "customer.automation.qa_action.terminology_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminology"])},
  "customer.automation.qa_action.terminology_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconsistent use of terminology or disregard of industry typical/provided glossary."])},
  "customer.automation.review_action.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no reviewers in your team for this combination. We will automatically assign a reviewer from our service"])},
  "customer.automation.settings.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can edit the settings of your flow."])},
  "customer.automation.settings.toast.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your settings for this automation were successfully updated"])},
  "customer.automation.settings.toast.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated settings"])},
  "customer.automation.sidebar.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an action"])},
  "customer.automation.start_string_library_automation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create translation automation for existing String Library"])},
  "customer.automation.start_string_library_automation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String Library"])},
  "customer.automation.status_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow is"])},
  "customer.automation.step.btn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Step ", _interpolate(_named("step"))])},
  "customer.automation.step_complete.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully set up your workflow step"])},
  "customer.automation.step_complete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully set up your workflow step"])},
  "customer.automation.step_complete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step complete"])},
  "customer.automation.step_incomplete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your settings for Automation workflow step"])},
  "customer.automation.step_incomplete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step incomplete"])},
  "customer.automation.string_library.action.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To generate content, select library from product."])},
  "customer.automation.string_library.action.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libraries"])},
  "customer.automation.string_library.step.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation or product trigger set to"])},
  "customer.automation.supplier_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match one of our freelancers to review your translated content"])},
  "customer.automation.supplier_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "customer.automation.supplier_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match one of our freelancers to translate your content"])},
  "customer.automation.supplier_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation"])},
  "customer.automation.unsupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow unsupported for subcription"])},
  "customer.automations.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't created any flows."])},
  "customer.automations.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No flows"])},
  "customer.automations.steps.ai_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI Translation"])},
  "customer.automations.steps.complete_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Project"])},
  "customer.automations.steps.create_caas_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation"])},
  "customer.automations.steps.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload source file"])},
  "customer.automations.steps.internal_caas_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal content review"])},
  "customer.automations.steps.internal_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal review"])},
  "customer.automations.steps.machine_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Translation (MT)"])},
  "customer.automations.steps.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.automations.steps.start_string_library_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String library"])},
  "customer.automations.steps.supplier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "customer.automations.steps.supplier_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation"])},
  "customer.automations.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create flow"])},
  "customer.balance.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No balance to be shown."])},
  "customer.balance.type.adjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustment"])},
  "customer.balance.type.applied_to_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied to invoice"])},
  "customer.balance.type.credit_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit note"])},
  "customer.balance.type.invoice_too_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice too small"])},
  "customer.banner.book_meeting.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Need help or have questions? Book a meeting with Customer Success ", _interpolate(_named("link"))])},
  "customer.banner.plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plan"])},
  "customer.banner.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your plan now"])},
  "customer.banner.visit.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visit our ", _interpolate(_named("link")), " for more information"])},
  "customer.billing.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your billing information and payment method."])},
  "customer.billing.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments made easy"])},
  "customer.billing.information.billing_portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View billing portal"])},
  "customer.billing.information.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing information"])},
  "customer.billing.methods.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add payment method"])},
  "customer.billing.methods.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add and manage your payment methods using our secure payment system."])},
  "customer.billing.methods.payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose payment methods"])},
  "customer.billing.methods.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
  "customer.billing.missing_billing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add missing billing information to proceed with MT training"])},
  "customer.billing.missing_billing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing billing information"])},
  "customer.build_team.copywriters.selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have selected ", _interpolate(_named("number")), " freelancers"])},
  "customer.build_team.copywriters.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have carefully selected our best freelancers but you can always change our recommendation."])},
  "customer.build_team.copywriters.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on a freelancer you can see their detailed profile."])},
  "customer.build_team.copywriters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your preferred freelancer(s)"])},
  "customer.build_team.copywriters_confirm.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm selected copy editors(s)"])},
  "customer.build_team.copywriting.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a copy editor to your team"])},
  "customer.build_team.page_five.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no available freelancers for the requested language combination, please reach out to us via the chat to see if we can help."])},
  "customer.build_team.page_five.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following freelancer(s) will be added to your team."])},
  "customer.build_team.page_five.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm selected freelancer(s)"])},
  "customer.build_team.page_four.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an optional Quality Assurance performer for your translation"])},
  "customer.build_team.page_four.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected translator(s) do not offer Quality Assurance services"])},
  "customer.build_team.page_four.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.build_team.page_one.file_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that your file can be no larger than 300KB"])},
  "customer.build_team.page_one.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a file so we can match your text with our AI-based technology."])},
  "customer.build_team.page_one.text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this will not create a project, but it will build your translation team."])},
  "customer.build_team.page_one.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This way we will ensure the best fit between you and our freelancers."])},
  "customer.build_team.page_one.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your team of freelancers"])},
  "customer.build_team.page_three.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an optional reviewer for your translation"])},
  "customer.build_team.page_three.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected translator(s) do not offer revision services"])},
  "customer.build_team.page_three.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "customer.build_team.page_two.selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have selected ", _interpolate(_named("number")), " freelancers"])},
  "customer.build_team.page_two.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have carefully selected our best freelancers but you can always change our recommendation."])},
  "customer.build_team.page_two.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on a freelancer you can see their detailed profile."])},
  "customer.build_team.page_two.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your preferred freelancer(s)"])},
  "customer.button.calculate.cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate project cost"])},
  "customer.caas_key_modal.library_keywords.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in product keywords for product library each separated with a comma. Use minimum 5 and maximum 25 keywords. To increase the generated text quality we suggest to use the keywords in logical order."])},
  "customer.caas_key_modal.library_keywords.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords for product library"])},
  "customer.caas_key_modal.sku_tooltip.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your unique product name (SKU) to create a unique key for product library"])},
  "customer.caas_key_modal.sku_tooltip.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product key name / SKU"])},
  "customer.content_generation.document.create.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document was created successfully"])},
  "customer.content_generation.document.delete.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document was deleted successfully"])},
  "customer.content_generation.document.name.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document's name has been updated successfully"])},
  "customer.content_generation.template.delete.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The template was deleted successfully"])},
  "customer.content_generation.template.save.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The template was saved successfully"])},
  "customer.content_pillars.button.create_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new pillar"])},
  "customer.content_pillars.button.save_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save pillar"])},
  "customer.content_pillars.buttons.create_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create one"])},
  "customer.content_pillars.clusters_overview.add_pages_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add pages"])},
  "customer.content_pillars.clusters_overview.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "customer.content_pillars.clusters_overview.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "customer.content_pillars.clusters_overview.in_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In draft"])},
  "customer.content_pillars.clusters_overview.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "customer.content_pillars.create_content.add_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add content settings"])},
  "customer.content_pillars.create_content.button.show_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to overview"])},
  "customer.content_pillars.create_content.chars_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both \"Characters\" and \"Words\" are empty. One of them must be higher than 0"])},
  "customer.content_pillars.create_content.drag_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag or use the arrows to select"])},
  "customer.content_pillars.create_content.enter_wordcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many words/characters?"])},
  "customer.content_pillars.create_content.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final result"])},
  "customer.content_pillars.create_content.results_pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick one, some, or all and let's get started!"])},
  "customer.content_pillars.create_content.select_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content type"])},
  "customer.content_pillars.create_content.select_suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select number of suggestions"])},
  "customer.content_pillars.create_content.select_wordcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the parameters for your content"])},
  "customer.content_pillars.create_content.suggestions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Suggestions"])},
  "customer.content_pillars.create_content.suggestions_happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you happy?"])},
  "customer.content_pillars.create_content.suggestions_pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick the ones you like"])},
  "customer.content_pillars.create_content.suggestions_regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "customer.content_pillars.label.add_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add content"])},
  "customer.content_pillars.label.describe_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a short description of your business or enter your URL"])},
  "customer.content_pillars.label.describe_pillar_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll generate keywords from this"])},
  "customer.content_pillars.label.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am empty"])},
  "customer.content_pillars.label.name_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your pillar"])},
  "customer.content_pillars.no_copyeditors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You do not have any copyeditors in your team of translators. Please click ", _interpolate(_named("here")), " to add a copyeditor freelancer to your team."])},
  "customer.copyediting.animation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to send your content for copy editing?"])},
  "customer.create_flow.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create flow"])},
  "customer.create_flow.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your flow and give it a description"])},
  "customer.create_flow.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow name"])},
  "customer.create_flow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your flow here"])},
  "customer.create_project.modal.missing_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must update your billing details before creating new project"])},
  "customer.create_project.modal.missing_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least one payment method before creating a project"])},
  "customer.create_project.modal.missing_translators": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You do not have a team of freelancers. Please click ", _interpolate(_named("here")), " to add a freelancer to your team or select Machine Translation"])},
  "customer.create_project.modal.subscription_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscription has ended. Check your usage ", _interpolate(_named("here")), " ", _interpolate(_named("link")), " or contact us using the chat on the bottom right corner."])},
  "customer.document.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document name already exists"])},
  "customer.feature.animation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is included in your package"])},
  "customer.feature.automation_workflow.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to set up your first Automation Workflow!"])},
  "customer.feature.automation_workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminate the need for manual handling of recurring workflows and save valuable time. This automation feature allows you to streamline your processes, ensuring that each task is performed consistently and accurately."])},
  "customer.feature.automation_workflow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhance productivity and efficiency!"])},
  "customer.feature.cg.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to generate content!"])},
  "customer.feature.cg.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation is an AI-based copywriting tool that generates compelling copy for blog posts, product descriptions, sales emails, social media posts, lead nurturing, ads and more. Optionally, it connects to a marketplace of copy editors for additional human finetuning."])},
  "customer.feature.cg.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create enticing content in a fraction of the time"])},
  "customer.feature.chat.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to start!"])},
  "customer.feature.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective communication is crucial when working on a task as it ensures everyone involved understands their roles and responsibilities. Good communication not only boosts productivity but also improves teamwork and fosters a positive work environment.\nCommunicate with your translators via our message function."])},
  "customer.feature.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate effectively with your freelancers"])},
  "customer.feature.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralise your content around the domain you are working with and let us generate multiple pieces of different content for the same subject! You'll only need to define the headline(s) and word count, and the rest is up to your copy editors."])},
  "customer.feature.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your content roadmap that focuses on SEO optimised content!"])},
  "customer.feature.cta_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s get started"])},
  "customer.feature.freelancers.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to add a freelancer!"])},
  "customer.feature.freelancers.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a freelancer to your team if you want to add a human touch to any of your task types! \nSelect among our many freelancers who either translate, copy edit, or review tasks."])},
  "customer.feature.freelancers.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need a human touch?"])},
  "customer.feature.mt.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to learn more!"])},
  "customer.feature.mt.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*This feature requires that you have a translation memory stored with us."])},
  "customer.feature.mt.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A typical machine translation engine will pull your content through a generic engine, which in most cases doesn't generate the best output when your content is better than generic. This engine takes your translation memory and teaches it to be biased towards your input in previous translations. This means that the topic of your translation is biased towards your existing data with us."])},
  "customer.feature.mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have your own personalised Machine Translation engine to generate powerful machine translations!"])},
  "customer.feature.string_library.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to create a library!"])},
  "customer.feature.string_library.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a great tool for both developers as well as anyone working with content. Manage your keys and values in one place. \nUsing a String Library simplifies processes significantly, making your code and content cleaner and more efficient. It also helps reduce the chances of errors."])},
  "customer.feature.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralise your content in one place!"])},
  "customer.folder.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder name already exists"])},
  "customer.folders.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No folders created."])},
  "customer.folders.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Folders"])},
  "customer.freemium.upgrade_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us to upgrade"])},
  "customer.freemium_modal.header_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below is a list of the different pricing tiers for this feature"])},
  "customer.freemium_modal.tier_table.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price / month"])},
  "customer.freemium_modal.tier_table.tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tier"])},
  "customer.freemium_modal.tier_table.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage limit"])},
  "customer.history.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history to show"])},
  "customer.history.status.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
  "customer.history.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "customer.history.status.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "customer.history.status.uncollectible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncollectible"])},
  "customer.history.status.void": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Void"])},
  "customer.history.type.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "customer.history.type.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscription"])},
  "customer.history.type.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "customer.invitation.empy.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong: this invitation doesn't exist or it has expired"])},
  "customer.invitation.empy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation error"])},
  "customer.invitation.team.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your invitation to ", _interpolate(_named("team")), " has been successfully declined"])},
  "customer.invitation.team.login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("link")), " to go to login"])},
  "customer.invitation.team.send_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An invitation was sent to ", _interpolate(_named("email"))])},
  "customer.invitation.team.success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Success! You have now joined ", _interpolate(_named("team"))])},
  "customer.invitation.team.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("team")), " has invited you to be part of their account."])},
  "customer.label.no_translators_internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO TRANSLATOR - INTERNAL"])},
  "customer.label.reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign"])},
  "customer.lang_assets.actions_modal.archive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to archive ", _interpolate(_named("assetType")), " ", _interpolate(_named("asset")), ".\nThe ", _interpolate(_named("assetTypeSmall")), " can be found in “Archived” and will no longer be displayed in this view"])},
  "customer.lang_assets.actions_modal.delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete this ", _interpolate(_named("assetTypeSmall")), "?"])},
  "customer.lang_assets.actions_modal.export": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to export ", _interpolate(_named("assetType")), " ", _interpolate(_named("asset"))])},
  "customer.lang_assets.actions_modal.export_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send export to (optional)"])},
  "customer.lang_assets.actions_modal.export_tootlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The export will be sent to the provided email address. If no email is entered, it will be sent to the email you are logged in with instead."])},
  "customer.lang_assets.actions_modal.import": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to import ", _interpolate(_named("assetType")), " into ", _interpolate(_named("asset"))])},
  "customer.lang_assets.add_start.start.file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your file(s)"])},
  "customer.lang_assets.add_start.start.glossary_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your glossary"])},
  "customer.lang_assets.add_start.start.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual upload of bilingual files"])},
  "customer.lang_assets.add_start.start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start from scratch!"])},
  "customer.lang_assets.add_start.start.tm_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your translation memory"])},
  "customer.lang_assets.add_start.upload.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("upload")), " your assets"])},
  "customer.lang_assets.case_sensitivity.confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to ", _interpolate(_named("state")), " case sensitivity?"])},
  "customer.lang_assets.case_sensitivity.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case sensitivity"])},
  "customer.lang_assets.content_type.human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human"])},
  "customer.lang_assets.content_type.modal.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change the entry type to ", _interpolate(_named("type")), "?"])},
  "customer.lang_assets.content_type.synthetic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthetic"])},
  "customer.lang_assets.create_modal.create_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Glossary"])},
  "customer.lang_assets.create_modal.create_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Translation Memory"])},
  "customer.lang_assets.create_modal.edit_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Glossary"])},
  "customer.lang_assets.create_modal.edit_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Translation Memory"])},
  "customer.lang_assets.create_modal.enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate after creation"])},
  "customer.lang_assets.create_modal.next_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next segment"])},
  "customer.lang_assets.create_modal.previous_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous segment"])},
  "customer.lang_assets.create_modal.source_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source value"])},
  "customer.lang_assets.create_modal.tags.primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
  "customer.lang_assets.create_modal.tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select tags"])},
  "customer.lang_assets.create_modal.target_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target value"])},
  "customer.lang_assets.create_modal.term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new term"])},
  "customer.lang_assets.create_modal.term_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term value"])},
  "customer.lang_assets.create_modal.tm_segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create TM segment"])},
  "customer.lang_assets.create_modal_tb.account_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an account to apply glossary"])},
  "customer.lang_assets.create_modal_tm.account_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an account to apply TM"])},
  "customer.lang_assets.entry_type.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry type"])},
  "customer.lang_assets.segment.add_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add translation"])},
  "customer.lang_assets.segment.metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
  "customer.lang_assets.segment.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "customer.lang_assets.upload.instructions.languages_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make sure to check that your languages codes are aligned with ours.\nClick ", _interpolate(_named("here")), " to see our language mapping"])},
  "customer.lang_assets.upload.instructions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How should your .csv file be structured?"])},
  "customer.lang_assets.upload.instructions_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each language should be placed in their own column"])},
  "customer.lang_assets.upload.instructions_tb.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note we only accept .csv files when uploading Glossaries"])},
  "customer.lang_assets.upload.instructions_tm.a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column A should contain the source segment"])},
  "customer.lang_assets.upload.instructions_tm.b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column B should contain the previous source segment that comes before Column A"])},
  "customer.lang_assets.upload.instructions_tm.c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column C should contain the next source segment that comes after Column A"])},
  "customer.lang_assets.upload.instructions_tm.d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column D should contain the first target language"])},
  "customer.lang_assets.upload.instructions_tm.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note we only accept .csv files when uploading TM’s"])},
  "customer.lang_assets.upload.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add multiple at once"])},
  "customer.lang_assets.upload.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload your ", _interpolate(_named("type"))])},
  "customer.lang_assets.used_in_workflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used in workflows"])},
  "customer.lang_assets.used_on_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used on accounts"])},
  "customer.lang_assets.user_type.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])},
  "customer.language_assets.add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
  "customer.language_assets.import_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import new"])},
  "customer.language_assets.overview_table.accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied on accounts"])},
  "customer.language_assets.overview_table.languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "customer.language_assets.overview_table.last_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last edited"])},
  "customer.language_assets.term_bank.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossaries"])},
  "customer.language_assets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Assets"])},
  "customer.language_assets.translation_memories.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memories"])},
  "customer.language_assets.translation_memory.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "customer.libraries.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create library"])},
  "customer.libraries.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No library created."])},
  "customer.libraries.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No library"])},
  "customer.libraries.modal.create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create product library"])},
  "customer.libraries.modal.create_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create translation library"])},
  "customer.libraries.modal.delete_language.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete language"])},
  "customer.libraries.modal.delete_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete library"])},
  "customer.libraries.modal.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your translation library informations"])},
  "customer.libraries.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library name already exists"])},
  "customer.libraries.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in all required information"])},
  "customer.libraries.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your library"])},
  "customer.libraries.title.add_new_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new language"])},
  "customer.library.caas.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add product key"])},
  "customer.library.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "customer.library.shared.btn.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download library"])},
  "customer.library.shared.btn.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start automation"])},
  "customer.library.shared.modal.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("name"))])},
  "customer.library.shared.modal.delete_key.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete key"])},
  "customer.library.shared.modal_download.nest_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nest strings"])},
  "customer.library.shared.modal_download.option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your preferred option"])},
  "customer.library.shared.modal_download.option_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all languages"])},
  "customer.library.shared.modal_download.option_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose languages"])},
  "customer.library.shared.modal_download.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to download ", _interpolate(_named("name")), " library as a ", _interpolate(_named("json")), " file."])},
  "customer.library.shared.modal_download.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download library"])},
  "customer.library.shared.modal_start.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to start automation on ", _interpolate(_named("name"))])},
  "customer.library.shared.modal_start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation Flow"])},
  "customer.library.shared.modal_start_filtered.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to start your automation for all strings matching your filter"])},
  "customer.library.shared.modal_start_selected.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to start your automation for the selected strings"])},
  "customer.library.translation.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add key"])},
  "customer.library.translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
  "customer.machine_translation.custom_mt.view.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start MT training"])},
  "customer.machine_translation.custom_mt.view.cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel training"])},
  "customer.machine_translation.custom_mt.view.cancel_training_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected model's training was cancelled"])},
  "customer.machine_translation.custom_mt.view.estimate_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate cost"])},
  "customer.machine_translation.custom_mt.view.start_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start training"])},
  "customer.machine_translation.custom_mt.view.success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your training model was started"])},
  "customer.marketplace.app.benner.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "customer.marketplace.app.blackbird.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you’re looking to create content from scratch, translate your website, webshop, CMS or more - we have all the tools you need to succeed. Integrate with our many no-code plugins and automate your entire translation and content generation processes."])},
  "customer.marketplace.app.contentful.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Contentful plugin allows you to sync your content from your content types into our string library, where you can apply our features within the String Library to either automate or translate yourself, then pull them back to Contentful. It's as simple as clicking two buttons."])},
  "customer.marketplace.app.episerver.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect EasyTranslate to your Episerver site and instantly send and receive translations. Please note that installing this plugin will download a zipfile which you'll need to install."])},
  "customer.marketplace.app.figma.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Figma plugin allows you to connect to a library, sync your strings, have them translated, and have them sent back, without having to leave Figma"])},
  "customer.marketplace.app.github.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GitHub action is our way of helping developers automate their translation processes. It helps you translate your localisation files from your codebase using only your source language, then creating the necessary translated files in your target language."])},
  "customer.marketplace.app.hygraph.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Hygraph plugin allows you to send all your website content to our platform with just a few clicks. The content goes into our String Library, gets translated, and then gets sent back to Hygraph."])},
  "customer.marketplace.app.magento_1.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect EasyTranslate to your Magento eCommerce website to instantly send and receive translations. Please note that installing this plugin will download a zipfile which you'll need to install."])},
  "customer.marketplace.app.magento_2.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect EasyTranslate to your Magento eCommerce website to instantly send and receive translations."])},
  "customer.marketplace.app.phrase.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our phrase connector allows you to send projects seamlessly via Phrase, with just a few clicks. Your content gets translated and goes straight back into your Phrase flow."])},
  "customer.marketplace.app.shopify.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect EasyTranslate with your Shopify webshop to receive translations straight within your backend."])},
  "customer.marketplace.app.shopware.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect EasyTranslate to your Shopware eCommerce website to instantly send and receive translations. Please note that installing this plugin will download a zipfile which you'll need to install."])},
  "customer.marketplace.app.wordpress.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your life easier by connecting EasyTranslate to your WordPress site to instantly send and receive translations."])},
  "customer.marketplace.app.zapier.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapier lets you connect with EasyTranslate to automatically integrate translations within the apps you use every day."])},
  "customer.marketplace.item.kb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("app_text")), " To learn more, click ", _interpolate(_named("link"))])},
  "customer.marketplace.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visit our ", _interpolate(_named("link")), " to get a step-by-step guide on how to install the integrations."])},
  "customer.marketplace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our no-code plugins seamlessly integrate with your other systems."])},
  "customer.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your translators are ready to smartly communicate with you in regards of specific tasks"])},
  "customer.modal.fill_out_form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please review us on ", _interpolate(_named("name")), " by filling out the form below"])},
  "customer.modal.fill_out_form_supplier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["G2 is the world's largest marketplace for business reviews and helps software companies to create credibility and trust among their customers. Please fill out the form below to review EasyTranslate on ", _interpolate(_named("name"))])},
  "customer.modal.freemium_accounts.ai.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get on-brand content in any language, and experience better than human translations that are 10x faster and with assurance of human quality, at just 0.01€ per translated word. Book a meeting with a Customer Success Manager to learn more about HumanAI and receive free onboarding on a language."])},
  "customer.modal.freemium_accounts.ai.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the future of translation with HumanAI!"])},
  "customer.multilingual_project.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The multilingual file is unavailable until all languages are complete. If you need individual languages that are already complete, please reach out to support."])},
  "customer.nav.account.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "customer.nav.automation_flows.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation Flows"])},
  "customer.nav.automation_workflows.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation Workflows"])},
  "customer.nav.copywriting.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing"])},
  "customer.nav.dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "customer.nav.getStarted.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
  "customer.nav.libraries.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libraries"])},
  "customer.nav.marketplace.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
  "customer.nav.overview.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])},
  "customer.nav.overview.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])},
  "customer.nav.payment.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "customer.nav.phrase_connector.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase Connector"])},
  "customer.nav.pricing.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
  "customer.nav.progress.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "customer.nav.progress.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "customer.nav.string_libraries.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String Libraries"])},
  "customer.nav.translation_assets.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom MT"])},
  "customer.nav.translation_assets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Custom Machine Translation engine"])},
  "customer.nav.translators.breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build team"])},
  "customer.nav.translators.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancers"])},
  "customer.nav.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your plan"])},
  "customer.news.02-10-2023.task_layout.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-th-large"])},
  "customer.news.02-10-2023.task_layout.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We listened to your feedback, and we’ve just released a new layout to your project view. You can now easily see what stage your project is in, as well as easily rate your freelancer, apply the editor and download your project."])},
  "customer.news.02-10-2023.task_layout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New task layout!"])},
  "customer.news.04-09-2023.automation_workflow_fix.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.04-09-2023.automation_workflow_fix.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now use our Automatic String Library feature. Set your threshold, and once it has passed, we will automatically start the translation proces."])},
  "customer.news.04-09-2023.automation_workflow_fix.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation workflows fix!"])},
  "customer.news.04-09-2023.invite_colleagues.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.04-09-2023.invite_colleagues.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have added the “Invite users” to the sidebar so you can easily add any colleagues to the platform."])},
  "customer.news.04-09-2023.invite_colleagues.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite your colleagues to the platform"])},
  "customer.news.04-09-2023.kb_access.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-question"])},
  "customer.news.04-09-2023.kb_access.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any unanswered questions? You can now access the Knowledge Base from the sidebar."])},
  "customer.news.04-09-2023.kb_access.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick access to our Knowledge Base!"])},
  "customer.news.04-09-2023.mt_preselect.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.04-09-2023.mt_preselect.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now pre-select languages for MT when creating a project. NB! This is a Enterprise feature."])},
  "customer.news.04-09-2023.mt_preselect.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Enterprise feature!"])},
  "customer.news.04-09-2023.remove_supplier.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user"])},
  "customer.news.04-09-2023.remove_supplier.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a team admin, you now have the ability to remove suppliers yourself, from your team."])},
  "customer.news.04-09-2023.remove_supplier.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove suppliers from your team"])},
  "customer.news.06-07-2022.data.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-shield"])},
  "customer.news.06-07-2022.data.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manual process of asking customer service for access to your personal data is no longer needed.\n\nSimply export your data if you would like to know what information has been stored. \n\nYou can now also request the deletion of your data in just a few clicks - straight on our platform."])},
  "customer.news.06-07-2022.data.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access your personal data"])},
  "customer.news.06-07-2022.sso.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user"])},
  "customer.news.06-07-2022.sso.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to our platform in an easy, yet secure way!\nSingle sign-on (SSO) is an identification method that enables you to log in to multiple applications with one set of credentials.  The social login feature allows you to authenticate yourself on various applications using existing information from a social networking service.\nKeep in mind that this only works with Gmail as of now."])},
  "customer.news.06-07-2022.sso.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO login"])},
  "customer.news.06-07-2022.two_factor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-shield"])},
  "customer.news.06-07-2022.two_factor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We now provide 2FA for a safer access to your account! This lets you verify your identity twice when logging on to the platform. First, with a username and password. Second,  by approving the authentication request with an authenticator app on your phone.\n2FA protects against phishing, social engineering and password brute-force attacks. It also secures your login as  many passwords are unfortunately too weak and easy to guess."])},
  "customer.news.06-07-2022.two_factor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
  "customer.news.07-07-2022.new_privacy_terms.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "customer.news.07-07-2022.new_privacy_terms.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://easytranslate.com/en/terms-and-conditions-localisation-software-platform/"])},
  "customer.news.07-07-2022.new_privacy_terms.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We made changes to our Terms and Conditions. Please read them carefully here: ", _interpolate(_named("link"))])},
  "customer.news.07-07-2022.new_privacy_terms.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "customer.news.08-12-2020.translate_yourself.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.08-12-2020.translate_yourself.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We now introduce an extra workflow for your translation process called Translate Yourself. You are now able to choose Translate Yourself in the project creation overview, which allows your project to be machine translated and returned directly into your editor without any manual steps. This option gives you the ability to seamlessly engage inhouse translators to do smaller instant translations with the same effective tools."])},
  "customer.news.08-12-2020.translate_yourself.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate Yourself"])},
  "customer.news.12-06-2023.terms.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "customer.news.12-06-2023.terms.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have made a minor change to our Terms and Conditions. Check it out, it’s in the Term and Termination section."])},
  "customer.news.12-06-2023.terms.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A small update to our T&C’s!"])},
  "customer.news.13-07-2022.github_action.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-code"])},
  "customer.news.13-07-2022.github_action.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GitHub action is our way of helping developers automate their translation processes. It helps you translate your localisation files from your codebase using only your source language, then creating the necessary translated files in your target language."])},
  "customer.news.13-07-2022.github_action.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github action"])},
  "customer.news.13-08-2020.payment_history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-invoice"])},
  "customer.news.13-08-2020.payment_history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's now possible to get a full overview of all project invoices. To get the full picture, please go to Settings, select the Payment tab and then the History sub-tab. You can see all your account invoices in a simple overview. You are also able to download invoices in a print-ready version."])},
  "customer.news.13-08-2020.payment_history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment history"])},
  "customer.news.13-08-2020.rating.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-star"])},
  "customer.news.13-08-2020.rating.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are excited to launch our newly designed feature - ratings. Ratings allow to provide feedback for the translator on each completed project. The individual rating is not available for the translator, but they can see the average of their scores. We use your ratings to categorize your translators according to an area of expertise and to pinpoint future training that might improve their skills."])},
  "customer.news.13-08-2020.rating.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
  "customer.news.13-08-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.13-08-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finally, we can reveal our first version of the EasyTranslate Review Editor, which gives you the ability to review translations done by your team of translators.The revisions are updated in your translation memory for instant improvement of future translations. Please don't hesitate to give your account manager feedback on this big feature."])},
  "customer.news.13-08-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review editor"])},
  "customer.news.13-10-2023.content_pillars.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.13-10-2023.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A content roadmap tool that focuses on SEO optimised content - Content Pillars!\nWe’ve created a tool that centralises your content around the domain you work with, so you can create multiple pieces of content in bulk. All you need to do is set your parametres, and your copy editor will do the rest."])},
  "customer.news.13-10-2023.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ve just added a new feature - Content Pillars!"])},
  "customer.news.14-02-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-cog"])},
  "customer.news.14-02-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have added a toolbar so you can create more accurate content. Please note that you have to be on a paid plan to apply them."])},
  "customer.news.14-02-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Content Generation tools!"])},
  "customer.news.14-02-2023.upgrade.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-chevron-up"])},
  "customer.news.14-02-2023.upgrade.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a freemium user, you can now upgrade directly from the Usage page."])},
  "customer.news.14-02-2023.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade within the platform!"])},
  "customer.news.16-09-2020.filters.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-filter"])},
  "customer.news.16-09-2020.filters.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for using our app so frequently. With the increased usage, we realize the importance of filters. You are now able to filter projects by creation date and created by among others. We will improve the filter and search functionality on an ongoing basis."])},
  "customer.news.16-09-2020.filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "customer.news.16-09-2020.messages.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "customer.news.16-09-2020.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have experienced the importance of being able to communicate directly with your translators regarding specific projects. We are therefore introducing Messages. Messages is your way of communicating directly with your translators. To create a message to your translator, you go to the project and choose the task with the translator you would like to message."])},
  "customer.news.16-09-2020.messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "customer.news.16-09-2020.new_task_view.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-th-large"])},
  "customer.news.16-09-2020.new_task_view.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have optimized your user experience, with New task view. With the recent release of review editor and ratings we realized that a new user experience was needed. You will now be available to see all your task under a given project as cards like you know it from your workspace."])},
  "customer.news.16-09-2020.new_task_view.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Task view"])},
  "customer.news.21-06-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-cog"])},
  "customer.news.21-06-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have now replaced our previous Content Generation tool, with our new version, thus taking it out of beta. Go check it out in the left sidebar."])},
  "customer.news.21-06-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Content Generation Version!"])},
  "customer.news.21-06-2023.upgrade.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-chevron-up"])},
  "customer.news.21-06-2023.upgrade.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have made several tools and buttons more visible and user-friendly in the Editor tool as well as in the String Library."])},
  "customer.news.21-06-2023.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrades within the platform!"])},
  "customer.news.21-07-2023.text_area.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.21-07-2023.text_area.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have now added a free text area that allows you to attach a comment or instructions when you create a project!"])},
  "customer.news.21-07-2023.text_area.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New text field for projects"])},
  "customer.news.23-01-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.23-01-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have just released our newest AI-content tool allowing you to create high quality content with just a few words! We currently support emails, blogs, ads, and product descriptions, and we'll be adding much more in the future!"])},
  "customer.news.23-01-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out our newest AI-content tool!"])},
  "customer.news.23-01-2023.menu.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-bars"])},
  "customer.news.23-01-2023.menu.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have placed your settings and news section inside the profile menu on the bottom left corner of the application. We have also moved the Message function to the left sidebar."])},
  "customer.news.23-01-2023.menu.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes to the menu!"])},
  "customer.news.23-09-2020.capacity_calculated_deadline.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-stopwatch"])},
  "customer.news.23-09-2020.capacity_calculated_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now calculating your deadline based on your favourite translators’ capacity. This means we take other projects that are ongoing with your translator into consideration to give you a more precise deadline."])},
  "customer.news.23-09-2020.capacity_calculated_deadline.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity calculated deadline"])},
  "customer.news.23-09-2020.new_price_list_review.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-invoice-dollar"])},
  "customer.news.23-09-2020.new_price_list_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To give you a clear overview of the cost involved with Translation and Review, we have added a price list for Review. You are always able to contact your Account Manager to get more information about prices and options."])},
  "customer.news.23-09-2020.new_price_list_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New price list for Review"])},
  "customer.news.23-09-2020.translation_and_review.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.23-09-2020.translation_and_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are introducing an extra workflow to optimise translation quality, Translation and Review. You are now able to choose Translation and Review in the Project creation overview. Your project is now both translated by your favourite translator and also reviewed by one of your other favourite translators. NB. You need a team of minimum 2 translators within the language combo to enable this feature. Please contact your Account Manager to expand your team."])},
  "customer.news.23-09-2020.translation_and_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation and Review"])},
  "customer.news.24-11-2021.automation_workflow.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.24-11-2021.automation_workflow.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We are excited to present to you one of our latest features : the ", _interpolate(_named("title")), ". You are now able to design your own workflow(s) to match specific teams, projects, and needs. Remove any repetitive process and ensure all operational steps are as accurate and efficient as possible by creating automation workflows. Once your workflow is active, simply choose it during the creation of your new project."])},
  "customer.news.24-11-2021.automation_workflow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation workflow"])},
  "customer.news.24-11-2021.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-comments"])},
  "customer.news.24-11-2021.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To give you a better visibility on what you are writing, we have reviewed and implemented the design of the chat input in the editor. You no longer have to navigate through what you have written to read or modify it, the input field is now displaying your content in lines to give you a full overview. NB. The chat feature is only available when one or more translator(s) of your team is involved in the project."])},
  "customer.news.24-11-2021.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "customer.news.24-11-2021.history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-history"])},
  "customer.news.24-11-2021.history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The EasyTranslate review Editor has received a new functionality you will like: the history. Thanks to the history, keep track of all changes made to a segment at any given time. restore previous versions or simply analyse the review process, the choice is yours."])},
  "customer.news.24-11-2021.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "customer.news.24-11-2021.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.24-11-2021.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is now possible to get an overview of the number of words and characters a segment contains. Simply click on a segment and look at the bottom left of the table. In addition to that, you can now see the correct ID number of each segment, even when you change pages."])},
  "customer.news.24-11-2021.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review editor"])},
  "customer.news.24-11-2021.string_library.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.24-11-2021.string_library.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We are constantly working on improving your experience with our platform. That being said, we have released a new feature called ", _interpolate(_named("title")), " that enables you to integrate data to your pages and posts with less complexity and more flexibility. Create individual libraries for your particular operating systems, add data to your library internally or with the help of a translator and benefit from faster deployment of your content. With title, you can reuse your data at any given time without being bound to files."])},
  "customer.news.24-11-2021.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String library"])},
  "customer.news.25-01-2022.string_library.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-database"])},
  "customer.news.25-01-2022.string_library.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Already loving the ", _interpolate(_named("title")), "? Now you can also translate your strings straight through the Library. Once you have set up a workflow for it, select your languages, and you are ready to start sending strings off for translation!"])},
  "customer.news.25-01-2022.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String Library"])},
  "customer.news.25-01-2022.usage.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-dollar-sign"])},
  "customer.news.25-01-2022.usage.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://easytranslate.com/en/pricing/"])},
  "customer.news.25-01-2022.usage.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Have you seen our new ", _interpolate(_named("title")), " pricing plan, based on volume and graduated pricing? Go to ", _interpolate(_named("link")), " to learn more, or reach out to us via the chat!"])},
  "customer.news.25-01-2022.usage.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "customer.news.26-10-2022.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "customer.news.26-10-2022.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out our new chat in the top right corner! You can now create groups and channels, attach files and images and much more!"])},
  "customer.news.26-10-2022.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and improved chat!"])},
  "customer.news.29-04-2021.search_within_the_editor_feature.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.29-04-2021.search_within_the_editor_feature.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Translation Memory and Term Bank are now accessible and searchable within the Editor tool, so you can ensure consistency in no time without leaving the page. Use the search field in the revision phase to look up words and see if they match your Translation Memory and/or Term Bank. All matches found will be highlighted in the source section of your content. Need to monitor your character count? These are now visible in both the source and target fields too."])},
  "customer.news.29-04-2021.search_within_the_editor_feature.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search within the Editor feature"])},
  "customer.news.29-04-2022.automated_content.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.29-04-2022.automated_content.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instead of spending time writing a 300-word product text, simply enter a few keywords, and Content Generation will create the text for you and store it in segments. You will also benefit from the AI-content Editor, where you are able to select the segments you like, edit, and combine them into the final product text."])},
  "customer.news.29-04-2022.automated_content.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated content"])},
  "customer.news.29-04-2022.improved_workflows.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-angles-up"])},
  "customer.news.29-04-2022.improved_workflows.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your localisation processes even more customisable than before as now you can assign a specific translator to a task in your workflow. \n\nYou get to select 1st, 2nd, and 3rd priorities for which translator you want to be in charge of your translation and revision tasks. With this improvement, you are also able to cover multiple languages in a shorter time."])},
  "customer.news.29-04-2022.improved_workflows.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved workflows"])},
  "customer.news.29-04-2022.qa.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-star"])},
  "customer.news.29-04-2022.qa.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your workflow becomes less manual because you are now able to attach a Quality Assurance task to your actual translation task. You can select the translator being reviewed with the QA as well as the translator performing the procedure. Customise the QA according to your priorities - you decide which parameters should weigh the most (language, style, locale, accuracy, or terminology)."])},
  "customer.news.29-04-2022.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.news.29-04-2022.translation_reports.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.29-04-2022.translation_reports.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a full overview of how many tasks have been processed, who uploaded them, which workflows were used and much more"])},
  "customer.news.29-04-2022.translation_reports.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Reports"])},
  "customer.news.29-07-2020.multiple_files_translation_projects.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.29-07-2020.multiple_files_translation_projects.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now ready to handle multiple-files projects which means that when you create a project you can now attach multiple files. By doing this, you will create multiple projects at once. And to keep your workspace organised when doing this, you will need to appoint the new projects to either an existing folder or just create a new one."])},
  "customer.news.29-07-2020.multiple_files_translation_projects.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create multiple-files translation projects"])},
  "customer.news.29-07-2020.remove_users_from_your_team.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.29-07-2020.remove_users_from_your_team.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have invited one of your colleagues by mistake or you just want to remove his access, now you can easily remove it from your team. This means that the user will no longer have access to your workspace. See the remove button from Teams > Users."])},
  "customer.news.29-07-2020.remove_users_from_your_team.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove users from your team"])},
  "customer.news.29-07-2020.translation_projects_into_folders.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.29-07-2020.translation_projects_into_folders.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If before you could only see all your projects and create new ones, now you can group them smartly into folders within your account's workspace. It's very simple! You either create a new folder by clicking the new folder button from the right side of the my workspace page or you can do it when needed during project creation."])},
  "customer.news.29-07-2020.translation_projects_into_folders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize your translation projects into folders"])},
  "customer.news.29-07-2020.users_can_decline_invitations.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.29-07-2020.users_can_decline_invitations.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your invited users can now decline an invitation."])},
  "customer.news.29-07-2020.users_can_decline_invitations.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can decline invitations"])},
  "customer.news.31-08-2022.custom_mt.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.31-08-2022.custom_mt.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Custom MT will allow you to have your own personalised Machine Translation engine, based on your content that you already have with us.\nWe reuse you current Translation Memory so we can make the MT engine smarter for you, and more biased towards the topics of your existing translations."])},
  "customer.news.31-08-2022.custom_mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom MT"])},
  "customer.onboarding.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a meeting"])},
  "customer.onboarding.btn.login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Already have an account? Log in ", _interpolate(_named("link"))])},
  "customer.onboarding.choose_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose connection"])},
  "customer.onboarding.create_account.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created. You can now log in to access your account."])},
  "customer.onboarding.loader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making sure all the i's have dots..."])},
  "customer.onboarding.loader_signup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One mississippi, two mississippi, three mississippi…. almost there!"])},
  "customer.onboarding.offline_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline source"])},
  "customer.overview.folders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folders"])},
  "customer.overview.projects.animation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first project!"])},
  "customer.overview.projects.animation.title.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making sure all the i's have dots..."])},
  "customer.overview.projects.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "customer.payment.usage.pricing_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check our pricing"])},
  "customer.permission.approve-project-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve Project Price"])},
  "customer.permission.create-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Billing Method"])},
  "customer.permission.create-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Sub-Account"])},
  "customer.permission.create-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Content Generation Document"])},
  "customer.permission.create-content-generation-document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to create new Content Generation documents"])},
  "customer.permission.create-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Content Generation Template"])},
  "customer.permission.create-content-generation-template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to create new Content Generation templates"])},
  "customer.permission.create-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Project"])},
  "customer.permission.create-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Folder"])},
  "customer.permission.create-task-revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Task Revision"])},
  "customer.permission.create-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Team Member"])},
  "customer.permission.create-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Custom Team Role"])},
  "customer.permission.create-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create translation assets"])},
  "customer.permission.create-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to create translation assets"])},
  "customer.permission.create-translation-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Freelancers"])},
  "customer.permission.create-translation-team.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to add freelancers to the team"])},
  "customer.permission.create-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Webhook Endpoint"])},
  "customer.permission.create-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create workflow"])},
  "customer.permission.create-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to create a workflow"])},
  "customer.permission.delete-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Billing Method"])},
  "customer.permission.delete-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Sub-Account"])},
  "customer.permission.delete-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Content Generation Document"])},
  "customer.permission.delete-content-generation-document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to delete Content Generation documents"])},
  "customer.permission.delete-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Content Generation Template"])},
  "customer.permission.delete-content-generation-template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to delete Content Generation templates"])},
  "customer.permission.delete-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Folder"])},
  "customer.permission.delete-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Team Member"])},
  "customer.permission.delete-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Custom Team Role"])},
  "customer.permission.delete-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete translation assets"])},
  "customer.permission.delete-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to delete translation assets"])},
  "customer.permission.delete-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Webhook Endpoint"])},
  "customer.permission.delete-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete workflow"])},
  "customer.permission.delete-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to delete a workflow"])},
  "customer.permission.download-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Invoice"])},
  "customer.permission.download-project-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Project Files"])},
  "customer.permission.join-channel-chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Chat Channel"])},
  "customer.permission.join-channel-chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to join chat channel"])},
  "customer.permission.read-billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Billing Details"])},
  "customer.permission.read-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Billing Methods"])},
  "customer.permission.read-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Sub-Accounts"])},
  "customer.permission.read-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Content Generation Documents"])},
  "customer.permission.read-content-generation-document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to see Content Generation documents"])},
  "customer.permission.read-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Content Generation Templates"])},
  "customer.permission.read-content-generation-template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to see Content Generation templates"])},
  "customer.permission.read-oauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View OAuth Credentials"])},
  "customer.permission.read-payin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Price Information"])},
  "customer.permission.read-price-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Price List"])},
  "customer.permission.read-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Project Details"])},
  "customer.permission.read-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Folders"])},
  "customer.permission.read-task-revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Task Revisions"])},
  "customer.permission.read-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Account Information"])},
  "customer.permission.read-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Team Members"])},
  "customer.permission.read-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View User Roles & Permissions"])},
  "customer.permission.read-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View translation assets"])},
  "customer.permission.read-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to see the translation assets page"])},
  "customer.permission.read-translation-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Added Freelancers"])},
  "customer.permission.read-translation-team-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Freelancers' Pricing"])},
  "customer.permission.read-translation-team-price.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to see freelancers’ word price in the Freelancers setting"])},
  "customer.permission.read-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Webhook Endpoint"])},
  "customer.permission.read-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View workflow"])},
  "customer.permission.read-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to see workflows"])},
  "customer.permission.update-automation-price-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Automation Price Limit"])},
  "customer.permission.update-billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Billing Details"])},
  "customer.permission.update-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Billing Method"])},
  "customer.permission.update-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Sub-Account"])},
  "customer.permission.update-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Content Generation Document"])},
  "customer.permission.update-content-generation-document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to update Content Generation documents"])},
  "customer.permission.update-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Content Generation Template"])},
  "customer.permission.update-content-generation-template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to update Content Generation templates"])},
  "customer.permission.update-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Folder"])},
  "customer.permission.update-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Account Information"])},
  "customer.permission.update-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Team Member"])},
  "customer.permission.update-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Custom Team Role"])},
  "customer.permission.update-translation-assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update translation assets"])},
  "customer.permission.update-translation-assets.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to edit/change the translation assets"])},
  "customer.permission.update-translation-team-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Freelancers' Pricing"])},
  "customer.permission.update-user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update User Roles & Permissions"])},
  "customer.permission.update-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Webhook Endpoint"])},
  "customer.permission.update-workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update workflow"])},
  "customer.permission.update-workflow-qa-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update workflow QA threshold"])},
  "customer.permission.update-workflow-qa-threshold.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to edit/change the QA threshold for a workflow"])},
  "customer.permission.update-workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to edit/change a workflow"])},
  "customer.phrase_connector.active_connetor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active connector"])},
  "customer.phrase_connector.complete_steps_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete step 1 before moving to the next steps"])},
  "customer.phrase_connector.connection_unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection unsuccessful"])},
  "customer.phrase_connector.header_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate your EasyTranslate account with your Phrase account"])},
  "customer.phrase_connector.header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate with Phrase"])},
  "customer.phrase_connector.language_pairing.create_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error trying to create language pairs, please try again"])},
  "customer.phrase_connector.language_pairing.create_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language pairs created successfully"])},
  "customer.phrase_connector.language_pairing.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create language pairs"])},
  "customer.phrase_connector.language_pairing.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language pairs successfully created"])},
  "customer.phrase_connector.language_pairing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Pairs"])},
  "customer.phrase_connector.language_pairing.update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error trying to update language pairs, please try again"])},
  "customer.phrase_connector.language_pairing.update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language pairs updated successfully"])},
  "customer.phrase_connector.step_language_pairing.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember to click “Save” after making any edits or deletions for the language pairs"])},
  "customer.phrase_connector.step_language_pairing.phrase_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase code"])},
  "customer.phrase_connector.step_language_pairing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup language pairs"])},
  "customer.phrase_connector.step_one.hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add link to Phrase"])},
  "customer.phrase_connector.step_one.hint_1_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy and paste below link, and add it to Phrase"])},
  "customer.phrase_connector.step_one.hint_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Client ID"])},
  "customer.phrase_connector.step_one.hint_2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Client ID from Phrase"])},
  "customer.phrase_connector.step_one.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your Phrase account with your EasyTranslate account"])},
  "customer.phrase_connector.step_one.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable connection to Phrase"])},
  "customer.phrase_connector.step_three.click_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below button to start verification process"])},
  "customer.phrase_connector.step_three.go_to_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Phrase"])},
  "customer.phrase_connector.step_three.provider_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create provider"])},
  "customer.phrase_connector.step_three.provider_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider successfully created"])},
  "customer.phrase_connector.step_three.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now create your very first task via Phrase"])},
  "customer.phrase_connector.step_three.success_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to assign the ET_admin as a translator and apply the correct workflow"])},
  "customer.phrase_connector.step_three.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your flow"])},
  "customer.phrase_connector.step_three.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your flow"])},
  "customer.phrase_connector.step_three.token_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your Phrase account with your EasyTranslate account"])},
  "customer.phrase_connector.step_three.token_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access token successfully created"])},
  "customer.phrase_connector.step_three.webhook_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create webhook"])},
  "customer.phrase_connector.step_three.webhook_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook successfully created"])},
  "customer.phrase_connector.step_three.workflow_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your workflow and enable it"])},
  "customer.phrase_connector.step_three.workflow_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow successfully created"])},
  "customer.phrase_connector.step_two.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your workflow"])},
  "customer.phrase_connector.step_two.text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the workflow ID to the \"Notes\" field in Phrase"])},
  "customer.phrase_connector.step_two.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create workflow"])},
  "customer.placeholder.search_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a project name/identifier or folder name"])},
  "customer.price_limit.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine a price limit on the automated process. All projects with a higher price value will need your approval before they are carried out."])},
  "customer.price_limit.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your price limit"])},
  "customer.price_limit.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a price limit for your automated translation solution."])},
  "customer.privacy.consent.marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marketing"])},
  "customer.privacy.consent.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "customer.privacy.consent.product_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product categories"])},
  "customer.privacy.consent.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yes, I consent to receive ", _interpolate(_named("marketing")), " via e-mails from EasyTranslate A/S regarding their ", _interpolate(_named("product_categories")), ". EasyTranslate processes my personal data in accordance with its ", _interpolate(_named("privacy_policy")), ". I can withdraw my consent at any time ", _interpolate(_named("here")), "."])},
  "customer.privacy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "customer.progress.add_billing_method.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a billing method"])},
  "customer.progress.add_review_supplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a reviewer (optional)"])},
  "customer.progress.add_translation_supplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a translator (optional)"])},
  "customer.progress.automation_flows.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation Workflows"])},
  "customer.progress.automation_flows.steps.create_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create custom workflow (optional)"])},
  "customer.progress.build_your_team_of_translators.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your team of freelancers"])},
  "customer.progress.create_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create custom workflow (optional)"])},
  "customer.progress.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first project"])},
  "customer.progress.create_review_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first translation and review project (optional)"])},
  "customer.progress.create_translation_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first translation project"])},
  "customer.progress.enable_wallet.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable your wallet"])},
  "customer.progress.first_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your first project"])},
  "customer.progress.getting_started.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting started"])},
  "customer.progress.getting_started.steps.add_billing_method.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a billing method"])},
  "customer.progress.getting_started.steps.build_your_team_of_translators.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your team of freelancers"])},
  "customer.progress.getting_started.steps.enable_wallet.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable your wallet"])},
  "customer.progress.getting_started.steps.update_billing_details.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your billing details"])},
  "customer.progress.team_of_translators.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team of freelancers"])},
  "customer.progress.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["See what actions you need to take to get started. All steps inside ", _interpolate(_named("name")), " are mandatory"])},
  "customer.progress.update_billing_details.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your billing details"])},
  "customer.progress.use_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use custom workflow (optional)"])},
  "customer.project.approve_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please approve the price of your project"])},
  "customer.project.button.download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all"])},
  "customer.project.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name already exists"])},
  "customer.project.skipped_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled: Not executed as the criteria weren't met"])},
  "customer.project.zip.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the zip file or create a new prepare file"])},
  "customer.project.zip.email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email will be sent to you when the zip file is ready for download"])},
  "customer.project.zip.prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the prepare file button to start the process"])},
  "customer.project.zip.prepare_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare file"])},
  "customer.project.zip.prepare_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing file"])},
  "customer.project.zip.preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing"])},
  "customer.project.zip.re_prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can re-prepare the file in"])},
  "customer.project_modal.preferred_deadline.set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to set preferred deadline"])},
  "customer.project_modal.preferred_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting a preferred deadline overrides the assignment logic in LaaS by ignoring the availability of your translator. This means your translator may not be able to deliver at your preferred deadline, which means your task may be assigned to a different translator in your team and there is no guarantee that anyone in your team has the capacity to deliver at your preferred date. If you are in doubt please always consult with your translator using the message function."])},
  "customer.project_modal.preferred_deadline_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that if you are ordering human translation + review, your preferred deadline will only be visible to the translator and not the reviewer. We suggest you inform your translator to take this into account."])},
  "customer.project_modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that we only accept business customers"])},
  "customer.project_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your project here"])},
  "customer.project_modal.video_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video tutorial on how to create a project"])},
  "customer.projects.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any ongoing projects."])},
  "customer.rate.modal.recommend_freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you recommend your freelancer?"])},
  "customer.reassign_task.customer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign customer"])},
  "customer.reassign_task.freelancer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign freelancer"])},
  "customer.reassign_task.not_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["freelancer not assigned"])},
  "customer.reports.btn.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download report"])},
  "customer.reports.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This export will include information regarding your projects such as the username of the project creator, amount of words, what workflow was used, as well as project status, task type and more."])},
  "customer.reports.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download your personal translation reports"])},
  "customer.roles.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new role"])},
  "customer.roles.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No custom roles were found"])},
  "customer.roles.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom roles not found"])},
  "customer.signup.success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful signup. You will now be redirected to the login page."])},
  "customer.sso.enable.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable SSO, you have to provide metadata URL of your SAML configuration"])},
  "customer.sso.enable.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable SSO authentication"])},
  "customer.sso.enabled_samls.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled SAMLs"])},
  "customer.sub_nav.account_info.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account information"])},
  "customer.sub_nav.account_structor.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account structure"])},
  "customer.sub_nav.api.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
  "customer.sub_nav.balance.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "customer.sub_nav.billing.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "customer.sub_nav.caas.documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "customer.sub_nav.caas.plain_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain document"])},
  "customer.sub_nav.caas.save_your_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save your documents"])},
  "customer.sub_nav.caas.templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "customer.sub_nav.folders.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folders"])},
  "customer.sub_nav.impersonate.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impersonate user"])},
  "customer.sub_nav.price_limit.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price limit"])},
  "customer.sub_nav.privacy.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy & consent"])},
  "customer.sub_nav.profile.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "customer.sub_nav.projects.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "customer.sub_nav.reports.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation reports"])},
  "customer.sub_nav.revision_price.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision price"])},
  "customer.sub_nav.roles_permission.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles & permissions"])},
  "customer.sub_nav.tb.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossary"])},
  "customer.sub_nav.tm.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "customer.sub_nav.translation_price.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation price"])},
  "customer.sub_nav.usage.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "customer.sub_nav.users_permission.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "customer.sub_nav.wallet.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "customer.subscription.update.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription updated"])},
  "customer.template.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name already exists"])},
  "customer.terms_conditions.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have updated our terms and conditions.\n\nBefore you can continue using our platform, you need to accept our new terms and conditions."])},
  "customer.terms_conditions.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New terms and conditions"])},
  "customer.toast.error.caas_invalid_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid keyword. Pease respect the format described in the tooltip"])},
  "customer.toast.error.caas_no_special_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special characters are not allowed in key names"])},
  "customer.toast.error.caas_no_whitespaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitespaces are not allowed in key names"])},
  "customer.toast.error.connect_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when trying to connect to Stripe"])},
  "customer.toast.error.folder_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong: your folder could not be deleted"])},
  "customer.toast.error.invalid_account": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No account with the given name \"", _interpolate(_named("account")), "\" was found"])},
  "customer.toast.error.invalid_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid account name"])},
  "customer.toast.error.min_five_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a minimum of 5 keywords before saving"])},
  "customer.toast.error.prefill_account_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong and we could not pre-fill your account information"])},
  "customer.toast.error.rating_min_one_star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must at least select one start"])},
  "customer.toast.error.register_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong and we could not log you in"])},
  "customer.toast.error.registration_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when we tried to register you"])},
  "customer.toast.error.something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again"])},
  "customer.toast.error.sub_tier_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription tier has been used"])},
  "customer.toast.error.team_no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the right permissions to perform this action"])},
  "customer.toast.error.update_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when trying to update your payment method"])},
  "customer.toast.error.user_stopped_action_close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong: your request was stopped because the window was closed while it was being processed."])},
  "customer.toast.success.accept_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have accepted the project"])},
  "customer.toast.success.card_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your default card was updated"])},
  "customer.toast.success.confirm_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the segments have been confirmed"])},
  "customer.toast.success.create_project": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Project \"", _interpolate(_named("project")), "\" was created.\nYou can locate your project in your ", _interpolate(_named("workspaceLink")), "."])},
  "customer.toast.success.create_project_basic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Project \"", _interpolate(_named("project")), "\" was created."])},
  "customer.toast.success.create_project_unnamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Project was created.\nYou can locate your project in your ", _interpolate(_named("workspaceLink")), "."])},
  "customer.toast.success.create_project_unnamed_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project was created."])},
  "customer.toast.success.create_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team of freelancers was created"])},
  "customer.toast.success.flow_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your automation flow was deleted successfully"])},
  "customer.toast.success.folder_create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Folder \"", _interpolate(_named("folder")), "\" was created"])},
  "customer.toast.success.inactive_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wallet is now inactive"])},
  "customer.toast.success.invitation_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The invitation was cancelled and the user was removed"])},
  "customer.toast.success.library_add_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("language")), "\" was added to your library"])},
  "customer.toast.success.library_automation_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The automation for this library has been switched ON and is now running"])},
  "customer.toast.success.library_create_key": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Key \"", _interpolate(_named("key")), "\" was created"])},
  "customer.toast.success.library_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Library \"", _interpolate(_named("name")), "\" was created"])},
  "customer.toast.success.library_delete_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The keys was deleted"])},
  "customer.toast.success.library_remove": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Library \"", _interpolate(_named("library")), "\" was deleted successfully"])},
  "customer.toast.success.library_remove_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("language")), "\" was removed from your library"])},
  "customer.toast.success.library_save_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes to your string were saved"])},
  "customer.toast.success.library_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your changes to library \"", _interpolate(_named("library")), "\" were saved"])},
  "customer.toast.success.rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, your rating was submitted"])},
  "customer.toast.success.remove_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment method was removed successfully"])},
  "customer.toast.success.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was successfully removed"])},
  "customer.toast.success.request_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request is being processed and a new linguist will be assigned shortly"])},
  "customer.toast.success.segments_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the segments have been confirmed"])},
  "customer.toast.success.segments_copied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The segments were copied to ", _interpolate(_named("tm"))])},
  "customer.toast.success.segments_unconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the segments have been unconfirmed"])},
  "customer.toast.success.terms_copied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The terms were copied to ", _interpolate(_named("tb"))])},
  "customer.toast.success.update_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your billing information was updated"])},
  "customer.toast.success.update_price_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your price limit was updated"])},
  "customer.toast.success.update_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your service prices were updated"])},
  "customer.toast.success.update_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile information was updated"])},
  "customer.toast.success.update_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA was updated successfully"])},
  "customer.toast.success.update_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wallet information was updated"])},
  "customer.toast.success.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been upgraded"])},
  "customer.toast.warning.existing_team_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have found your team. You will be notified when the administrator has accepted your request to join the team."])},
  "customer.toast.warning.incomplete_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can update you need to complete your billing information"])},
  "customer.toasts.error.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your revision couldn't be marked as finished"])},
  "customer.tooltip.ai_translation.qa_check_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments below the percentage will be unconfirmed until approved."])},
  "customer.tooltip.string_library.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select product library if you wish to generate content"])},
  "customer.tooltip.string_library.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the translation library that you wish to be translated"])},
  "customer.tooltip.string_library.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If more than the selected percentage of the strings in your library are not translated, we will trigger this flow automatically"])},
  "customer.tooltip.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the task will be assigned to the first translator you select. If this translator declines, it will be assigned to the following translators you have selected, in chronological order."])},
  "customer.translation_assets.custom_mt.bleu_legend.10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost useless"])},
  "customer.translation_assets.custom_mt.bleu_legend.19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard to get the gist"])},
  "customer.translation_assets.custom_mt.bleu_legend.29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gist is clear, but has significant grammatical errors"])},
  "customer.translation_assets.custom_mt.bleu_legend.39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understandable to good translations"])},
  "customer.translation_assets.custom_mt.bleu_legend.49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High quality translations"])},
  "customer.translation_assets.custom_mt.bleu_legend.59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very high quality, adequate, and fluent translations"])},
  "customer.translation_assets.custom_mt.bleu_legend.60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality often better than human"])},
  "customer.translation_assets.custom_mt.bleu_legend.interpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interpretation"])},
  "customer.translation_assets.custom_mt.cancel.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, cancel training"])},
  "customer.translation_assets.custom_mt.cancel.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to cancel the training of the ", _interpolate(_named("name")), " model?"])},
  "customer.translation_assets.custom_mt.cancel.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel training"])},
  "customer.translation_assets.custom_mt.cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "customer.translation_assets.custom_mt.modal_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are instructing about a use of service that involves transfer to Google in the US – if you wish to transfer and use Custom Machine Translation, then please proceed. If you do not wish to transfer, do not proceed.\nEasyTranslate encourages you, the data controller, to anonymise files before transferring them to EasyTranslate to minimise the flow of personal data (Data Minimisation, article 5 of the GDPR)."])},
  "customer.translation_assets.custom_mt.modal_instructions.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand"])},
  "customer.translation_assets.custom_mt.modal_instructions.requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only use this feature if your account meets the following requirements:"])},
  "customer.translation_assets.custom_mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Machine Translation"])},
  "customer.translation_assets.custom_mt.training_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training info"])},
  "customer.translation_assets.custom_mt.training_pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training pairs"])},
  "customer.translation_assets.custom_mt.training_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training time"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 1000 segments/strings"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Only supported languages - click ", _interpolate(_named("here")), " to see the list"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 200 words per segment/string"])},
  "customer.translation_assets.custom_mt.view.info_block.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your stored Translation Memory must match these requirements"])},
  "customer.translation_assets.custom_mt.view_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Custom Machine Translation engine biases your input towards your Translation Memory, resulting in machine-translated content that is more accurate and in line with your unique brand and tone of voice. This lets you translate more content without additional human efforts."])},
  "customer.translation_assets.custom_mt.view_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your MT training model"])},
  "customer.translation_assets.custom_mt.warning_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the required tier in your usage plan. Our team will reach out to you shortly."])},
  "customer.translation_assets.glossary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossary"])},
  "customer.translation_assets.translation_memory.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "customer.translation_price.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can keep track of the agreed pricing structure and TM (translation memory) discount scheme."])},
  "customer.translation_price.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency is key to us."])},
  "customer.translation_price.discount.match_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 0-49%"])},
  "customer.translation_price.discount.match_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 100%"])},
  "customer.translation_price.discount.match_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 101%"])},
  "customer.translation_price.discount.match_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 50-74%"])},
  "customer.translation_price.discount.match_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 74-84%"])},
  "customer.translation_price.discount.match_85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 85-94%"])},
  "customer.translation_price.discount.match_95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match 95-99%"])},
  "customer.translation_price.discount.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA"])},
  "customer.translation_price.discount.repetitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetitions"])},
  "customer.translation_price.discount.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation discount"])},
  "customer.translation_price.price_list.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
  "customer.translation_price.price_list.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New price entry"])},
  "customer.translation_price.price_list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list"])},
  "customer.translator.btn.play_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch this video tutorial to learn how to add freelancers."])},
  "customer.translator.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find any freelancers in your team"])},
  "customer.translator.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No freelancers"])},
  "customer.translator.modal_service.change_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change price"])},
  "customer.translator.modal_service.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be aware that the service will be permanently deleted and this action is irreversible."])},
  "customer.translator.modal_service.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the language pairs to delete from your account"])},
  "customer.translator.modal_service.delete.title_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the following language pairs ?"])},
  "customer.translator.modal_service.delete_lang_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete language pair"])},
  "customer.translator.modal_service.remove_translator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove ", _interpolate(_named("name")), " from your team?"])},
  "customer.translator.modal_service.service_deleted.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected language pair has been deleted from your services"])},
  "customer.translator.modal_service.service_deleted.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service deleted"])},
  "customer.translator.translator_remove.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " has been successfully removed from your translators"])},
  "customer.translator.translator_remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator removed"])},
  "customer.translator_team.loader.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are analysing your document and finding the best match(es) for you"])},
  "customer.translator_team.loader.text_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While you wait, take a look at our Knowledge Base to learn what cool features we have available for you!"])},
  "customer.translator_team.loader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding translator"])},
  "customer.translator_team.loader.title_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding translators"])},
  "customer.updatesub.coupon.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply coupon"])},
  "customer.updatesub.coupon.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
  "customer.updatesub.coupon.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply coupon code"])},
  "customer.upgarde_sub.word_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["word credits"])},
  "customer.upgarde_sub.word_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["word limit"])},
  "customer.upgrade_sub.12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months"])},
  "customer.upgrade_sub.1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month"])},
  "customer.upgrade_sub.billed_annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billed annually (save 50%)"])},
  "customer.upgrade_sub.billed_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billed monthly"])},
  "customer.upgrade_sub.contact_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact sales"])},
  "customer.upgrade_sub.contact_sus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "customer.upgrade_sub.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current plan"])},
  "customer.upgrade_sub.custom_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom price"])},
  "customer.upgrade_sub.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade"])},
  "customer.upgrade_sub.enterprise_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endless possibilities"])},
  "customer.upgrade_sub.feature.caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Content Generation settings"])},
  "customer.upgrade_sub.feature.custom_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom amount of words"])},
  "customer.upgrade_sub.feature.customer_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority Customer Support"])},
  "customer.upgrade_sub.feature.optimisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal optimisation session"])},
  "customer.upgrade_sub.feature.technical_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority Technical Support"])},
  "customer.upgrade_sub.feature.translator_recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailored translator recruitment"])},
  "customer.upgrade_sub.finish_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish payment"])},
  "customer.upgrade_sub.free_trial.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your Free Trial now and try out our platform"])},
  "customer.upgrade_sub.free_trial_anim.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*on annual plans"])},
  "customer.upgrade_sub.free_trial_anim.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try now, decide later"])},
  "customer.upgrade_sub.free_trial_anim.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 day Free Trial!"])},
  "customer.upgrade_sub.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "customer.upgrade_sub.payment.cancel_anytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel anytime"])},
  "customer.upgrade_sub.payment.free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-day free trial"])},
  "customer.upgrade_sub.paymentInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in your payment information to get started"])},
  "customer.upgrade_sub.period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Period: ", _interpolate(_named("fromDate")), " to ", _interpolate(_named("toDate"))])},
  "customer.upgrade_sub.plan_get_started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Get started with the ", _interpolate(_named("plan")), " plan"])},
  "customer.upgrade_sub.scaleup_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most popular"])},
  "customer.upgrade_sub.start_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start free trial"])},
  "customer.upgrade_sub.startup_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminate limitations"])},
  "customer.upgrade_sub.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a payment method to upgrade now"])},
  "customer.upgrade_sub.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're doing a happy dance in your honor and we can't wait to see the magic you'll create with this upgrade."])},
  "customer.upgrade_sub.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hooray for upgrading, you fantastic human being – you've just unleashed a world of marvelous features that are ready to party with your growth!"])},
  "customer.upgrade_sub.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your project exceeds the ", _interpolate(_named("text")), " of your current plan"])},
  "customer.upgrade_sub.title.caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your account to unlock this feature"])},
  "customer.upgrade_sub.title.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a plan that fits your needs"])},
  "customer.upgrade_sub.trial.first_bullet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your first 7 days are free, cancel any time within the 7 days"])},
  "customer.upgrade_sub.trial.second_bullet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your first payment will be charged on ", _interpolate(_named("date"))])},
  "customer.upgrade_sub.trial.third_bullet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will remind you 1 day before your trial ends"])},
  "customer.upgrade_sub.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
  "customer.usage.subaccount.account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team name"])},
  "customer.usage.subaccount.meta.ai_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI Translation"])},
  "customer.usage.subaccount.meta.content_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation"])},
  "customer.usage.subaccount.meta.machine_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Translation"])},
  "customer.usage.subaccount.meta.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation"])},
  "customer.usage.subaccount.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed usage view for each subaccount"])},
  "customer.usage.subaccount.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccounts usage"])},
  "customer.usage.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage and upgrade your pricing plan"])},
  "customer.usage.upgrade.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade and change your pricing plan"])},
  "customer.user_permissions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant access to accounts"])},
  "customer.users_permissions.account.cancel_invite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You're about to cancel the invitation for ", _interpolate(_named("name")), ". Are you sure you want to perform this action?"])},
  "customer.users_permissions.account.remove_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You're about to remove ", _interpolate(_named("name")), " from your team account. Are you sure you want to perform this action?"])},
  "customer.users_permissions.btn.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite new user"])},
  "customer.users_permissions.btn.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove user from account ?"])},
  "customer.users_permissions.modal.btn.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitation"])},
  "customer.users_permissions.modal.edit.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not able to edit your own roles and permissions"])},
  "customer.users_permissions.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite user via email"])},
  "customer.users_permissions.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite users to your team"])},
  "customer.users_permissions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My users"])},
  "customer.users_permissions.title_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user requests"])},
  "customer.voucher.added_cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your added cards"])},
  "customer.voucher.free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After 7 day free trial has ended:"])},
  "customer.voucher.no_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ooops! You don't have permission to change the billing details. Reach out to your team admin ", _interpolate(_named("admin")), " for correct permissions."])},
  "customer.voucher.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "customer.voucher.paymet_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment information"])},
  "customer.voucher.project_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project price"])},
  "customer.voucher.redeem_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem Voucher"])},
  "customer.voucher.saving_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment method will be saved to your account for future projects"])},
  "customer.voucher.set_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set default"])},
  "customer.voucher.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a payment method to proceed with your project"])},
  "customer.voucher.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project exceeds your 25 EUR voucher"])},
  "customer.voucher.title_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing account details"])},
  "customer.voucher.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "customer.voucher.total_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (ex. tax)"])},
  "customer.voucher.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT 25%"])},
  "customer.voucher.voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])},
  "customer.wallet.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable your Wallet and set your desired charge and re-charge amount."])},
  "customer.wallet.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments made easy"])},
  "customer.wallet.monthly_spend.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the amount of how much we should re-charge and top up your credit balance."])},
  "customer.wallet.monthly_spend.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your expected monthly spending"])},
  "customer.wallet.re_charge.day.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge day"])},
  "customer.wallet.re_charge.default_method.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your pre-set default method to execute the recharge of the wallet."])},
  "customer.wallet.re_charge.default_method.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default billing method"])},
  "customer.wallet.re_charge.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose your preferred re-charge type."])},
  "customer.wallet.re_charge.invoice.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use an invoice which is not paid right away, but sent to you with only SEPA available as payment method."])},
  "customer.wallet.re_charge.settings.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The auto re-charge function will automatically recharge your wallet if your balance drops below the minimum balance or based on the preferred auto re-charge date."])},
  "customer.wallet.re_charge.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto re-charge settings"])},
  "customer.wallet.re_charge.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge billing method"])},
  "customer.wallet.status.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "customer.wallet.status.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "customer.wallet.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "customer.workflows.ai_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI"])},
  "customer.workflows.copywriting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing"])},
  "customer.workflows.copywriting_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing & Translation"])},
  "customer.workflows.multilingual.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual"])},
  "customer.workflows.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.workflows.translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation"])},
  "customer.workflows.translation_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation + Review"])},
  "customre.library.shared.btn.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start automation"])},
  "folders.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No folders created"])},
  "folders.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No folders"])},
  "folders.folder.project_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " projects"])},
  "folders.remove.confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove folder ", _interpolate(_named("folderName")), " ?"])},
  "folders.remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove folder"])},
  "folders.remove.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your folder will be permanently removed and this action is irreversible. The projects inside the folder won't be delete; they will be moved to your overview."])},
  "folders.select.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a folder"])},
  "label.notification.invite_dms_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been added to conversation with"])},
  "label.notification.invite_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been added to channel"])},
  "label.notification.invite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New conversation"])},
  "label.notification.open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open conversation"])},
  "label.notification.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message from"])},
  "label.shared.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "label.shared.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA"])},
  "modal.title.supplier.recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Recruitment"])},
  "projects.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any ongoing projects."])},
  "projects.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No projects"])},
  "server.validation.accepted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be accepted."])},
  "server.validation.account_workflow_duplicate_string_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an existing workflow for the selected library"])},
  "server.validation.account_workflow_invalid_initial_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected step is not allowed."])},
  "server.validation.account_workflow_invalid_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The workflow step '", _interpolate(_named("step")), "' can not be followed by '", _interpolate(_named("following_step")), "'"])},
  "server.validation.active_url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " is not a valid URL."])},
  "server.validation.add_language_to_library_duplicates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have already added this language ", _interpolate(_named("target_languages"))])},
  "server.validation.after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a date after ", _interpolate(_named("date")), "."])},
  "server.validation.after_or_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a date after or equal to ", _interpolate(_named("date")), "."])},
  "server.validation.alpha": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may only contain letters."])},
  "server.validation.alpha_dash": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may only contain letters, numbers, dashes and underscores."])},
  "server.validation.alpha_num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may only contain letters and numbers."])},
  "server.validation.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be an array."])},
  "server.validation.before": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a date before ", _interpolate(_named("date")), "."])},
  "server.validation.before_or_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a date before or equal to ", _interpolate(_named("date")), "."])},
  "server.validation.between.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must have between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), " items."])},
  "server.validation.between.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), " kilobytes."])},
  "server.validation.between.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), "."])},
  "server.validation.between.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), " characters."])},
  "server.validation.boolean": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field must be true or false."])},
  "server.validation.chat_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unable to send messages for ", _interpolate(_named("reason"))])},
  "server.validation.company_email_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a business email address"])},
  "server.validation.confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " confirmation does not match."])},
  "server.validation.country_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid ISO-3166 alpha-2 code"])},
  "server.validation.currency_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided value is not a valid ISO-4217 currency"])},
  "server.validation.date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " is not a valid date."])},
  "server.validation.date_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a date equal to ", _interpolate(_named("date")), "."])},
  "server.validation.date_format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " does not match the format ", _interpolate(_named("format")), "."])},
  "server.validation.different": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " and ", _interpolate(_named("other")), " must be different."])},
  "server.validation.digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be ", _interpolate(_named("digits")), " digits."])},
  "server.validation.digits_between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), " digits."])},
  "server.validation.dimensions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " has invalid image dimensions."])},
  "server.validation.distinct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field has a duplicate value."])},
  "server.validation.download_library_extra_target_languages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The given languages are not part of the library: ", _interpolate(_named("language_codes"))])},
  "server.validation.email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid email address."])},
  "server.validation.ends_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must end with one of the following: ", _interpolate(_named("values"))])},
  "server.validation.exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The selected ", _interpolate(_named("attribute")), " is invalid."])},
  "server.validation.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a file."])},
  "server.validation.filled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field must have a value."])},
  "server.validation.finish_automation_bulk_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to bulk update source language via workflow"])},
  "server.validation.finish_automation_for_completed_workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow completed"])},
  "server.validation.gdpr_action_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already requested this action. It is pending."])},
  "server.validation.gdpr_supplier_pending_payouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to request deletion, as you have pending payouts"])},
  "server.validation.gdpr_supplier_pending_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to request deletion, as you have pending tasks"])},
  "server.validation.gt.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must have more than ", _interpolate(_named("value")), " items."])},
  "server.validation.gt.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be greater than ", _interpolate(_named("value")), " kilobytes."])},
  "server.validation.gt.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be greater than ", _interpolate(_named("value")), "."])},
  "server.validation.gt.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be greater than ", _interpolate(_named("value")), " characters."])},
  "server.validation.gte.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must have ", _interpolate(_named("value")), " items or more."])},
  "server.validation.gte.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be greater than or equal ", _interpolate(_named("value")), " kilobytes."])},
  "server.validation.gte.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be greater than or equal ", _interpolate(_named("value")), "."])},
  "server.validation.gte.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be greater than or equal ", _interpolate(_named("value")), " characters."])},
  "server.validation.image": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be an image."])},
  "server.validation.in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The selected ", _interpolate(_named("attribute")), " is invalid."])},
  "server.validation.in_array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field does not exist in ", _interpolate(_named("other")), "."])},
  "server.validation.integer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be an integer. Ex. -5, 1, 5, 8, 97.."])},
  "server.validation.ip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid IP address."])},
  "server.validation.ipv4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid IPv4 address."])},
  "server.validation.ipv6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid IPv6 address."])},
  "server.validation.json": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid JSON string."])},
  "server.validation.language_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be valid language code"])},
  "server.validation.login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These credentials do not match our records."])},
  "server.validation.lt.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must have less than ", _interpolate(_named("value")), " items."])},
  "server.validation.lt.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be less than ", _interpolate(_named("value")), " kilobytes."])},
  "server.validation.lt.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be less than ", _interpolate(_named("value")), "."])},
  "server.validation.lt.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be less than ", _interpolate(_named("value")), " characters."])},
  "server.validation.lte.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must not have more than ", _interpolate(_named("value")), " items."])},
  "server.validation.lte.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be less than or equal ", _interpolate(_named("value")), " kilobytes."])},
  "server.validation.lte.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be less than or equal ", _interpolate(_named("value")), "."])},
  "server.validation.lte.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be less than or equal ", _interpolate(_named("value")), " characters."])},
  "server.validation.match_supplier_delete_service_from_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete this service, as you are part of a customer team. Reach out to support via the chat."])},
  "server.validation.match_supplier_missing_project_template_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file so we can process your future files. Please note that this should be a small file"])},
  "server.validation.match_supplier_update_price_per_word_on_laas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to modify price per word for LaaS customers"])},
  "server.validation.max.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may not have more than ", _interpolate(_named("max")), " items."])},
  "server.validation.max.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may not be greater than ", _interpolate(_named("max")), " kilobytes."])},
  "server.validation.max.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may not be greater than ", _interpolate(_named("max")), "."])},
  "server.validation.max.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " may not be greater than ", _interpolate(_named("max")), " characters."])},
  "server.validation.mimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported file format."])},
  "server.validation.mimetypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported file format."])},
  "server.validation.min.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must have at least ", _interpolate(_named("min")), " items."])},
  "server.validation.min.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be at least ", _interpolate(_named("min")), " kilobytes."])},
  "server.validation.min.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be at least ", _interpolate(_named("min")), "."])},
  "server.validation.min.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be at least ", _interpolate(_named("min")), " characters."])},
  "server.validation.not_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The selected ", _interpolate(_named("attribute")), " is invalid."])},
  "server.validation.not_regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " format is invalid."])},
  "server.validation.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a number."])},
  "server.validation.password_reset_error_throttled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again in a moment."])},
  "server.validation.password_reset_error_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password reset token is invalid"])},
  "server.validation.password_reset_error_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can't find a user with that email address."])},
  "server.validation.present": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field must be present."])},
  "server.validation.price_list_discounts_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid format provided"])},
  "server.validation.price_list_languages_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid format provided"])},
  "server.validation.project_creation_meta_value_too_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The meta value for key ", _interpolate(_named("key")), " is longer than ", _interpolate(_named("limit")), " characters"])},
  "server.validation.project_creation_missing_billing_details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We can not process your order without your billing details. Please click ", _interpolate(_named("here")), " ", _interpolate(_named("link")), " to fill them out."])},
  "server.validation.project_creation_missing_billing_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At least one billing method is required before creating a new project ", _interpolate(_named("here")), " ", _interpolate(_named("link"))])},
  "server.validation.project_creation_missing_project_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is no project template set up for ", _interpolate(_named("source_language")), " ", _interpolate(_named("identifier"))])},
  "server.validation.project_creation_missing_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription has expired."])},
  "server.validation.project_creation_missing_translation_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation team is required for requested source/target language combinations"])},
  "server.validation.project_creation_same_source_and_target_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having same source and target language pair is not allowed"])},
  "server.validation.project_creation_supplier_without_account_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The supplier does not have the given language pairs attached to the customer"])},
  "server.validation.project_status_not_ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid action. Project is not ", _interpolate(_named("status"))])},
  "server.validation.regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " format is invalid."])},
  "server.validation.registration_private_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a business email address"])},
  "server.validation.registration_sso_not_verified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is not yet verified"])},
  "server.validation.required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required."])},
  "server.validation.required_if": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required when ", _interpolate(_named("other")), " is ", _interpolate(_named("value")), "."])},
  "server.validation.required_unless": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required unless ", _interpolate(_named("other")), " is in ", _interpolate(_named("values")), "."])},
  "server.validation.required_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required when ", _interpolate(_named("values")), " is present."])},
  "server.validation.required_with_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required when ", _interpolate(_named("values")), " are present."])},
  "server.validation.required_without": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required when ", _interpolate(_named("values")), " is not present."])},
  "server.validation.required_without_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " field is required when none of ", _interpolate(_named("values")), " are present."])},
  "server.validation.same": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " and ", _interpolate(_named("other")), " must match."])},
  "server.validation.size.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must contain ", _interpolate(_named("size")), " items."])},
  "server.validation.size.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be ", _interpolate(_named("size")), " kilobytes."])},
  "server.validation.size.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be ", _interpolate(_named("size")), "."])},
  "server.validation.size.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be ", _interpolate(_named("size")), " characters."])},
  "server.validation.sso_unable_to_use_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO registered users are not allowed to login with password, or reset their password"])},
  "server.validation.start_automation_extra_target_languages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Can't start automation for target languages that are not part of the library: ", _interpolate(_named("target_languages"))])},
  "server.validation.start_automation_for_disabled_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't start automation. Library is disabled"])},
  "server.validation.start_automation_for_disabled_library_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't start automation. Library automation is not set or workflow is not active!"])},
  "server.validation.start_automation_for_source_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't start automation for source language"])},
  "server.validation.start_automation_for_wrong_library_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation can't be applied to review library"])},
  "server.validation.starts_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must start with one of the following: ", _interpolate(_named("values"))])},
  "server.validation.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a string."])},
  "server.validation.subscription_invalid_subscription_tier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tier ", _interpolate(_named("tier")), " does not exists for ", _interpolate(_named("metric"))])},
  "server.validation.subscription_maximum_tier_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum allowed ", _interpolate(_named("maximum")), " units for ", _interpolate(_named("metric")), " while on ", _interpolate(_named("plan")), "(", _interpolate(_named("tier")), ")"])},
  "server.validation.subscription_unable_to_update_subscription_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to update LaaS subscription tiers"])},
  "server.validation.task_revision_unconfirmed_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task has unconfirmed segments. Please double check."])},
  "server.validation.team_identifier_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only lower case letters with dash and numbers are allowed, and to not start nor end with a dash."])},
  "server.validation.template_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The source and target language(s) do not match with the chosen template, please update the template."])},
  "server.validation.throttle_login_request": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Too many login attempts. Please try again in ", _interpolate(_named("seconds")), " seconds."])},
  "server.validation.throttle_process": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The process was already initiated ", _interpolate(_named("minutes")), " minutes ago, please try again later"])},
  "server.validation.timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid zone."])},
  "server.validation.unable_to_modify_predefined_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to modify predefined_entity"])},
  "server.validation.unique": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " has already been taken."])},
  "server.validation.unique_domain_email_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company domain is already registered with us. Please contact us via the chat to learn more"])},
  "server.validation.update_own": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unable to update your own ", _interpolate(_named("attribute"))])},
  "server.validation.uploaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " failed to upload."])},
  "server.validation.url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " format is invalid."])},
  "server.validation.user_invitation_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation is already accepted"])},
  "server.validation.user_invitation_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation is already declined"])},
  "server.validation.uuid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must be a valid UUID."])},
  "server.validation.vat_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid VAT number format provided."])},
  "server.validation.wallet_greater_invalid_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("attribute")), " must not be greater than ", _interpolate(_named("amount")), " ", _interpolate(_named("currency"))])},
  "server.validation.wallet_invalid_currency_for_recharge_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice method is available only for ", _interpolate(_named("currency")), " currency"])},
  "server.validation.wallet_invalid_threshold_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum balance can't be higher than the balance"])},
  "server.validation.wallet_missing_threshold_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one auth-recharge option is required"])},
  "server.validation.wallet_positive_credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credit balance is positive. Please contact Support."])},
  "server.validation.wallet_unable_to_disable_for_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to disable wallet for Freemium subscription"])},
  "server.validation.zip_file_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ZIP file is not yet ready"])},
  "server.validaton.supplier_onboarding_no_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we have no translators with this combination. Please reach out to us via the chat."])},
  "shard.label.multiple_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple languages"])},
  "shard.label.one_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One language"])},
  "shared.account.account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account information"])},
  "shared.account.profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile information"])},
  "shared.account_list.label.choose_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your account"])},
  "shared.account_list.label.choose_supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your supplier"])},
  "shared.account_list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose customer type"])},
  "shared.banner.donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Review us on G2 - We will donate 25€ to Ukraine for every non-anonymous review we receive ", _interpolate(_named("here")), ". You have ", _interpolate(_named("days")), " days left."])},
  "shared.button.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "shared.button.add_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add education"])},
  "shared.button.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more"])},
  "shared.button.add_more_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more keywords (optional)"])},
  "shared.button.add_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Glossary"])},
  "shared.button.add_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add TM"])},
  "shared.button.approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "shared.button.approve_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve payment"])},
  "shared.button.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "shared.button.back_translator_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to freelancer page"])},
  "shared.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "shared.button.cancel_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel invitation"])},
  "shared.button.change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "shared.button.chat_supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View progress"])},
  "shared.button.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "shared.button.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "shared.button.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
  "shared.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "shared.button.confirm_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm&next"])},
  "shared.button.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "shared.button.content_in_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See content in my library"])},
  "shared.button.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
  "shared.button.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "shared.button.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
  "shared.button.create_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create document"])},
  "shared.button.create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new folder"])},
  "shared.button.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create project"])},
  "shared.button.create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
  "shared.button.decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
  "shared.button.decline_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline task"])},
  "shared.button.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "shared.button.delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
  "shared.button.delete_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete flow"])},
  "shared.button.delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete folder"])},
  "shared.button.delete_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete key"])},
  "shared.button.delete_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete language"])},
  "shared.button.delete_language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete language pair"])},
  "shared.button.delete_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete library"])},
  "shared.button.delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete service"])},
  "shared.button.delete_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this action and all after it"])},
  "shared.button.delete_time_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete time off"])},
  "shared.button.disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
  "shared.button.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "shared.button.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "shared.button.download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
  "shared.button.download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "shared.button.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "shared.button.edit_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit list"])},
  "shared.button.edit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit price"])},
  "shared.button.edit_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit services"])},
  "shared.button.edit_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this action"])},
  "shared.button.embed_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed code"])},
  "shared.button.enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
  "shared.button.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "shared.button.finish_onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish onboarding"])},
  "shared.button.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish revision"])},
  "shared.button.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "shared.button.generate_pat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate your personal token (PAT)"])},
  "shared.button.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
  "shared.button.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
  "shared.button.here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "shared.button.import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "shared.button.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invite"])},
  "shared.button.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "shared.button.lets_get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's get started!"])},
  "shared.button.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
  "shared.button.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "shared.button.memsource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Memsource"])},
  "shared.button.move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to folder"])},
  "shared.button.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "shared.button.no_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
  "shared.button.phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in Phrase"])},
  "shared.button.preselect_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected languages"])},
  "shared.button.previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "shared.button.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "shared.button.remove_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove account"])},
  "shared.button.remove_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove translator"])},
  "shared.button.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove user"])},
  "shared.button.rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename folder"])},
  "shared.button.request_account_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request account deletion"])},
  "shared.button.request_company_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request company deletion"])},
  "shared.button.resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve"])},
  "shared.button.revise_generated_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise generated content"])},
  "shared.button.revise_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "shared.button.revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in revision editor"])},
  "shared.button.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "shared.button.save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save draft"])},
  "shared.button.search_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search assets"])},
  "shared.button.see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
  "shared.button.see_all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all services"])},
  "shared.button.see_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["See in ", _interpolate(_named("format"))])},
  "shared.button.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "shared.button.set_word_credits_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View word credits breakdown"])},
  "shared.button.set_word_credits_ratio.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your cost in word credits pr. service"])},
  "shared.button.set_word_credits_ratio.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word credit cost"])},
  "shared.button.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "shared.button.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
  "shared.button.skip_for_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip for now"])},
  "shared.button.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "shared.button.start_translating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start translating"])},
  "shared.button.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "shared.button.switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch account"])},
  "shared.button.try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "shared.button.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "shared.button.update_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update subscription"])},
  "shared.button.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "shared.button.verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify my account"])},
  "shared.button.video_tutorial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Video tutorial on how to add ", _interpolate(_named("title"))])},
  "shared.button.view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
  "shared.button.yes_im_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I’m sure"])},
  "shared.button.zip_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip download"])},
  "shared.buttons.explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
  "shared.caas.ads.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate ad content and increase your conversion rates"])},
  "shared.caas.ads.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads"])},
  "shared.caas.blogs.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate convincing, high-quality blog posts"])},
  "shared.caas.blogs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog posts"])},
  "shared.caas.button.clear_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear document"])},
  "shared.caas.button.lets_go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's go!"])},
  "shared.caas.button.replace_and_write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate content"])},
  "shared.caas.button.start_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start translation"])},
  "shared.caas.button.write_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue writing"])},
  "shared.caas.buttons.document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
  "shared.caas.buttons.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content reference"])},
  "shared.caas.data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate encourages you, the data controller, to anonymise any input before generating content to minimise the flow of personal data (Data Minimisation, article 5 of the GDPR)."])},
  "shared.caas.emails.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate engaging content for automated emails"])},
  "shared.caas.emails.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails"])},
  "shared.caas.modal.check_accurateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accurate content in any language"])},
  "shared.caas.modal.check_highQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-quality content - fast and at scale"])},
  "shared.caas.modal.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the words generated using this tool will count towards your overall word usage in your license plan."])},
  "shared.caas.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All you have to do is type in your request (e.g. \"Write a blog post about AI\") and within seconds it produces enticing content."])},
  "shared.caas.modal.text_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All you have to do is type in your request (e.g. \"Write a blog post about AI\") and within seconds it produces enticing content."])},
  "shared.caas.modal.text_freemium_letsGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s get you started using the platform! We’ll start with our AI content-generation tool:"])},
  "shared.caas.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We just released our new feature AI-content and you can try it for FREE!"])},
  "shared.caas.modal.title_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to EasyTranslate!"])},
  "shared.caas.product.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate enticing product descriptions"])},
  "shared.caas.product.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product descriptions"])},
  "shared.caas.reset_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset template to default"])},
  "shared.caas.sidebar.advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
  "shared.caas.sidebar.basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
  "shared.caas.sidebar.creativity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creativity"])},
  "shared.caas.sidebar.frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetitive frequency"])},
  "shared.caas.sidebar.high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "shared.caas.sidebar.instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
  "shared.caas.sidebar.low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "shared.caas.sidebar.maxchars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum character count"])},
  "shared.caas.sidebar.maxchars.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend disabling max. word count when applying this feature"])},
  "shared.caas.sidebar.presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetitive presence"])},
  "shared.caas.sidebar.tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum word count"])},
  "shared.caas.sidebar.tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
  "shared.caas.sidebar.video.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to generate enticing content"])},
  "shared.caas.sidebar.watch_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch the tutorial for Blog content Generation"])},
  "shared.caas.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let our AI tool write engaging content for you!"])},
  "shared.caas.subtitle_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can write your prompts in any language you wish (for example “Schreibe einen Artikel über KI”)"])},
  "shared.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation"])},
  "shared.caas.tooltip.replace_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action replaces your command with the output (Mac: command + enter, Other: control + enter)"])},
  "shared.caas.tooltip.writing_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action continues your initial prompt"])},
  "shared.chat.actions.join_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join conversation"])},
  "shared.chat.actions.leave_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave conversation"])},
  "shared.chat.actions.leave_conversation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this conversation?"])},
  "shared.chat.button.go_offline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set yourself as ", _interpolate(_named("offline"))])},
  "shared.chat.button.go_online": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set yourself as ", _interpolate(_named("online"))])},
  "shared.chat.button.new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message"])},
  "shared.chat.button.show_all_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all members"])},
  "shared.chat.buttons.delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete message"])},
  "shared.chat.buttons.remove_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from saved items"])},
  "shared.chat.buttons.save_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save item"])},
  "shared.chat.channel.delete_channel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete channel ", _interpolate(_named("name"))])},
  "shared.chat.channel.delete_direct_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete your direct message conversation with ", _interpolate(_named("name"))])},
  "shared.chat.channel.delete_group_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete your group message conversation with ", _interpolate(_named("name"))])},
  "shared.chat.channels.about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What’s this channel about?"])},
  "shared.chat.channels.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create channel"])},
  "shared.chat.channels.choose_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add members"])},
  "shared.chat.channels.make_private_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make private"])},
  "shared.chat.channels.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels are where your team communicates. They’re best when organised around a topic – #marketing, for example."])},
  "shared.chat.channels.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a channel"])},
  "shared.chat.channels.private_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a channel is set to private, it can only be viewed or joined by invitation."])},
  "shared.chat.channels.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share outside EasyTranslate"])},
  "shared.chat.customer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This chat has been deprecated on the 26th of October 2022, as a result of the release of our new and improved chat.\n\nTo keep communicating with the translators involved in this task, please create a group message or a channel in the chat, which you can find in the top right corner.\n\nThank you for your understanding."])},
  "shared.chat.delete_channel.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete channel"])},
  "shared.chat.delete_direct_message.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete direct message"])},
  "shared.chat.group_message.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create group message"])},
  "shared.chat.group_message.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a group message"])},
  "shared.chat.group_messages.modal.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot create a group message with less than 3 participants. To communicate with one person, write a direct message instead."])},
  "shared.chat.hide_hidden_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide hidden conversations"])},
  "shared.chat.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "shared.chat.label.add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new members"])},
  "shared.chat.label.attachments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This message contains ", _interpolate(_named("count")), " attachment(s):"])},
  "shared.chat.label.channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
  "shared.chat.label.channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
  "shared.chat.label.dm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct message"])},
  "shared.chat.label.dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct messages"])},
  "shared.chat.label.group_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group message"])},
  "shared.chat.label.group_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group messages"])},
  "shared.chat.label.more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More actions"])},
  "shared.chat.label.new_message_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "shared.chat.label.new_message_to.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somebody, someone..."])},
  "shared.chat.label.remove_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove members"])},
  "shared.chat.label.saved_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved items"])},
  "shared.chat.label.unread_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread messages"])},
  "shared.chat.label.update_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update member list"])},
  "shared.chat.search_channels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search all channels..."])},
  "shared.chat.show_hidden_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show hidden conversations"])},
  "shared.chat.supplier.client_gets_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You customer ", _interpolate(_named("customer")), " will get your messages."])},
  "shared.chat.supplier_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This chat has been deprecated on the 26th of October 2022, as a result of the release of our new and improved chat.\n\nTo keep communicating with the translators involved in this task, please create a group message or a channel in the chat, which you can find in the top right corner.\n\nThank you for your understanding."])},
  "shared.chat.unread_messages_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messages"])},
  "shared.chat.warning.old_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This chat has been deprecated on the 26th of October 2022, as a result of the release of our new and improved chat.\n\nTo keep communicating with the translators involved in this task, please create a group message or a channel in the chat, which you can find in the top right corner.\n\nThank you for your understanding."])},
  "shared.combination.no_match": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no ", _interpolate(_named("lang")), " combination. Please add additional translators to your team by clicking ", _interpolate(_named("here"))])},
  "shared.connection_form.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start new workflow"])},
  "shared.content_form.api_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up APIs and strings"])},
  "shared.content_form.api_strings_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a developer or work closely with one"])},
  "shared.content_form.automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automate repetitive and time-consuming tasks"])},
  "shared.content_form.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard files"])},
  "shared.content_form.files_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g.: excel, word, xliff, json"])},
  "shared.content_form.improve_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve quality and consistency throughout translations"])},
  "shared.content_form.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate content with AI"])},
  "shared.content_form.new_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let AI write content for you"])},
  "shared.content_form.pim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIM systems"])},
  "shared.content_form.plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugins"])},
  "shared.content_form.repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A repository"])},
  "shared.content_form.repository_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g.: Github, Gitlab"])},
  "shared.content_form.start_localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start localisation from scratch"])},
  "shared.content_form.streamline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamline internal workflows and task management"])},
  "shared.content_form.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to start a translation task"])},
  "shared.content_form.translation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine translation or freelance translation"])},
  "shared.content_form.web_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate websites or software"])},
  "shared.content_form.web_translation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our plugins for WordPress, Shopify and more"])},
  "shared.content_generation.apply_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply template"])},
  "shared.content_generation.content_brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content brief"])},
  "shared.content_generation.content_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content type"])},
  "shared.content_generation.create_new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new template"])},
  "shared.content_generation.delete_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete document"])},
  "shared.content_generation.delete_document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to delete the following document:"])},
  "shared.content_generation.delete_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete template"])},
  "shared.content_generation.delete_template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to delete the following template:"])},
  "shared.content_generation.edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Template"])},
  "shared.content_generation.edit_template_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter template name here (required)"])},
  "shared.content_generation.error_empty_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prompt can't be empty"])},
  "shared.content_generation.error_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum word count parameter must be at least 12"])},
  "shared.content_generation.import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import template"])},
  "shared.content_generation.main_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main subject"])},
  "shared.content_generation.manage_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can manage your documents here"])},
  "shared.content_generation.manage_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can manage your templates here"])},
  "shared.content_generation.modals.change_document_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your document name here"])},
  "shared.content_generation.modals.change_template_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your template name here"])},
  "shared.content_generation.modals.rename_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change document name"])},
  "shared.content_generation.modals.rename_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change template name"])},
  "shared.content_generation.modals.save_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save document"])},
  "shared.content_generation.modals.save_tempate_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save your document as a template and re-use it by importing it"])},
  "shared.content_generation.modals.save_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save template"])},
  "shared.content_generation.new_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New plain document"])},
  "shared.content_generation.new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New plain template"])},
  "shared.content_generation.saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
  "shared.content_generation.template_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template active"])},
  "shared.content_generation.tone_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone of voice"])},
  "shared.content_generation.unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved"])},
  "shared.content_pillar_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Pillars task editor"])},
  "shared.copywriting.complete_task.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to complete the following task:"])},
  "shared.copywriting.complete_task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete copy editing task"])},
  "shared.copywriting.finish_copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish copy editing"])},
  "shared.copywriting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing"])},
  "shared.days.Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "shared.days.Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "shared.days.Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "shared.days.Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "shared.days.Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thursday"])},
  "shared.days.Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "shared.days.Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wednesday"])},
  "shared.editor.ai_filters.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "shared.editor.ai_filters.filter_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter settings"])},
  "shared.editor.ai_filters.is_ai_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified by AI"])},
  "shared.editor.ai_filters.is_human_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified by human"])},
  "shared.editor.ai_filters.is_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked segments"])},
  "shared.editor.ai_filters.is_qa_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed AI"])},
  "shared.editor.ai_filters.is_untranslatable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untranslatable segments"])},
  "shared.editor.ai_filters.score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory matches"])},
  "shared.editor.btn.confirm_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm all"])},
  "shared.editor.btn.unconfirm_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconfirm all"])},
  "shared.editor.linked_segments.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all identical segments"])},
  "shared.editor.tagging_issues.pro_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tip: Apply the source segment as source of truth"])},
  "shared.editor.tagging_issues.step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the “Fix tags” below"])},
  "shared.editor.tagging_issues.step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if the tags are placed correctly"])},
  "shared.editor.tagging_issues.step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If not, click the tag, and drop it where desired"])},
  "shared.editor.tagging_issues.step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can also create tags by typing ", _interpolate(_named("tagExample")), " (make sure to apply the correct number)"])},
  "shared.editor.tagging_issues.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file contains tagging issues!"])},
  "shared.editor.toast.char_limit_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The segment exceeds the character limit. Please adjust the text according to the character limit."])},
  "shared.editor_preview.tab.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "shared.editor_preview.tab.xlf_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLF notes"])},
  "shared.embed_code.toast.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code copied"])},
  "shared.empty.clients.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find any customers. Try again later."])},
  "shared.empty.clients.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customers"])},
  "shared.empty.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history to be shown."])},
  "shared.error_titles.generic_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error ", _interpolate(_named("code"))])},
  "shared.errors.generic_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested resource could not be found"])},
  "shared.errors.missing_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change your password, you need to type in your current password"])},
  "shared.errors.missing_password_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change your email, you need to type in your password"])},
  "shared.gdpr.delete.admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user has requested that his account be deleted. Click on delete below to permanently delete their account."])},
  "shared.gdpr.delete.modal_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that I have read and understood the text above, and I want to proceed to delete my EasyTranslate account."])},
  "shared.gdpr.delete.modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will delete your EasyTranslate account, along with the selected records. Your data will be deleted and your EasyTranslate account will no longer be accessible."])},
  "shared.gdpr.delete.modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
  "shared.gdpr.delete.option_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
  "shared.gdpr.delete.option_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["picture"])},
  "shared.gdpr.delete.option_source_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source files"])},
  "shared.gdpr.delete.option_target_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target files"])},
  "shared.gdpr.delete.option_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation memory"])},
  "shared.gdpr.delete.select_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select which of your data you want us to delete from our records when we delete your account:"])},
  "shared.gdpr.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete your account"])},
  "shared.gdpr.delete.user_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is currently a ", _interpolate(_named("pending")), " request to delete this account. We are currently processing your request."])},
  "shared.gdpr.delete_company.modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will delete your company from EasyTranslate, including all its sub-accounts and their content. Your data will also be permanently deleted and your EasyTranslate account will no longer be accessible."])},
  "shared.gdpr.delete_company.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select which of your data you want us to delete from our records when we delete your company:"])},
  "shared.gdpr.delete_company.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete your company"])},
  "shared.gdpr.delete_company.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will delete everything regarding your company, including sub-accounts and their content."])},
  "shared.gdpr.export.option_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
  "shared.gdpr.export.option_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoices"])},
  "shared.gdpr.export.option_personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personal"])},
  "shared.gdpr.export.option_source_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source files"])},
  "shared.gdpr.export.option_target_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target files"])},
  "shared.gdpr.export.option_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation memory"])},
  "shared.gdpr.export.preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing zip file..."])},
  "shared.gdpr.export.request_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request new download"])},
  "shared.gdpr.export.tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation memory"])},
  "shared.gdpr.team_admin.data_retention.never_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
  "shared.gdpr.team_admin.data_retention.standard_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months"])},
  "shared.gdpr.team_admin.data_retention_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage preferences"])},
  "shared.gdpr.team_admin.delete.impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As an admin you can delete team members' accounts and data. But first, you have to go to Settings > Users & Permissions > options button > 'Impersonate user'"])},
  "shared.gdpr.team_admin.export.impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As an admin you can download any team member's data. But first, you have to go to Settings > Users & Permissions > options button > 'Impersonate user'"])},
  "shared.gdpr.team_admin.retention_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose if you would like us to delete your data after 12 months (standard) or if we don't need to delete it (never)"])},
  "shared.gdpr.zip.button.download_latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download latest file (.zip)"])},
  "shared.gdpr.zip.request_in_mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can request a new file in ", _interpolate(_named("zipTimestamp")), " min"])},
  "shared.gdpr.zip.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download your data"])},
  "shared.gdpr.zip.when_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email will be sent to you when the new zip file is ready for download"])},
  "shared.google_signup.empty_consents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to agree to our Terms and Conditions below to be able to sign up"])},
  "shared.google_signup.sso_or_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or create your account manually"])},
  "shared.header.add_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your translator"])},
  "shared.header.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a few more details - no credit card needed!"])},
  "shared.header.connection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set up ", _interpolate(_named("connection")), " connection"])},
  "shared.header.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you planning to use EasyTranslate for?"])},
  "shared.header.create_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your free account"])},
  "shared.header.setup_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation details"])},
  "shared.labe.last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified"])},
  "shared.label.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "shared.label.account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account type"])},
  "shared.label.accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "shared.label.action_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This change is irreversible"])},
  "shared.label.action_may_take_few_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action may take a few minutes"])},
  "shared.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "shared.label.add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file"])},
  "shared.label.add_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new key"])},
  "shared.label.add_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row"])},
  "shared.label.add_translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add freelancers"])},
  "shared.label.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "shared.label.admin_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin account"])},
  "shared.label.ai_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI step"])},
  "shared.label.ai_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI Translation"])},
  "shared.label.ai_translation.qa_check_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality check threshold"])},
  "shared.label.ai_translation.split_file_dropdown_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split file 1.a"])},
  "shared.label.ai_translation.split_file_dropdown_1.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the threshold"])},
  "shared.label.ai_translation.split_file_dropdown_1.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value defines if the file can be split into smaller files. If the word count in the file is below this value, it won’t be split. If it exceeds, the file will be split into multiple files based on your selected amount."])},
  "shared.label.ai_translation.split_file_dropdown_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split file 1.b"])},
  "shared.label.ai_translation.split_file_dropdown_2.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the amount of words"])},
  "shared.label.ai_translation.split_file_dropdown_2.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the uploaded file’s word count exceeds the threshold, it will be split into smaller projects, each containing fewer words than the here specified limit."])},
  "shared.label.ai_translation.template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
  "shared.label.ai_translation.template.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the template"])},
  "shared.label.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "shared.label.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "shared.label.any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])},
  "shared.label.approval_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action required"])},
  "shared.label.approve_new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve new price"])},
  "shared.label.archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "shared.label.areas_expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Areas of expertise (max: 5)"])},
  "shared.label.assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
  "shared.label.balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "shared.label.bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account"])},
  "shared.label.beta_editor.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This version only displays flagged segments. Untick beta to view all segments."])},
  "shared.label.billed_annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billed annually"])},
  "shared.label.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
  "shared.label.birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
  "shared.label.bleu_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLEU score"])},
  "shared.label.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a meeting"])},
  "shared.label.btn.add_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tags"])},
  "shared.label.btn.complete_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete revision"])},
  "shared.label.btn.fix_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix tags"])},
  "shared.label.btn.reach_upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach out to support to upgrade"])},
  "shared.label.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business type"])},
  "shared.label.button.message_freelancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message freelancers"])},
  "shared.label.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
  "shared.label.card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
  "shared.label.card_holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card holder name"])},
  "shared.label.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
  "shared.label.cardholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder name"])},
  "shared.label.change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "shared.label.change_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your profile picture"])},
  "shared.label.character_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character limit"])},
  "shared.label.characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters"])},
  "shared.label.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "shared.label.chat.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "shared.label.choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose date"])},
  "shared.label.choose_internal_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose internal reviewer"])},
  "shared.label.choose_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose language"])},
  "shared.label.choose_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a library"])},
  "shared.label.choose_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose reviewer(s)"])},
  "shared.label.choose_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose time"])},
  "shared.label.choose_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose translator"])},
  "shared.label.choose_translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose translator(s)"])},
  "shared.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "shared.label.click_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
  "shared.label.click_to_activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to activate"])},
  "shared.label.click_to_deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to deactivate"])},
  "shared.label.client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "shared.label.client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
  "shared.label.client_not_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer is not on freemium"])},
  "shared.label.coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
  "shared.label.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "shared.label.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "shared.label.company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company phone"])},
  "shared.label.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "shared.label.confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "shared.label.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your password"])},
  "shared.label.confirmed_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed segments"])},
  "shared.label.content_pillars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Pillars"])},
  "shared.label.content_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content type"])},
  "shared.label.copy_pat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the field to copy your PAT (Personal Access Token)"])},
  "shared.label.copy_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to"])},
  "shared.label.copyeditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editor"])},
  "shared.label.copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing"])},
  "shared.label.copywriting_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing price"])},
  "shared.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "shared.label.create_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new service"])},
  "shared.label.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "shared.label.created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
  "shared.label.created_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created on ", _interpolate(_named("date"))])},
  "shared.label.creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
  "shared.label.credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Credit Balance"])},
  "shared.label.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "shared.label.current_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current balance"])},
  "shared.label.current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "shared.label.custom_field_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom field"])},
  "shared.label.custom_field_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])},
  "shared.label.custom_field_name_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name (e.g. Contact person)"])},
  "shared.label.custom_field_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field value"])},
  "shared.label.custom_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom role"])},
  "shared.label.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "shared.label.data_retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete my files after"])},
  "shared.label.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "shared.label.deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
  "shared.label.decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
  "shared.label.decline_new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline new price"])},
  "shared.label.declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
  "shared.label.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "shared.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "shared.label.description_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (max. 500 chars.)"])},
  "shared.label.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "shared.label.education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education title"])},
  "shared.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "shared.label.email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "shared.label.enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
  "shared.label.enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])},
  "shared.label.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires"])},
  "shared.label.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "shared.label.failed_segments.count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flagged segments"])},
  "shared.label.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "shared.label.filter_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter segments by"])},
  "shared.label.final_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final score"])},
  "shared.label.first_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First feature"])},
  "shared.label.first_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First flow"])},
  "shared.label.first_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First library"])},
  "shared.label.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "shared.label.flow_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow description"])},
  "shared.label.flow_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow name"])},
  "shared.label.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "shared.label.freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
  "shared.label.freemium.price_total_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total after"])},
  "shared.label.freemium.price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT incl."])},
  "shared.label.freemium.price_without_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT excl."])},
  "shared.label.from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "shared.label.from_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From-To"])},
  "shared.label.from_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From (year)"])},
  "shared.label.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "shared.label.generated_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated content"])},
  "shared.label.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting started"])},
  "shared.label.headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headline"])},
  "shared.label.history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "shared.label.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "shared.label.human_ai_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI Translations"])},
  "shared.label.human_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human step"])},
  "shared.label.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "shared.label.import_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import assets"])},
  "shared.label.inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
  "shared.label.include_suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include supplier services"])},
  "shared.label.internal_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal reviewer"])},
  "shared.label.invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
  "shared.label.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
  "shared.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "shared.label.key_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key name"])},
  "shared.label.keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keys"])},
  "shared.label.keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "shared.label.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "shared.label.language_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language options"])},
  "shared.label.language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language pair"])},
  "shared.label.languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language(s)"])},
  "shared.label.last_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last edit"])},
  "shared.label.last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified"])},
  "shared.label.last_modified_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified at"])},
  "shared.label.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "shared.label.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "shared.label.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
  "shared.label.match_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match rate"])},
  "shared.label.max_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. usage"])},
  "shared.label.messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "shared.label.minimum_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum balance"])},
  "shared.label.money_saved_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money saved with TM"])},
  "shared.label.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "shared.label.multilingual_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual File"])},
  "shared.label.my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tasks"])},
  "shared.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "shared.label.native_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native language"])},
  "shared.label.native_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native role"])},
  "shared.label.never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
  "shared.label.new_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New action"])},
  "shared.label.new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "shared.label.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "shared.label.not_found_freelancers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You haven't added any freelancers for this service yet. Click ", _interpolate(_named("here")), " to add one now"])},
  "shared.label.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "shared.label.number_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of projects"])},
  "shared.label.offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
  "shared.label.online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "shared.label.open_in_cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in Content Pillars"])},
  "shared.label.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
  "shared.label.or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
  "shared.label.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "shared.label.pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "shared.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "shared.label.password_strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password strength"])},
  "shared.label.passwords_dont_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords do not match"])},
  "shared.label.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "shared.label.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "shared.label.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "shared.label.period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "shared.label.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "shared.label.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "shared.label.pick_translation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick between translation types"])},
  "shared.label.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "shared.label.preferred_app_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred app language"])},
  "shared.label.preferred_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred date"])},
  "shared.label.preferred_deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred deadline"])},
  "shared.label.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "shared.label.previous_revised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous (revised)"])},
  "shared.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "shared.label.price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price from"])},
  "shared.label.price_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price/month"])},
  "shared.label.price_per_amount_words": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Price per ", _interpolate(_named("amount")), " words"])},
  "shared.label.price_per_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per task"])},
  "shared.label.price_per_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per word"])},
  "shared.label.price_without_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price (VAT excl.)"])},
  "shared.label.pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
  "shared.label.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "shared.label.product_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product key"])},
  "shared.label.product_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product keywords"])},
  "shared.label.product_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product library"])},
  "shared.label.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "shared.label.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "shared.label.project_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project identifier"])},
  "shared.label.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA"])},
  "shared.label.qa_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA parameters"])},
  "shared.label.qa_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA price"])},
  "shared.label.qa_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA scores"])},
  "shared.label.qa_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
  "shared.label.quality_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality results"])},
  "shared.label.rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "shared.label.receipt_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt e-mail address"])},
  "shared.label.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
  "shared.label.replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
  "shared.label.replace_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace all"])},
  "shared.label.replace_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace with"])},
  "shared.label.required_indication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* indicates required field"])},
  "shared.label.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "shared.label.review_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review price"])},
  "shared.label.reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
  "shared.label.reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer(s)"])},
  "shared.label.reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "shared.label.revised_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revised on"])},
  "shared.label.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "shared.label.roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "shared.label.sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox environment"])},
  "shared.label.seacrh_and_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search and replace"])},
  "shared.label.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "shared.label.see_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See customers"])},
  "shared.label.segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment"])},
  "shared.label.segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segments"])},
  "shared.label.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "shared.label.select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "shared.label.select_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select area of expertise"])},
  "shared.label.select_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from list"])},
  "shared.label.select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])},
  "shared.label.select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select languages"])},
  "shared.label.select_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a tier"])},
  "shared.label.select_workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workflow"])},
  "shared.label.service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service type"])},
  "shared.label.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "shared.label.set_as_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default"])},
  "shared.label.source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "shared.label.source_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source file"])},
  "shared.label.source_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source language"])},
  "shared.label.source_lang_copyediting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "shared.label.source_language_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source language value"])},
  "shared.label.source_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source(s)"])},
  "shared.label.source_term_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source: Glossary"])},
  "shared.label.specific_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific instructions"])},
  "shared.label.specific_instructions.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See comments"])},
  "shared.label.specific_instructions.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments to your freelancer"])},
  "shared.label.specific_instructions_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add any instruction and/or comment to your freelancer"])},
  "shared.label.standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard (12 months)"])},
  "shared.label.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "shared.label.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription start date"])},
  "shared.label.start_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start project"])},
  "shared.label.started_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
  "shared.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "shared.label.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "shared.label.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "shared.label.supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])},
  "shared.label.supplier.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "shared.label.supplier.view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more"])},
  "shared.label.supplier_margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier margin"])},
  "shared.label.supported_formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported file formats"])},
  "shared.label.supported_language_pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported language pairs"])},
  "shared.label.table_data_pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("from")), "-", _interpolate(_named("to")), " of ", _interpolate(_named("of"))])},
  "shared.label.tag_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag mismatch"])},
  "shared.label.tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "shared.label.target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "shared.label.target_initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target (initial)"])},
  "shared.label.target_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target language"])},
  "shared.label.target_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target language(s)"])},
  "shared.label.target_revised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target (revised)"])},
  "shared.label.target_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target(s)"])},
  "shared.label.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
  "shared.label.task_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task type"])},
  "shared.label.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
  "shared.label.team_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team admin"])},
  "shared.label.terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "shared.label.terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General terms and conditions"])},
  "shared.label.tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tier"])},
  "shared.label.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
  "shared.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "shared.label.tm_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation memory match"])},
  "shared.label.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "shared.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "shared.label.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
  "shared.label.total_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total strings"])},
  "shared.label.total_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total tasks"])},
  "shared.label.total_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total word count"])},
  "shared.label.total_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total words"])},
  "shared.label.translate_yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate yourself"])},
  "shared.label.translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translated"])},
  "shared.label.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation"])},
  "shared.label.translation_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation library"])},
  "shared.label.translation_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human translation + review"])},
  "shared.label.translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translations"])},
  "shared.label.translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator"])},
  "shared.label.translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator(s)"])},
  "shared.label.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
  "shared.label.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "shared.label.type_keywords_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your keywords here"])},
  "shared.label.unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
  "shared.label.unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unread"])},
  "shared.label.unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unselect all"])},
  "shared.label.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "shared.label.upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file (optional)"])},
  "shared.label.upload_files.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or browse for files"])},
  "shared.label.upload_files.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag files here to upload"])},
  "shared.label.used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
  "shared.label.user.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set user status"])},
  "shared.label.user_is_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is already an invitation for a user with this email address"])},
  "shared.label.vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
  "shared.label.vat_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Tax (25%)"])},
  "shared.label.view_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to project details"])},
  "shared.label.welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "shared.label.with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with"])},
  "shared.label.word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word count"])},
  "shared.label.word_count_pr_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word count pr. language pair"])},
  "shared.label.words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words"])},
  "shared.label.worflow.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow name already exists"])},
  "shared.label.workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow"])},
  "shared.label.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "shared.label.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "shared.label.yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "shared.label.your_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your messages"])},
  "shared.labels.characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters"])},
  "shared.labels.created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "shared.labels.here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here."])},
  "shared.labels.or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "shared.labels.workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workspace"])},
  "shared.languages.aa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afar"])},
  "shared.languages.af": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrikaans"])},
  "shared.languages.am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amharic"])},
  "shared.languages.ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
  "shared.languages.ar_EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic (Egypt)"])},
  "shared.languages.ar_MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic (Morocco)"])},
  "shared.languages.ar_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic (Sudan)"])},
  "shared.languages.ar_SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic (Syria)"])},
  "shared.languages.az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijani"])},
  "shared.languages.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarusian"])},
  "shared.languages.bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
  "shared.languages.bm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambara"])},
  "shared.languages.bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bengali"])},
  "shared.languages.bo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tibetan"])},
  "shared.languages.bs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnian"])},
  "shared.languages.byn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blin (Bilin)"])},
  "shared.languages.ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])},
  "shared.languages.ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chechen"])},
  "shared.languages.ckb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorani"])},
  "shared.languages.cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
  "shared.languages.cy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welsh"])},
  "shared.languages.da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danish"])},
  "shared.languages.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "shared.languages.de_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German (Austria)"])},
  "shared.languages.de_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German (Switzerland)"])},
  "shared.languages.dv_MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhivehi"])},
  "shared.languages.dz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzongkha"])},
  "shared.languages.el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
  "shared.languages.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "shared.languages.en_AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (Australia)"])},
  "shared.languages.en_GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (United Kingdom)"])},
  "shared.languages.en_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (India)"])},
  "shared.languages.en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (United States)"])},
  "shared.languages.en_ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (South Africa)"])},
  "shared.languages.eo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperanto"])},
  "shared.languages.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "shared.languages.es_419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish (Latin America)"])},
  "shared.languages.es_ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish (European)"])},
  "shared.languages.es_MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish (Mexico)"])},
  "shared.languages.et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonian"])},
  "shared.languages.eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basque"])},
  "shared.languages.fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farsi"])},
  "shared.languages.fa_IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persian (Farsi)"])},
  "shared.languages.ff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fula"])},
  "shared.languages.fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnish"])},
  "shared.languages.fil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipino"])},
  "shared.languages.fo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroese"])},
  "shared.languages.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "shared.languages.fr_BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French (Belgium)"])},
  "shared.languages.fr_CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French (Canada)"])},
  "shared.languages.fr_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French (Switzerland)"])},
  "shared.languages.fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French (Standard)"])},
  "shared.languages.fy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisian"])},
  "shared.languages.ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irish"])},
  "shared.languages.gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galician"])},
  "shared.languages.gu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
  "shared.languages.ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausa"])},
  "shared.languages.he": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
  "shared.languages.hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "shared.languages.hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
  "shared.languages.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
  "shared.languages.hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenian"])},
  "shared.languages.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
  "shared.languages.ig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igbo"])},
  "shared.languages.is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icelandic"])},
  "shared.languages.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "shared.languages.it_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian (Switzerland)"])},
  "shared.languages.it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian (Standard)"])},
  "shared.languages.iu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inuktitut"])},
  "shared.languages.ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
  "shared.languages.jv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javanese"])},
  "shared.languages.ka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgian"])},
  "shared.languages.kk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakh"])},
  "shared.languages.kl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenlandic"])},
  "shared.languages.km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
  "shared.languages.kmr_SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurmanji (Syria)"])},
  "shared.languages.kmr_TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurmanji (Turkey)"])},
  "shared.languages.kn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
  "shared.languages.ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
  "shared.languages.kri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krio"])},
  "shared.languages.kz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyz"])},
  "shared.languages.la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin"])},
  "shared.languages.lb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourgish"])},
  "shared.languages.ln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingala"])},
  "shared.languages.lo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
  "shared.languages.lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuanian"])},
  "shared.languages.lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
  "shared.languages.man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandingo (Mandinka)"])},
  "shared.languages.mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malagasy"])},
  "shared.languages.mk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonian"])},
  "shared.languages.ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malayalam"])},
  "shared.languages.mn_MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolian"])},
  "shared.languages.mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
  "shared.languages.ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malay"])},
  "shared.languages.mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maltese"])},
  "shared.languages.my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
  "shared.languages.nb_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian (Bokmål)"])},
  "shared.languages.ne_NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepali"])},
  "shared.languages.nl_BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch (Belgium)"])},
  "shared.languages.nl_NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch (Standard)"])},
  "shared.languages.nn_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian (Nynorsk)"])},
  "shared.languages.om": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oromo"])},
  "shared.languages.pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjabi"])},
  "shared.languages.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
  "shared.languages.prs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
  "shared.languages.ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pashto"])},
  "shared.languages.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
  "shared.languages.pt_BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese (Brazil)"])},
  "shared.languages.pt_PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese (European)"])},
  "shared.languages.rm_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romansh (Switzerland)"])},
  "shared.languages.rn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirundi"])},
  "shared.languages.ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
  "shared.languages.ro_MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldovian"])},
  "shared.languages.ro_RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
  "shared.languages.rom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romani"])},
  "shared.languages.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
  "shared.languages.rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinyarwanda"])},
  "shared.languages.se_FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sami"])},
  "shared.languages.si_LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
  "shared.languages.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
  "shared.languages.sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
  "shared.languages.so": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somali"])},
  "shared.languages.sq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanian"])},
  "shared.languages.sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
  "shared.languages.sr_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian (Montenegro)"])},
  "shared.languages.sr_cyrl_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian (Cyrillic, Montenegro)"])},
  "shared.languages.sr_cyrl_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian (Cyrillic, Serbia)"])},
  "shared.languages.sr_latn_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian (Latin, Montenegro)"])},
  "shared.languages.sr_latn_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian (Latin, Serbia)"])},
  "shared.languages.sr_latn_SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian (Latin, Serbia)"])},
  "shared.languages.st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotho"])},
  "shared.languages.sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
  "shared.languages.sw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swahili"])},
  "shared.languages.ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
  "shared.languages.te": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
  "shared.languages.tg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajik"])},
  "shared.languages.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
  "shared.languages.ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tigrinya"])},
  "shared.languages.tig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tigre"])},
  "shared.languages.tk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmen"])},
  "shared.languages.tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagalog"])},
  "shared.languages.tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
  "shared.languages.tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twi"])},
  "shared.languages.ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyghur"])},
  "shared.languages.uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
  "shared.languages.ur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urdu"])},
  "shared.languages.uz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbek"])},
  "shared.languages.vi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
  "shared.languages.wo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolof"])},
  "shared.languages.xh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xhosa"])},
  "shared.languages.yi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiddish"])},
  "shared.languages.yo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoruba"])},
  "shared.languages.zh_CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese (Simplified)"])},
  "shared.languages.zh_HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional Chinese (Hongkong)"])},
  "shared.languages.zh_TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional Chinese (Taiwan)"])},
  "shared.languages.zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulu"])},
  "shared.link.how_to_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to connect"])},
  "shared.loader.text.0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your left thumb points to the right and your right thumb points to the left."])},
  "shared.loader.text.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't worry - a few bits tried to escape, but we caught them"])},
  "shared.loader.text.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...at least you're not on hold..."])},
  "shared.loader.text.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How about this weather, eh?"])},
  "shared.loader.text.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unicorns are at the end of this road, I promise"])},
  "shared.loader.text.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're building the buildings as fast as we can"])},
  "shared.mfa.authenticator.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify with two-factor authenticator code"])},
  "shared.mfa.banner_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an additional security layer to your account by enabling two-factor authentication."])},
  "shared.mfa.banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
  "shared.mfa.confirm_password_disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To disable 2FA, you have to confirm your password."])},
  "shared.mfa.confirm_password_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable 2FA, you have to confirm your password."])},
  "shared.mfa.enable_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you enable two-factor authentication, you will be adding an extra security layer to your account. When logging in, you will be requested to enter a random code generated by the authenticator application of your choice."])},
  "shared.mfa.enable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable two-factor authentication"])},
  "shared.mfa.enabled_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already enabled two-factor authentication. You can disable it by clicking on 'disable' below."])},
  "shared.mfa.recovery_code.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify with a recovery code"])},
  "shared.mfa.scan_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to scan this QR with the authenticator app of your choice and copy your recovery codes in a safe place. The QR and codes will only be shown once and they will be destroyed when you close this dialogue window."])},
  "shared.mfa.scan_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan your QR code"])},
  "shared.mfa.verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your two-factor authentication"])},
  "shared.modal.repetitions.linked_segments.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the segments you would like to apply your changes to"])},
  "shared.modal.repetitions.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to apply your change(s) across all identical segments?\nClicking yes, will enable this function for all identical segments"])},
  "shared.modal.repetitions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This segment appears multiple times."])},
  "shared.nav.account.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "shared.nav.language_assets.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Assets"])},
  "shared.nav.logout.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "shared.nav.news.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "shared.nav.news.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's new?"])},
  "shared.nav.payment.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "shared.nav.settings.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "shared.new.chat.customer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of the freelancers involved in this chat will see your message. This function is not supported for QA tasks. To send a DM to a freelancer, please go to the message function on the left sidebar."])},
  "shared.new.chat.supplier_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a group conversation. The customer and all the freelancers involved in this task will see your message."])},
  "shared.news.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We constantly evolve! Stay informed about our latest updates, features and more."])},
  "shared.news.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent updates"])},
  "shared.pagination.items_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items per page"])},
  "shared.pagination.page_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're on page no."])},
  "shared.placceholder.no_result_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result found"])},
  "shared.placeholder.caas_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coffee, dark, espresso, shop, 15gr, caffeine"])},
  "shared.placeholder.channel_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (optional)"])},
  "shared.placeholder.channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "shared.placeholder.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "shared.placeholder.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment goes here..."])},
  "shared.placeholder.comment_freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a comment for your freelancer"])},
  "shared.placeholder.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "shared.placeholder.csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert a csv file"])},
  "shared.placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description goes here..."])},
  "shared.placeholder.description_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a description here"])},
  "shared.placeholder.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "shared.placeholder.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
  "shared.placeholder.filter_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter source text"])},
  "shared.placeholder.filter_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter target text"])},
  "shared.placeholder.folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder name"])},
  "shared.placeholder.format.csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".csv"])},
  "shared.placeholder.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "shared.placeholder.library_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library name"])},
  "shared.placeholder.new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New name"])},
  "shared.placeholder.no_match_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No match found"])},
  "shared.placeholder.parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original project ID (all files combined)"])},
  "shared.placeholder.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "shared.placeholder.project_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder name (optional for 1 file, mandatory for multiple)"])},
  "shared.placeholder.project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project ID"])},
  "shared.placeholder.project_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name"])},
  "shared.placeholder.project_name_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name (optional)"])},
  "shared.placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
  "shared.placeholder.search_for_subaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for sub-account"])},
  "shared.placeholder.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your search results will appear here"])},
  "shared.placeholder.search_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a name or identifier"])},
  "shared.placeholder.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
  "shared.placeholder.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your message here..."])},
  "shared.placeholder.type_in_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in your answer here"])},
  "shared.placeholder.type_message_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your message here"])},
  "shared.placeholder.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop your file here or click to upload"])},
  "shared.qa.accuracy.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong meaning, errors in numbers and references, omissions or incorrect tags."])},
  "shared.qa.accuracy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy"])},
  "shared.qa.comment.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "shared.qa.error_severities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error severities"])},
  "shared.qa.language.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctuation, spelling, capitalization or grammar errors."])},
  "shared.qa.language.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "shared.qa.locale.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors in country standards like date formats and currencies, and cultural considerations."])},
  "shared.qa.locale.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locale"])},
  "shared.qa.select_segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a segment to edit QA"])},
  "shared.qa.status.critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical"])},
  "shared.qa.status.critical_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show-stopper: an unacceptable error, e.g. misinterpretations or errors that may carry legal, health or financial consequences."])},
  "shared.qa.status.major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
  "shared.qa.status.major_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors in the headline, ignored translation instructions or significant change of meaning leading to misdirection or distortion of product."])},
  "shared.qa.status.minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor"])},
  "shared.qa.status.minor_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctuation errors or minor typos. Errors that result in slight change of meaning."])},
  "shared.qa.status.no-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No error"])},
  "shared.qa.style.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stylistic errors, disregard of specific guidelines or language register/language variant errors."])},
  "shared.qa.style.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
  "shared.qa.terminology.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconsistent use of terminology or disregard of industry typical/provided glossary."])},
  "shared.qa.terminology.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminology"])},
  "shared.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "shared.reset_password.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "shared.reset_password.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email to request a password reset."])},
  "shared.section.text.gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download and/or delete your personal data."])},
  "shared.section.title.transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency is key to us"])},
  "shared.setupConnection.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Read our knowledgebase article to get started with the EasyTranslate ", _interpolate(_named("plugin")), " plugin."])},
  "shared.status.action_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action required"])},
  "shared.status.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "shared.status.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "shared.status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "shared.status.in_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In draft"])},
  "shared.status.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "shared.status.in_progress_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation in progress"])},
  "shared.status.need_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action required"])},
  "shared.status.not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not started"])},
  "shared.status.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])},
  "shared.status.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "shared.status.project_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project cancelled"])},
  "shared.status.project_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project created"])},
  "shared.status.project_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project declined"])},
  "shared.status.ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation is ready"])},
  "shared.status.unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
  "shared.stripe.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are some extra steps that you need to complete in order to verify your ", _interpolate(_named("stripe")), " account and be able to receive payments."])},
  "shared.sub_nav.history.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "shared.sub_nav.profile.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "shared.terms.ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to take part in the EasyTranslate AI-Learning Program and benefit from smarter, faster, data-driven analyses of my work."])},
  "shared.terms.condition.btn.terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
  "shared.terms.conditions.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I agree to the EasyTranslate ", _interpolate(_named("terms_conditions")), " and the ", _interpolate(_named("privacy_policy")), ". I further agree to receiving ", _interpolate(_named("marketing")), " via e-mails from EasyTranslate A/S regarding ", _interpolate(_named("product_categories")), ", which I can withdraw any time."])},
  "shared.terms.dpa": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("here")), " to download our DPA."])},
  "shared.terms.knowledge_base.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Base"])},
  "shared.terms.processors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("here")), " for more information about our sub-data processors."])},
  "shared.terms.vat_id.example": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("label")), " Danish VAT number: DK12345678; Norwegian VAT number: NO123456789 MVA; Swiss VAT number: CHE 123.456.789 MWST / CHE 123.456.789 TVA / CHE 123.456.789 IVA"])},
  "shared.terms.vat_id.for_instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For instance:"])},
  "shared.terms.vat_id.requirment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every VAT identification number must begin with the code of the relevant country and be followed by a set of digits and/or characters."])},
  "shared.text.create_your_client_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your EasyTranslate account"])},
  "shared.text.iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* By providing your IBAN, you are authorizing *Rocketship Inc* and Stripe, our payment service provider, to send instructions to your bank to debit your account in accordance with those instructions. Subsequent payments are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within eight weeks starting from the date on which your account was debited."])},
  "shared.text.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "shared.text.steps_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps completed"])},
  "shared.text.upload_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a profile picture"])},
  "shared.text.your_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account information"])},
  "shared.text.your_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your billing information"])},
  "shared.text.your_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment method"])},
  "shared.title.figma_pat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Figma PAT"])},
  "shared.title.hygraph_pat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Hygrapth PAT"])},
  "shared.toast.error.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a business email"])},
  "shared.toast.error.download_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file could not be downloaded because the file is not ready or the url is invalid"])},
  "shared.toast.error.no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permissions to perform this action. Please contact your team admin (team admin's email)"])},
  "shared.toast.error.redirect_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when trying to redirect you to Stripe"])},
  "shared.toast.error.send_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message could not be sent"])},
  "shared.toast.error.tags_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is tag mismatch between the source and the target segment"])},
  "shared.toast.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "shared.toast.info.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "shared.toast.label.file_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file exceeds the 10MB limit"])},
  "shared.toast.label.freelancer_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached your limit of Freelancers. Please remove a freelancer before adding a new one."])},
  "shared.toast.message.file.notsupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type not supported"])},
  "shared.toast.success.congrats_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
  "shared.toast.success.data_retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your preferences for storage of personal data have been updated"])},
  "shared.toast.success.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your revision has been marked as finished"])},
  "shared.toast.success.mfa_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication has been disabled on your profile"])},
  "shared.toast.success.mfa_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication has been enabled for your profile"])},
  "shared.toast.success.password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new password has been saved"])},
  "shared.toast.success.preparing_export_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file is being processed. You will receive an email once the file is ready for download."])},
  "shared.toast.success.privacy_consent_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your privacy & consents preferences have been updated."])},
  "shared.toast.success.requesst_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request to delete this account has been sent. The process can take up to 14 working days."])},
  "shared.toast.success.request_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your deletion request was sent and we will begin to process it very soon"])},
  "shared.toast.success.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your changes were saved"])},
  "shared.toast.success.saved_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
  "shared.toast.success.send_forgot_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have requested to recover your password. You will soon receive an email with instructions."])},
  "shared.toast.success.subaccount_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your sub-account was created"])},
  "shared.toast.success.tag_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag fix complete"])},
  "shared.toast.success.thanks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
  "shared.toast.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "shared.toast.success.uncofirm_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the segments have been unconfirmed"])},
  "shared.toast.success.update_profile_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile information was updated"])},
  "shared.toast.warning.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "shared.tooltip.documents.word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every word included in your plain document, counts towards the word count shown below"])},
  "shared.translation_form.choose_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose area of expertise"])},
  "shared.translation_form.choose_translation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose service type"])},
  "shared.translator_form.billing_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing / Wallet"])},
  "shared.translator_form.btn.settingup_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up"])},
  "shared.translator_form.btn.setup_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up billing"])},
  "shared.translator_form.choose_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose payment method"])},
  "shared.translator_form.setup_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your billing to build custom workflow"])},
  "shared.translator_form.translator_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have now added your translator"])},
  "shared.translator_list.no_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No translators found"])},
  "shared.unsupported.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try to access the editor from desktop device"])},
  "shared.unsupported.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor is not supported on smaller screen sizes"])},
  "shared.user.status.not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not supported"])},
  "shared.validation.password.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a business email"])},
  "shared.validation.password.min_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters long"])},
  "shared.validation.password.not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords do not match"])},
  "shared.validation.password.strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long and contain digits, special characters, lowercase and uppercase"])},
  "shared.validation.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number"])},
  "shared.voucher.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Receive a ", _interpolate(_named("price")), "€ voucher and access all our features for free!"])},
  "supplier.account.delete_modal.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this account?"])},
  "supplier.account.delete_modal.confirm_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove account ", _interpolate(_named("account")), "?"])},
  "supplier.account.delete_modal.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be aware that the account will be permanently deleted and that this action is irreversible"])},
  "supplier.availability.calendar.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My calendar"])},
  "supplier.availability.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems like you have not planned any time off periods yet"])},
  "supplier.availability.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time off found"])},
  "supplier.availability.modal_add.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a start date and end date for your time off."])},
  "supplier.availability.modal_add.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new time off period"])},
  "supplier.availability.modal_remove.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to set yourself as available the following dates?"])},
  "supplier.availability.modal_remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove time off"])},
  "supplier.availability.settings.days_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which days are you available to work?"])},
  "supplier.availability.settings.hourly_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your hourly word output?"])},
  "supplier.availability.settings.hourly_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many hours/day do you work?"])},
  "supplier.availability.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability settings"])},
  "supplier.availability.settings.work_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the start and end time of your workday (from-to)"])},
  "supplier.availability.time.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add time off"])},
  "supplier.availability.time.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My time off"])},
  "supplier.chat.account_selector.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workspace/customer"])},
  "supplier.clients_list.no_clients_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customers have used this service yet."])},
  "supplier.clients_list.no_clients_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customers"])},
  "supplier.content_pillars.editor.header.content_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content pillar"])},
  "supplier.content_pillars.editor.header.intro.text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to edit a text that was AI generated using customer input and relevant keywords."])},
  "supplier.content_pillars.editor.header.intro.text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use the reference material in the below info box - it provides valuable information on context, content type, a short task description and keywords."])},
  "supplier.content_pillars.editor.header.intro.text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If available, we provide the link to the website the keywords were extracted from for even more insight. Please edit the output carefully to match customer requirements and use the prompt function if  necessary. Make sure that the text field only contains your final output before completing the task."])},
  "supplier.content_pillars.editor.header.intro.thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
  "supplier.content_pillars.editor.header.intro.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey there, a new Copy Editing task is ready for you!"])},
  "supplier.content_pillars.editor.header.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "supplier.content_pillars.error.task_id_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"task id is undefined\". We couldn't complete your task. Please reload and try again."])},
  "supplier.cooperation_agreement.modal.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to continue"])},
  "supplier.cooperation_agreement.modal.checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that I have read, understood and that I agree to the Supplier Cooperation Agreement"])},
  "supplier.cooperation_agreement.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can continue using our platform, you need to agree to our new cooperation agreement.\n\nIf you had already signed the agreement before, we kindly ask you to do it again: There was a mistake in the agreement's format that had to be amended. The agreement's terms have not changed."])},
  "supplier.cooperation_agreement.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New cooperation agreement"])},
  "supplier.download_source_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download source file"])},
  "supplier.finish_copywriting.toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully completed your task"])},
  "supplier.language_pair.delete.modal.confirmation_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete the service ", _interpolate(_named("service")), " from account ", _interpolate(_named("account")), "?"])},
  "supplier.language_pair.delete.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this language pair?"])},
  "supplier.language_pair.delete.modal.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be aware that the service will be permanently deleted and that this action is irreversible"])},
  "supplier.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can chat with your customer"])},
  "supplier.methods.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate will never share your payment information with third parties without your knowledge and consent"])},
  "supplier.methods.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments made easy"])},
  "supplier.methods.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See my Stripe account"])},
  "supplier.methods.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Stripe to make sure you get paid on time and to keep your personal and bank details secure."])},
  "supplier.methods.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
  "supplier.my_clients.btn.services_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See services & prices"])},
  "supplier.nav.availability.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My availability"])},
  "supplier.nav.clients.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My customers"])},
  "supplier.nav.tasks.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tasks"])},
  "supplier.news.03-07-2023.more_customers.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "supplier.news.03-07-2023.more_customers.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be even more visible to potential customer, all you need to do, is to go your profile and opt-in to sharing your profile on our public website and be added to our freelance catalogue. You will have the opportunity to opt-out at any time you wish to do so by changing this setting back to the default."])},
  "supplier.news.03-07-2023.more_customers.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more customers!"])},
  "supplier.news.13-10-2023.content_pillars.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "supplier.news.13-10-2023.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer is now able to create a content roadmap that focuses on SEO optimised content. Keep an eye out for new tasks. They’ll be similar to copy editing tasks, but they’ll have a detailed instruction on how to apply the tool correctly, so you can provide the best possible output to your customers."])},
  "supplier.news.13-10-2023.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ve just added a new feature - Content Pillars!"])},
  "supplier.news.14-04-2021.preferred_deadline.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-calendar-day"])},
  "supplier.news.14-04-2021.preferred_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your customer has now the ability to set a preferred deadline, which may not correlate with your set availability. Please confirm the preferred deadline by accepting the task in Memsource or decline it if you cannot meet the deadline. If you are not able to meet the preferred deadline, please reach out to your customer via the message function in the platform to coordinate."])},
  "supplier.news.14-04-2021.preferred_deadline.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred deadline"])},
  "supplier.news.16-09-2020.availability.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-calendar-day"])},
  "supplier.news.16-09-2020.availability.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help you calculate delivery time for your customers with availability. You are now able to set yourself as unavailable in holiday periods among others, so your customers know when they can expect their translations delivered."])},
  "supplier.news.16-09-2020.availability.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
  "supplier.news.16-09-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "supplier.news.16-09-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have experienced the importance of being able to communicate directly with your customers regarding specific projects. We are therefore introducing Messages. Messages is your way of communicating directly with your customers. To write a message to your customer, go to the project you would like to message your customer about and write the message."])},
  "supplier.news.16-09-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review editor for NEW project type “Review”."])},
  "supplier.news.23-11-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "supplier.news.23-11-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finally, we can reveal our first version of the EasyTranslate Review Editor, which gives you the ability to do your review task directly in EasyTranslate Platform. The revisions are updated in translation memory for instant improvement of future translations. You will also be able to see the edits done after delivery by the customer for future improvements."])},
  "supplier.news.23-11-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review editor for NEW project type “Review”."])},
  "supplier.news.24-11-2021.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-comments"])},
  "supplier.news.24-11-2021.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To give you a better visibility on what you are writing, we have reviewed and implemented the design of the chat input in the editor. You no longer have to navigate through what you have written to read or modify it, the input field is now displaying your content in lines to give you a full overview. NB. The chat feature is only available when one or more translator(s) of your team is involved in the project."])},
  "supplier.news.24-11-2021.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "supplier.news.24-11-2021.history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-history"])},
  "supplier.news.24-11-2021.history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The EasyTranslate review Editor has received a new functionality you will like: the history. Thanks to the history, keep track of all changes made to a segment at any given time. Restore previous versions or simply analyse the review process, the choice is yours."])},
  "supplier.news.24-11-2021.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "supplier.news.25-01-2022.client_overview.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "supplier.news.25-01-2022.client_overview.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have now implemented a customer overview, so you can see all your clients in a grid view, as well as what services your offer them."])},
  "supplier.news.25-01-2022.client_overview.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer overview"])},
  "supplier.news.25-01-2022.new_pricing.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-dollar-sign"])},
  "supplier.news.25-01-2022.new_pricing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have now introduced a new pricing plan to our customers: freemium! When leads sign up for the plan, they can select you as their translator, and send you projects.It will work just as it does now, but without the need for test translations!"])},
  "supplier.news.25-01-2022.new_pricing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pricing"])},
  "supplier.news.25-01-2023.menu.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-bars"])},
  "supplier.news.25-01-2023.menu.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have placed your settings and news section inside the profile menu on the bottom left corner of the application. We have also moved the Message function to the left sidebar."])},
  "supplier.news.25-01-2023.menu.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes to the menu!"])},
  "supplier.news.26-10-2022.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "supplier.news.26-10-2022.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out our new chat in the top right corner! You can now create groups and channels, attach files and images and much more!"])},
  "supplier.news.26-10-2022.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and improved chat!"])},
  "supplier.news.29-03-2021.search_editor_feature.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "supplier.news.29-03-2021.search_editor_feature.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Translation Memory and Term Bank are now accessible and searchable within the Editor tool, so you can ensure consistency in no time without leaving the page. Use the search field in the revision phase to look up words and see if they match your Translation Memory and/or Term Bank. All matches found will be highlighted in the source section of your content. Need to monitor your character count? These are now visible in both the source and target fields too."])},
  "supplier.news.29-03-2021.search_editor_feature.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search within the Editor feature"])},
  "supplier.onboarding.about_you.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About you"])},
  "supplier.onboarding.about_you.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us more about you"])},
  "supplier.onboarding.payout.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
  "supplier.onboarding.payout.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Stripe to make sure you get paid on time and to keep your personal bank and details secure."])},
  "supplier.onboarding.payout.text_two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on ", _interpolate(_named("next")), " to set up your payments on Stripe."])},
  "supplier.onboarding.payout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment information"])},
  "supplier.onboarding.services.hourly_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your hourly word output?"])},
  "supplier.onboarding.services.hours_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many hours do you work per day?"])},
  "supplier.onboarding.services.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your services"])},
  "supplier.onboarding.services.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you manage your work?"])},
  "supplier.onboarding.services.whih_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which days are you available to work?"])},
  "supplier.onboarding.services.working_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What time is your working window? (from - to)"])},
  "supplier.onboarding.success.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "supplier.onboarding.success.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are excited you have chosen to partner with us! Your work is important to us and now that you completed your account set up, you are ready to go."])},
  "supplier.onboarding.success.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lots of tasks are coming your way."])},
  "supplier.onboarding.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your supplier account"])},
  "supplier.profile.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate will never share your personal information with third parties without your knowledge and consent"])},
  "supplier.profile.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't share your information with others"])},
  "supplier.profile.catalogue.switch_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch off to stop sharing your profile on our public website at the following url:"])},
  "supplier.profile.catalogue.switch_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch on to share your profile on our public website at the following url:"])},
  "supplier.profile.embed_code.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By embedding this code snippet into your website, email signature etc., you can add an interactive image that redirects users to your EasyTranslate profile."])},
  "supplier.profile.embed_code.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed code for your EasyTranslate profile link"])},
  "supplier.profile.label.public_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to your public profile"])},
  "supplier.profile.placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your description here..."])},
  "supplier.save_document.toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document has been saved successfully"])},
  "supplier.services.create.button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new service"])},
  "supplier.services.create.modal.button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create service"])},
  "supplier.services.create.modal.select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a language for the new service"])},
  "supplier.services.create.modal.select_language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a language pair for the new service"])},
  "supplier.services.create.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new service"])},
  "supplier.services.delete.modal.confirm_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete the service ", _interpolate(_named("service")), " ?"])},
  "supplier.services.delete.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this service?"])},
  "supplier.services.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see and manage your services and their pricing. You can create and delete services as well as change their prices."])},
  "supplier.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
  "supplier.status_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as not-supported"])},
  "supplier.sub_nav.cooperation.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperation Agreement"])},
  "supplier.sub_nav.gdpr.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
  "supplier.sub_nav.history.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "supplier.sub_nav.methods.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methods"])},
  "supplier.sub_nav.services.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "supplier.task.decline_task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to decline this task?"])},
  "supplier.task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task information"])},
  "supplier.terms.contract_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate Partner Programme Contract"])},
  "supplier.terms.contract_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I agree to have read and be bound by the ", _interpolate(_named("link")), " in order to work on language projects for customers.*"])},
  "supplier.terms.data_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to share the above data and create a translator account for the purpose of working with EasyTranslate's platform and customers.*"])},
  "supplier.toast.error.empty_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find your invitation"])},
  "supplier.toast.error.invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong with the download. Please try again a bit later."])},
  "supplier.toast.error.load_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when trying to load your tasks"])},
  "supplier.toast.error.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when trying to open your task"])},
  "supplier.toast.error.payout_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when loading your payout history"])},
  "supplier.toast.success.delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your service was deleted"])},
  "supplier.toast.success.invitations_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The invitations were successfully sent to the suppliers"])},
  "supplier.toast.success.save_consents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have registered your consent to our cooperation agreement"])},
  "supplier.toast.success.update_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile picture was updated"])},
  "suppliers.my_clients.title.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation and Review Services"])},
  "suppliers.my_clients.title.services_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI Translation Services"])},
  "suppliers.my_clients.title.services_copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy editing Services"])},
  "suppliers.my_clients.title.services_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance Services"])}
}
<template>
  <div class="projectFilters flex flex-col gap-2">
    <div class="flex items-center pb-1 rounded-md">
      <InputField
        id="project_search_input"
        class="w-full mr-4 tabletPortrait:mr-6"
        prefix-icon="fa-magnifying-glass"
        :placeholder="placeholder"
        @inputChange="({value}) => search = value"
      />
      <a
        data-intercom-target="Filter"
        data-cy="filters_toggle"
        class="group/filterIcon w-fit min-w-fit block ml-auto py-2.5 px-0 text-neutral-200 hover:cursor-pointer hover:text-primary-100"
        @click="toggleFilters"
      >
        <font-awesome-icon icon="fa-filter" />

        {{ $t('shared.button.filters') }}
      </a>
    </div>
    <div
      class="flex flex-col items-center gap-2.5 max-h-0 opacity-0 overflow-hidden p-0 transition-all duration-[600ms] ease-[cubic-bezier(0.33,_1,_0.68,_1)] tabletPortrait:flex-row"
      :class="{
        'opacity-100 max-h-[400px] tabletPortrait:max-h-[60px] mb-3':
          showFilters
      }"
    >
      <Select
        class="w-full mb-0 grid grid-cols-1 tabletPortrait:grid-cols-4 grid-rows-4 tabletPortrait:grid-rows-1 gap-y-2.5 tabletPortrait:gap-y-0 tabletPortrait:gap-x-2.5"
      >
        <SelectSelection
          v-for="filter in projectFilters"
          :key="filter.type"
          :data-cy="filter.title"
          :value="filter.type"
          @inputChange="onFilterProjects($event, filter.type)"
        >
          <SelectOption disabled :value="filter.type">
            <span>{{ filter.title }}</span>
          </SelectOption>
          <SelectOption
            v-for="item in filter.data"
            :key="item.name"
            :value="item.value"
          >
            <span> {{ item.name }}</span>
          </SelectOption>
        </SelectSelection>
      </Select>
      <BtnSecondary
        :title="$t('shared.button.clear')"
        class="bg-white py-2 px-4"
        neutral
        @click="removeFilters"
      />
    </div>
  </div>
</template>
<script setup>
import InputField from '@/components/shared/input/InputField'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import Select from '@/components/shared/select/Select'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const $t = i18n.t

const store = useStore()
const route = useRoute()

const search = ref(null)
const showFilters = ref(false)

const pages = computed(() => store.state.workspace.pages)
const projectFilters = computed(() => store.state.workspace.projectFilters)
const folderId = computed(() => store.state.workspace.folderId)

const placeholder = computed(() =>
  route.name === 'clientProjects'
    ? $t('customer.placeholder.search_project')
    : $t('shared.placeholder.search_task')
)

watch(
  () => search.value,
  (searchInput) => {
    handleSearch(searchInput)
  }
)

const handleSearch = _.debounce(async function (searchInput) {
  startLoader({ shy: true })
  if (!searchInput) {
    await getProjects({
      search: null,
      currentPage: 1,
      perPage: pages.value.perPage,
      folderId: folderId.value
    })
  } else {
    await getProjects({
      search: search.value,
      currentPage: 1,
      perPage: pages.value.perPage,
      folderId: folderId.value
    })
  }
  stopLoader()
}, 1000)

onMounted(async () => {
  await getProjectFilters()
  if (route.query.search) {
    search.value = route.query.search
  }
})

const stopLoader = () => store.dispatch('loading/stopLoader')
const startLoader = (payload) => store.dispatch('loading/startLoader', payload)
const getProjects = (payload) =>
  store.dispatch('workspace/getProjects', payload)
const getProjectFilters = () => store.dispatch('workspace/getProjectFilters')
const updateProjectFilter = (payload) =>
  store.commit('workspace/updateProjectFilter', payload)

async function onFilterProjects(value, type) {
  await updateProjectFilter({ value, type })
  await getProjects({
    search: search.value,
    currentPage: 1,
    perPage: pages.value.perPage,
    folderId: folderId.value
  })
}
function toggleFilters() {
  showFilters.value = !showFilters.value
}
async function removeFilters() {
  search.value = null
  await getProjectFilters()
  await getProjects({
    search: null,
    currentPage: 1,
    perPage: pages.value.perPage,
    folderId: folderId.value
  })
}
</script>

<template>
  <div>
    <slot name="label" />
    <div class="flex relative">
      <input
        :id="id"
        :ref="refName"
        v-model="inputValue"
        :data-cy="id"
        :class="[
          'block w-full p-2.5 text-black placeholder:text-neutral-100 text-sm bg-neutral-50 border border-neutral-80 rounded-lg focus:border-primary-100 hover:border-primary-100 focus:ring-0 focus-visible:outline-none',
          {
            'bg-white': !disabled,
            'text-center': centered,
            '!border-error-100': redBorder,
            '!border-neutral-100': darkGrayBorder,
            '!py-1 !pr-1.5': small,
            '!pl-10': prefixIcon || customPrefix,
            '!pr-8': icon,
            '!pr-5 !pl-1.5 !w-[60px]': percentage,
            isTypeFile: type === 'file',
            inputAppearanceNone,
            '!pr-6': copyMode,
            '!text-error-100 !border-error-100': validationStatus === 'error'
          }
        ]"
        :placeholder="`${placeholder}${indicator}`"
        :disabled="disabled"
        :type="type"
        :autofocus="autofocus"
        :accept="accept"
        :min="min"
        :max="max"
        :step="step"
        :required="forceRequired || isRequired"
        @keypress="validate"
        @blur="$emit('blur')"
      />
      <template v-if="prefixIcon || customPrefix">
        <div
          class="w-10 flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none overflow-hidden"
        >
          <font-awesome-icon
            v-if="prefixIcon"
            :icon="`fa-solid ${prefixIcon}`"
            class="!text-neutral-200 dark:text-neutral-200"
          />
          <span v-else class="text-primary-100 font-semibold">{{
            customPrefix
          }}</span>
        </div>
      </template>
      <template v-if="percentage">
        <div class="absolute right-2.5 bottom-2.5">
          <p>%</p>
        </div>
      </template>
      <template v-if="icon">
        <div class="absolute right-2.5 bottom-2.5">
          <i :class="`${icon} icon`" />
        </div>
      </template>
      <template v-if="forceRequired || isRequired">
        <div class="absolute right-2.5 bottom-2.5">
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            color="red"
          />
        </div>
      </template>
      <template v-if="copyMode">
        <div class="absolute right-2.5 bottom-2.5">
          <font-awesome-icon icon="fa-solid fa-clipboard" />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
const props = defineProps({
  keyName: [String, Number],
  type: {
    type: String,
    default: 'text'
  },
  value: [String, Number],
  copyMode: {
    type: Boolean,
    default: false
  },
  disabled: Boolean,
  placeholder: String,
  icon: String,
  id: String,
  accept: String,
  refName: String,
  min: Number,
  max: Number,
  step: Number,
  small: Boolean,
  optional: Boolean,
  required: {
    type: Boolean,
    default: false
  },
  forceRequired: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  centered: {
    type: Boolean,
    default: false
  },
  inputAppearanceNone: {
    type: Boolean,
    default: false
  },
  percentage: {
    type: Boolean,
    default: false
  },
  redBorder: {
    type: Boolean,
    default: false
  },
  darkGrayBorder: {
    type: Boolean,
    default: false
  },
  prefixIcon: String,
  customPrefix: String
})

const emit = defineEmits(['inputChange', 'blur'])

const inputValue = ref('')
const isRequired = ref('')
const validationStatus = computed(() => {
  if (props.forceRequired || isRequired.value) {
    return 'error'
  }

  return ''
})

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})

watch(
  () => inputValue.value,
  (val) => {
    if (val === '') {
      isRequired.value = props.required
    } else {
      isRequired.value = false
    }
    if (val === undefined) return
    if (props.type === 'number') {
      if (!val && props.min && props.min > 0) {
        inputValue.value = ''
      }
      if (val && props.max && Number(val) > props.max) {
        inputValue.value = props.max
        return
      }
      if (val && props.min && Number(val) < props.min) {
        inputValue.value = props.min
        return
      }
    }
    emit('inputChange', { value: val, field: props.keyName })
  }
)

watch(
  () => props.value,
  (val) => {
    inputValue.value = val
  }
)

onMounted(() => {
  inputValue.value = props.value
})

function validate(e) {
  if (!e) {
    return
  }
  if (props.type !== 'number') {
    return
  }
  let key
  if (e.type === 'paste') {
    key = e.clipboardData.getData('text/plain')
  } else {
    key = e.keyCode || e.which
    key = String.fromCharCode(key)
  }
  const regex = /[0-9]|\./
  if (!regex.test(key)) {
    e.returnValue = false
    if (e.preventDefault) e.preventDefault()
  }
}
</script>

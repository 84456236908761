export default {
  "customer.account_info.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du dein Konto bearbeiten? Deine Kontaktdaten ändern? Das kannst du hier tun."])},
  "customer.account_info.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo!"])},
  "customer.account_structore.error.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur das Hauptkonto kann Konten anzeigen und erstellen."])},
  "customer.account_structore.error.no_account.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten keine Konten finden"])},
  "customer.account_structore.error.no_account.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konten"])},
  "customer.account_structore.modal.information.dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abteilung/Tochtergesellschaft"])},
  "customer.account_structore.modal.information.team_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamkennung"])},
  "customer.account_structore.modal.information.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinformation"])},
  "customer.account_structore.modal.settings.terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ich möchte die ", _interpolate(_list(0)), " dieses Kontos auf das neue übertragen."])},
  "customer.account_structore.modal.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "customer.account_structore.modal.settings.tos.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdaten"])},
  "customer.account_structore.modal.settings.tos.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisliste"])},
  "customer.account_structore.modal.settings.tos.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsteam"])},
  "customer.account_structore.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkonto erstellen"])},
  "customer.account_structore.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
  "customer.ai_file.error_codes.complex_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexe XLSX-Datei gefunden. Bitte entferne den Content innerhalb der Skript-Tags (z.B. <script>)"])},
  "customer.ai_task.download.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt erstellte Datei: ", _interpolate(_named("timestamp")), " Überprüfe vor dem Herunterladen der Datei, ob der Zeitstempel nach deinen letzten Änderungen in der internen Überprüfung liegt. Wenn er davor liegt, werden deine Änderungen nicht berücksichtigt. Warte mit dem Download, bis der Zeitstempel aktualisiert ist."])},
  "customer.ai_task.download.wait_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deine Datei kann in ca. ", _interpolate(_named("min")), " Minuten heruntergeladen werden"])},
  "customer.api.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du Übersetzungen noch schneller und einfacher bei uns bestellen? Mit unserer API-Lösung sorgen wir für eine reibungslose Integration in deine eigene Plattform. Deine Übersetzungen sind nur einen Klick entfernt!"])},
  "customer.api.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligente Technologie"])},
  "customer.api.api_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Dokumentationslink"])},
  "customer.area_of_work.modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das hilft uns, dein Nutzungserlebnis mit EasyTranslate zu verbessern"])},
  "customer.area_of_work.modal.localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalisierungsteam"])},
  "customer.area_of_work.modal.marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing- &amp; Designteam"])},
  "customer.area_of_work.modal.operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations Team"])},
  "customer.area_of_work.modal.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
  "customer.area_of_work.modal.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktteam"])},
  "customer.area_of_work.modal.tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software- &amp; Technikteam"])},
  "customer.area_of_work.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welchem Team bist du?"])},
  "customer.automation.ai_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwende unsere KI-Übersetzung, um deinen Content zu übersetzen"])},
  "customer.automation.ai_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI-Übersetzung"])},
  "customer.automation.complete_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt beenden"])},
  "customer.automation.complete_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplettes Projekt"])},
  "customer.automation.create_caas_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generiere Produktbeschreibungen aus Schlüsselwörtern"])},
  "customer.automation.create_caas_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texterstellung"])},
  "customer.automation.create_project.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade deine Inhalte zur Übersetzung hoch"])},
  "customer.automation.create_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelldatei hochladen"])},
  "customer.automation.inital.step.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsauslöser"])},
  "customer.automation.inital.step.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Auslöser aus, die deinen Workflow einleiten."])},
  "customer.automation.internal_caas_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe deine generierten Inhalte"])},
  "customer.automation.internal_caas_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Inhaltsüberprüfung"])},
  "customer.automation.internal_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasse deine eigenen internen Mitarbeitenden den übersetzten Inhalt überarbeiten"])},
  "customer.automation.internal_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Überprüfung"])},
  "customer.automation.machine_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwende unsere maschinelle Übersetzung, um deine Inhalte zu übersetzen"])},
  "customer.automation.machine_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinelle Übersetzung (MT)"])},
  "customer.automation.project_action.assets_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn noch kein ", _interpolate(_named("Asset")), " vorhanden ist, gehen Sie zu <i>Sprach-Assets</i> und erstellen Sie eines, indem Sie auf <i>Neu hinzufügen</i> klicken."])},
  "customer.automation.project_action.primary_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primär auswählen"])},
  "customer.automation.project_action.secondary_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundär auswählen"])},
  "customer.automation.qa.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorge für Qualitätssicherung, indem dein eigener interner Mitarbeitender den Auszug einer übersetzten Datei kontrolliert"])},
  "customer.automation.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherung (QA)"])},
  "customer.automation.qa_action.accuracy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
  "customer.automation.qa_action.accuracy_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Bedeutung, Fehler in Zahlen und Verweisen, Auslassungen oder falsche Tags."])},
  "customer.automation.qa_action.file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale Stichprobengröße"])},
  "customer.automation.qa_action.frequency_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit"])},
  "customer.automation.qa_action.frequency_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Häufigkeit bestimmt, wie oft eine Qualitätssicherung ausgeführt wird. Z.B.: Wenn deine Häufigkeit auf 2 von 10 eingestellt ist, wird ungefähr jede 5. Aufgabe mit einer Qualitätssicherung überprüft. Bitte beachte, dass die QA-Aufgabe immer auf Grundlage der gewählten Zielsprache und des für die QA gewählten Themas gezählt wird"])},
  "customer.automation.qa_action.language_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "customer.automation.qa_action.language_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichensetzungs-, Rechtschreibungs-, Großschreibungs- oder Grammatikfehler."])},
  "customer.automation.qa_action.locale_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regionale Standards"])},
  "customer.automation.qa_action.locale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler in Länderstandards wie Datumsformate und Währungen sowie kulturelle Aspekte."])},
  "customer.automation.qa_action.performing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl der*des Übersetzer*in für die Qualitätssicherung"])},
  "customer.automation.qa_action.performing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass die Aufgabe dem*der ersten von dir ausgewählten Übersetzer*in zugewiesen wird. Wenn diese*r Übersetzer*in ablehnt, wird die Aufgabe den folgenden von dir ausgewählten Übersetzer*innen in chronologischer Reihenfolge zugewiesen."])},
  "customer.automation.qa_action.style_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])},
  "customer.automation.qa_action.style_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilistische Fehler, Missachtung bestimmter Richtlinien oder Fehler im Sprachregister/in der Sprachvariante."])},
  "customer.automation.qa_action.subject_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung der korrekten Übersetzer*innen für die Qualitätssicherung"])},
  "customer.automation.qa_action.subject_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte achte darauf, dass die Übersetzer*innen, die du für die Qualitätssicherung auswählst, die gleichen sind, denen du die Aufgabe zugewiesen hast. Wenn diese*r Dienstleister*in ablehnt, wird deine QA-Aufgabe automatisch storniert."])},
  "customer.automation.qa_action.terminology_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminologie"])},
  "customer.automation.qa_action.terminology_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine einheitliche Verwendung von Terminologie oder Missachtung branchenüblicher/bereitgestellter Glossare."])},
  "customer.automation.review_action.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Kombination gibt es keine Korrekturleser*innen in deinem Team. Wir werden automatisch einen Korrekturleser aus unserem Team zuweisen"])},
  "customer.automation.settings.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du die Einstellungen deines Workflows bearbeiten."])},
  "customer.automation.settings.toast.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Einstellungen für diese Automatisierung wurden erfolgreich aktualisiert"])},
  "customer.automation.settings.toast.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierte Einstellungen"])},
  "customer.automation.sidebar.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Aktion wählen"])},
  "customer.automation.start_string_library_automation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle eine Übersetzungsautomatisierung für eine vorhandene String-Bibliothek"])},
  "customer.automation.start_string_library_automation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String-Bibliothek"])},
  "customer.automation.status_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow-Status"])},
  "customer.automation.step_complete.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast deinen Workflow-Schritt erfolgreich eingerichtet"])},
  "customer.automation.step_complete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast deinen Workflow-Schritt erfolgreich eingerichtet"])},
  "customer.automation.step_complete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt abgeschlossen"])},
  "customer.automation.step_incomplete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle deine Einstellungen für die Automatisierung des Workflow-Schritts"])},
  "customer.automation.step_incomplete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt unvollständig"])},
  "customer.automation.string_library.action.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Inhalte zu erstellen, wählen Sie die Bibliothek aus dem Produkt aus."])},
  "customer.automation.string_library.action.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliotheken"])},
  "customer.automation.string_library.step.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung der Übersetzungs- oder Produktauslöser"])},
  "customer.automation.supplier_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine*n Freelancer*in zur Übersetzung aus"])},
  "customer.automation.supplier_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur"])},
  "customer.automation.supplier_translation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine*n Freelancer*in zur Übersetzung aus"])},
  "customer.automation.supplier_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung"])},
  "customer.automation.unsupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow für Abonnement nicht unterstützt"])},
  "customer.automations.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keine Workflows erstellt."])},
  "customer.automations.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Workflows"])},
  "customer.automations.steps.complete_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt beenden"])},
  "customer.automations.steps.create_caas_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation"])},
  "customer.automations.steps.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelldatei hochladen"])},
  "customer.automations.steps.internal_caas_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Inhaltsüberprüfung"])},
  "customer.automations.steps.internal_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Überprüfung"])},
  "customer.automations.steps.machine_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinelle Übersetzung (MT)"])},
  "customer.automations.steps.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.automations.steps.start_string_library_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String Library"])},
  "customer.automations.steps.supplier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur"])},
  "customer.automations.steps.supplier_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung"])},
  "customer.automations.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow erstellen"])},
  "customer.balance.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Guthaben verfügbar."])},
  "customer.balance.type.adjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassung"])},
  "customer.balance.type.applied_to_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird auf die Rechnung angewendet"])},
  "customer.balance.type.credit_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift"])},
  "customer.banner.book_meeting.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benötigst du Hilfe oder hast du Fragen? Vereinbare ein Meeting mit dem Customer Success ", _interpolate(_named("link"))])},
  "customer.billing.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiere deine Rechnungsinformationen und Zahlungsmethode."])},
  "customer.billing.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen leicht gemacht"])},
  "customer.billing.information.billing_portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsportal anzeigen"])},
  "customer.billing.information.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsinformationen"])},
  "customer.billing.methods.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode hinzufügen"])},
  "customer.billing.methods.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge deine Zahlungsmethoden hinzu und verwalte sie mit unserem sicheren Zahlungssystem."])},
  "customer.billing.methods.payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethoden auswählen"])},
  "customer.billing.methods.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethoden"])},
  "customer.billing.missing_billing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge fehlende Rechnungsinformationen hinzu, um mit dem MT-Training fortzufahren"])},
  "customer.billing.missing_billing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Rechnungsinformationen"])},
  "customer.build_team.copywriters.selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast ", _interpolate(_named("number")), "  Freelancer*innen ausgewählt."])},
  "customer.build_team.copywriters.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die besten Freelancer*innen für dich ausgewählt, aber du kannst unsere Auswahl jederzeit anpassen.\nIndem du auf die Freelancer*innen klickst, kannst du mehr über sie erfahren."])},
  "customer.build_team.copywriters.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Du auf die Texter*innen klickst, siehst du ihr detailliertes Profil."])},
  "customer.build_team.copywriters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle deine bevorzugten Freelancer*innen aus"])},
  "customer.build_team.copywriters_confirm.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Texter*innen bestätigen"])},
  "customer.build_team.copywriting.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge deinem Team Texter*innen hinzu"])},
  "customer.build_team.page_five.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Freelancer*innen für die ausgewählte Sprachkombination verfügbar. Bitte kontaktiere uns über den Chat, damit wir die weiterhelfen können."])},
  "customer.build_team.page_five.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Übersetzer*innen werden deinem Team hinzugefügt."])},
  "customer.build_team.page_five.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte(n) Übersetzer bestätigen"])},
  "customer.build_team.page_four.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge einen optionalen Mitarbeitenden für die Qualitätssicherung deiner Übersetzung hinzu"])},
  "customer.build_team.page_four.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte*r Übersetzer*in bieten keine Qualitätssicherungsdienstleistungen an"])},
  "customer.build_team.page_four.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherung (QA)"])},
  "customer.build_team.page_one.file_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass deine Datei nicht größer als 300 KB sein darf"])},
  "customer.build_team.page_one.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte lade eine Datei hoch, damit wir deinen Text mit unserer KI-basierten Technologie verbinden können."])},
  "customer.build_team.page_one.text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass dadurch kein Projekt erstellt wird, sondern dein Übersetzungsteam aufgebaut wird."])},
  "customer.build_team.page_one.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf diese Weise finden wir die geeignetsten Übersetzer für dein Team."])},
  "customer.build_team.page_three.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge eine*n optionale*n Korrekturleser*in für deine Übersetzung hinzu"])},
  "customer.build_team.page_three.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte*r Übersetzer*in bieten keine Korrekturdienstleistungen an"])},
  "customer.build_team.page_three.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungskorrektur"])},
  "customer.build_team.page_two.selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast ", _interpolate(_named("number")), "  Freelancer*innen ausgewählt."])},
  "customer.build_team.page_two.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die besten Freelancer*innen für dich ausgewählt, aber du kannst unsere Auswahl jederzeit anpassen.\nIndem du auf die Freelancer*innen klickst, kannst du mehr über sie erfahren."])},
  "customer.build_team.page_two.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Du auf Übersetzer*innen klickst, kannst du ihr detailliertes Profil sehen."])},
  "customer.build_team.page_two.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle deine bevorzugten Freelancer*innen aus"])},
  "customer.button.calculate.cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektkosten berechnen"])},
  "customer.caas_key_modal.library_keywords.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Schlüsselwörter für die Produktbibliothek jeweils durch ein Komma getrennt ein. Verwende mindestens 5 und maximal 25 Schlüsselwörter. Um die Qualität generierter Texte zu erhöhen, empfehlen wir, die Schlüsselwörter in logischer Reihenfolge zu verwenden."])},
  "customer.caas_key_modal.library_keywords.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselwörter für die Produktbibliothek"])},
  "customer.caas_key_modal.sku_tooltip.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deinen eindeutigen Produktnamen (SKU) ein, um einen eindeutigen Schlüssel für die Produktbibliothek zu erstellen"])},
  "customer.caas_key_modal.sku_tooltip.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktschlüsselname/SKU"])},
  "customer.content_generation.document.create.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument wurde erfolgreich erstellt"])},
  "customer.content_generation.document.delete.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument wurde erfolgreich gelöscht"])},
  "customer.content_generation.document.name.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name des Dokuments wurde erfolgreich aktualisiert"])},
  "customer.content_generation.template.delete.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vorlage wurde erfolgreich gelöscht"])},
  "customer.content_generation.template.save.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vorlage wurde erfolgreich gespeichert"])},
  "customer.content_pillars.button.create_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Säule erstellen"])},
  "customer.content_pillars.button.save_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säule speichern"])},
  "customer.content_pillars.buttons.create_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle eine"])},
  "customer.content_pillars.clusters_overview.add_pages_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten hinzufügen"])},
  "customer.content_pillars.clusters_overview.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
  "customer.content_pillars.clusters_overview.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "customer.content_pillars.clusters_overview.in_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "customer.content_pillars.clusters_overview.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "customer.content_pillars.create_content.add_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content-Einstellungen hinzufügen"])},
  "customer.content_pillars.create_content.button.show_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zur Übersicht"])},
  "customer.content_pillars.create_content.chars_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sowohl „Zeichen“ als auch „Wörter“ sind leer. Eins davon muss größer als 0 sein"])},
  "customer.content_pillars.create_content.drag_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehe oder verwende die Pfeile zur Auswahl"])},
  "customer.content_pillars.create_content.enter_wordcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Wörter/Zeichen?"])},
  "customer.content_pillars.create_content.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endergebnis"])},
  "customer.content_pillars.create_content.results_pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eins, mehrere oder alle aus und los geht’s!"])},
  "customer.content_pillars.create_content.select_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content-Typ"])},
  "customer.content_pillars.create_content.select_suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Anzahl der Vorschläge aus"])},
  "customer.content_pillars.create_content.select_wordcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Parameter für deinen Content ein"])},
  "customer.content_pillars.create_content.suggestions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Vorschläge"])},
  "customer.content_pillars.create_content.suggestions_happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du zufrieden?"])},
  "customer.content_pillars.create_content.suggestions_pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Vorschläge, die dir gefallen"])},
  "customer.content_pillars.create_content.suggestions_regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
  "customer.content_pillars.label.add_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content hinzufügen"])},
  "customer.content_pillars.label.describe_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibe dein Unternehmen oder gib deine URL ein"])},
  "customer.content_pillars.label.describe_pillar_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daraus generieren wir Schlüsselwörter"])},
  "customer.content_pillars.label.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin leer"])},
  "customer.content_pillars.label.name_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benenne deine Säule"])},
  "customer.content_pillars.no_copyeditors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast keine Texteditor*innen in deinem Übersetzungsteam. Bitte klicke ", _interpolate(_named("here")), ", um freiberufliche Texteditor*innen zu deinem Team hinzuzufügen."])},
  "customer.copyediting.animation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll dein Content zur Bearbeitung freigegeben werden?"])},
  "customer.create_flow.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow erstellen"])},
  "customer.create_flow.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle einen Namen und eine Beschreibung für deinen Workflow aus"])},
  "customer.create_flow.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow-Name"])},
  "customer.create_flow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier Workflow erstellen"])},
  "customer.create_project.modal.missing_translators": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast kein Übersetzungsteam. Bitte klicke", _interpolate(_named("here")), ", um deinem Team Übersetzer*innen hinzuzufügen, oder wähle Machine Translation"])},
  "customer.document.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dokumentname existiert bereits"])},
  "customer.feature.animation.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feature ist in deinem Paket enthalten"])},
  "customer.feature.automation_workflow.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten, um deinen ersten Automatisierungs-Workflow einzurichten!"])},
  "customer.feature.automation_workflow.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzichte auf manuelle Bearbeitung wiederkehrender Workflows und spare wertvolle Zeit. Mit diesem Automatisierungsfeature kannst du deine Prozesse optimieren und sicherstellen, dass jede Aufgabe einheitlich und genau ausgeführt wird."])},
  "customer.feature.automation_workflow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeite produktiver und effizienter!"])},
  "customer.feature.cg.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten, um Content zu generieren!"])},
  "customer.feature.cg.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation ist ein KI-basiertes Tool zur Texterstellung, das überzeugende Texte für Blogbeiträge, Produktbeschreibungen, Marketing-E-Mails, Social-Media-Beiträge, Lead Nurturing, Anzeigen und vieles mehr erstellt. Optional lässt sich das Tool für den zusätzlichen menschlichen Feinschliff mit einem Marketplace von Texteditor*innen verbinden."])},
  "customer.feature.cg.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ansprechenden Content in kürzester Zeit"])},
  "customer.feature.chat.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten, um loszulegen!"])},
  "customer.feature.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektive Kommunikation ist bei der Zusammenarbeit von entscheidender Bedeutung, da sie sicherstellt, dass alle Beteiligten ihre Rollen und Verantwortlichkeiten verstehen. Gute Kommunikation steigert nicht nur die Produktivität, sondern verbessert auch die Teamarbeit und fördert ein positives Arbeitsumfeld. Kommuniziere mit deinen Übersetzer*innen über unsere Nachrichtenfunktion."])},
  "customer.feature.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommuniziere effektiv mit deinen Freelancer*innen"])},
  "customer.feature.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppieren Sie Ihren Content rund um die Domain, mit der Sie arbeiten, und lassen Sie uns mehrere unterschiedliche Inhalte zum gleichen Thema generieren! Sie müssen nur die Überschrift(en) und Wortanzahl festlegen – den Rest erledigen Ihre Texteditor*innen."])},
  "customer.feature.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihre Content-Roadmap, die sich auf SEO-optimierte Inhalte konzentriert!"])},
  "customer.feature.cta_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los geht‘s"])},
  "customer.feature.freelancers.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten, um eine*n Freelancer*in hinzuzufügen!"])},
  "customer.feature.freelancers.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge deinem Team Freelancer*innen hinzu, um Aufgaben mit menschlicher Expertise zu erledigen! Wähle aus unseren zahlreichen Freelancer*innen, die Aufgaben übersetzen, bearbeiten oder überprüfen."])},
  "customer.feature.freelancers.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du brauchst den menschlichen Feinschliff?"])},
  "customer.feature.mt.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten, um mehr zu erfahren!"])},
  "customer.feature.mt.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Für dieses Feature benötigst du ein bei uns gespeichertes Translation Memory."])},
  "customer.feature.mt.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der typischen maschinellen Übersetzung wird dein Content in eine einfache Anwendung eingegeben, die in den meisten Fällen keine guten Ergebnisse liefert, wenn dein Content etwas spezifischer als gewöhnlich ist. Unsere Anwendung nutzt dein Translation Memory und trainiert es, sich an Eingaben in früheren Übersetzungen zu orientieren. Das bedeutet, dass das Thema deiner Übersetzung auf deine bei uns gespeicherten Daten zugeschnitten ist."])},
  "customer.feature.mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greife auf deine eigene personalisierte maschinelle Übersetzung zu, um leistungsstarke maschinelle Übersetzungen zu erstellen!"])},
  "customer.feature.string_library.cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten, um eine Bibliothek zu erstellen!"])},
  "customer.feature.string_library.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Tool eignet sich hervorragend für Entwickler*innen und alle, die mit Content arbeiten. Verwalte deines Keys und Values an einem Ort. Die Verwendung einer String-Bibliothek vereinfacht Prozesse erheblich und macht deinen Code und deinen Content präziser und effizienter. Sie hilft auch, die Fehlerwahrscheinlichkeit zu verringern."])},
  "customer.feature.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalte deinen Content an einem Ort!"])},
  "customer.folder.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ordnername existiert bereits"])},
  "customer.folders.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ordner erstellt."])},
  "customer.folders.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ordner"])},
  "customer.freemium.upgrade_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere uns für ein Upgrade"])},
  "customer.freemium_modal.header_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfolgend findest du eine Liste der verschiedenen Preisstufen für diese Funktion"])},
  "customer.freemium_modal.tier_table.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis/Monat"])},
  "customer.freemium_modal.tier_table.tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe"])},
  "customer.freemium_modal.tier_table.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungslimit"])},
  "customer.history.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Verlauf verfügbar"])},
  "customer.history.status.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "customer.history.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
  "customer.history.status.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
  "customer.history.status.uncollectible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht einbringbar"])},
  "customer.history.status.void": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer"])},
  "customer.history.type.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "customer.history.type.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
  "customer.history.type.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "customer.invitation.empy.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen: Diese Einladung existiert nicht oder ist abgelaufen"])},
  "customer.invitation.empy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungsfehler"])},
  "customer.invitation.team.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deine Einladung zu ", _interpolate(_named("team")), " wurde erfolgreich abgelehnt"])},
  "customer.invitation.team.login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hier klicken", _interpolate(_named("link")), " , um zur Anmeldung zu gelangen"])},
  "customer.invitation.team.send_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eine Einladung wurde an ", _interpolate(_named("email")), " gesendet"])},
  "customer.invitation.team.success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geschafft! Du bist jetzt ", _interpolate(_named("team")), " beigetreten"])},
  "customer.invitation.team.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("team")), " hat dich eingeladen, Teil ihres*seines Kontos zu werden."])},
  "customer.label.reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu zuordnen"])},
  "customer.lang_assets.actions_modal.export_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export senden an (optional)"])},
  "customer.lang_assets.actions_modal.export_tootlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Export wird an die angegebene E-Mail-Adresse gesendet. Wenn keine E-Mail eingegeben wird, wird es stattdessen an Ihre E-Mail gesendet."])},
  "customer.lang_assets.actions_modal.import": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, ", _interpolate(_named("assetType")), " in ", _interpolate(_named("asset")), " zu importieren."])},
  "customer.lang_assets.add_start.start.file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benenne deine Datei(en)"])},
  "customer.lang_assets.add_start.start.glossary_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benenne deine Glossare"])},
  "customer.lang_assets.add_start.start.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manueller Upload zweisprachiger Dateien"])},
  "customer.lang_assets.add_start.start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestalte dein Projekt von Anfang an nach deinen Vorstellungen!"])},
  "customer.lang_assets.add_start.upload.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("upload")), " deine Assets"])},
  "customer.lang_assets.content_type.modal.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Eintragstyp in ", _interpolate(_named("type")), " ändern?"])},
  "customer.lang_assets.upload.instructions.languages_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Achte darauf, dass deine Sprachcodes mit unseren übereinstimmen. Klicke", _interpolate(_named("here")), " für unsere Sprachenzuordnung"])},
  "customer.lang_assets.upload.instructions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie sollte deine CSV-Datei aufgebaut sein?"])},
  "customer.lang_assets.upload.instructions_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Sprache sollte in einer eigenen Spalte stehen"])},
  "customer.lang_assets.upload.instructions_tb.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass Glossare nur als CSV-Datei hochgeladen werden können"])},
  "customer.lang_assets.upload.instructions_tm.a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte A sollte das Quellsegment enthalten"])},
  "customer.lang_assets.upload.instructions_tm.b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte B sollte die vorherige Übersetzung enthalten (sofern vorhanden, andernfalls ist sie leer)"])},
  "customer.lang_assets.upload.instructions_tm.c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte C sollte das nächste Segment enthalten (sofern vorhanden, andernfalls leer)"])},
  "customer.lang_assets.upload.instructions_tm.d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte D sollte die erste Zielsprache enthalten"])},
  "customer.lang_assets.upload.instructions_tm.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass TMs nur als CSV-Datei hochgeladen werden können"])},
  "customer.lang_assets.upload.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst mehrere hinzufügen, aber immer nur eine Datei auf einmal"])},
  "customer.lang_assets.upload.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lade dein ", _interpolate(_named("type")), " hoch"])},
  "customer.language_assets.import_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue importieren"])},
  "customer.language_assets.term_bank.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossar"])},
  "customer.language_assets.translation_memory.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "customer.libraries.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek erstellen"])},
  "customer.libraries.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bibliothek erstellt."])},
  "customer.libraries.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bibliothek"])},
  "customer.libraries.modal.create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbibliothek erstellen"])},
  "customer.libraries.modal.create_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsbibliothek erstellen"])},
  "customer.libraries.modal.delete_language.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache löschen"])},
  "customer.libraries.modal.delete_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek löschen"])},
  "customer.libraries.modal.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeite die Informationen deiner Übersetzungsbibliothek"])},
  "customer.libraries.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bibliotheksname existiert bereits"])},
  "customer.libraries.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib alle erforderlichen Informationen ein"])},
  "customer.libraries.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle deine Bibliothek"])},
  "customer.libraries.title.add_new_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Sprache hinzufügen"])},
  "customer.library.caas.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktschlüssel hinzufügen"])},
  "customer.library.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
  "customer.library.shared.btn.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek herunterladen"])},
  "customer.library.shared.btn.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierung starten"])},
  "customer.library.shared.modal.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bist du sicher, dass du ", _interpolate(_named("name")), " löschen möchtest"])},
  "customer.library.shared.modal.delete_key.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel löschen"])},
  "customer.library.shared.modal_download.nest_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strings zusammenführen"])},
  "customer.library.shared.modal_download.option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle deine bevorzugte Option"])},
  "customer.library.shared.modal_download.option_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Sprachen herunterladen"])},
  "customer.library.shared.modal_download.option_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen wählen"])},
  "customer.library.shared.modal_download.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bist dabei, die Bibliothek ", _interpolate(_named("name")), " als ", _interpolate(_named("json")), " Datei herunterzuladen."])},
  "customer.library.shared.modal_download.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek herunterladen"])},
  "customer.library.shared.modal_start.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bist dabei, die Automatisierung für ", _interpolate(_named("name")), " zu starten"])},
  "customer.library.shared.modal_start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablauf der Automatisierung"])},
  "customer.library.translation.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel hinzufügen"])},
  "customer.library.translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung"])},
  "customer.machine_translation.custom_mt.view.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginne mit dem MT-Training"])},
  "customer.machine_translation.custom_mt.view.cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training abbrechen"])},
  "customer.machine_translation.custom_mt.view.cancel_training_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Training des ausgewählten Modells wurde abgebrochen"])},
  "customer.machine_translation.custom_mt.view.estimate_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtliche Kosten"])},
  "customer.machine_translation.custom_mt.view.start_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginne zu üben"])},
  "customer.machine_translation.custom_mt.view.success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Trainingsmodell wurde gestartet"])},
  "customer.marketplace.app.benner.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
  "customer.marketplace.app.blackbird.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal, ob du Content von Grund auf neu erstellen oder deine Website, deinen Webshop, dein CMS oder andere Bereiche übersetzen möchtest – wir haben alle Tools, die du brauchst, um erfolgreich zu sein. Nutze unsere zahlreichen No-Code-Plugins und automatisiere deine gesamten Übersetzungs- und Inhaltsgenerierungsprozesse."])},
  "customer.marketplace.app.contentful.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit unserem Contentful-Plugin kannst du verschiedenen Content mit unserer String Library synchronisieren. Du kannst die dortigen Features nutzen, um deinen Content entweder automatisiert oder selbst zu übersetzen und ihn anschließend zurück zu Contentful zu übertragen. Dies geschieht ganz einfach per Mausklick."])},
  "customer.marketplace.app.episerver.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinde EasyTranslate mit deiner Episerver-Website, um Übersetzungen sofort in Auftrag zu geben und zu erhalten. Bitte beachte, dass durch die Installation dieses Plugins eine ZIP-Datei heruntergeladen wird, die du installieren musst."])},
  "customer.marketplace.app.figma.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit unserem Figma-Plugin kannst du dich mit einer Library verbinden, deine Strings synchronisieren, sie übersetzen und zurücksenden lassen, ohne Figma verlassen zu müssen"])},
  "customer.marketplace.app.github.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die GitHub-Aktion ist unsere Art, Entwickler bei der Automatisierung ihrer Übersetzungsprozesse zu unterstützen. Mit GitHub kannst du deine Lokalisierungsdateien aus deiner Codebasis übersetzen. Es wird nur deine Ausgangssprache verwendet, die erforderlichen übersetzten Dateien werden automatisch in deiner Zielsprache erstellt."])},
  "customer.marketplace.app.hygraph.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit unserem Hygraph-Plugin kannst du deinen Website-Content mit nur wenigen Klicks an unsere Plattform senden. Der Content wandert in unsere String Library, wird übersetzt und dann an Hygraph zurückgesendet."])},
  "customer.marketplace.app.magento_1.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinde EasyTranslate mit deiner E-Commerce-Website von Magento, um Übersetzungen sofort in Auftrag zu geben und zu erhalten. Bitte beachte, dass durch die Installation dieses Plugins eine ZIP-Datei heruntergeladen wird, die du installieren musst."])},
  "customer.marketplace.app.magento_2.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinde EasyTranslate mit deiner E-Commerce-Website von Magento, um Übersetzungen sofort in Auftrag zu geben und zu erhalten."])},
  "customer.marketplace.app.shopify.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinde EasyTranslate mit deinem Shopify-Webshop, um Übersetzungen direkt in deinem Backend zu erhalten."])},
  "customer.marketplace.app.shopware.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinde EasyTranslate mit deiner E-Commerce-Website von Shopware, um Übersetzungen sofort in Auftrag zu geben und zu erhalten. Bitte beachte, dass durch die Installation dieses Plugins eine ZIP-Datei heruntergeladen wird, die du installieren musst."])},
  "customer.marketplace.app.wordpress.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten dir eine leichtere Lösung: Verbinde EasyTranslate mit deiner WordPress-Website, um Übersetzungen sofort in Auftrag zu geben und zu erhalten."])},
  "customer.marketplace.app.zapier.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Zapier stellst du eine Verbindung zu EasyTranslate her, um Übersetzungen automatisch in die Apps zu integrieren, die du täglich verwendest."])},
  "customer.marketplace.item.kb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("app_text")), " Um mehr zu erfahren, klicke ", _interpolate(_named("link"))])},
  "customer.marketplace.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Besuche unser", _interpolate(_named("link")), " , um mehr darüber zu erfahren, wie du unser Plugin installierst und in dein System integrierst."])},
  "customer.marketplace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisiere deine Arbeitsprozesse und steigere deine Leistung mit unseren verschiedenen Plug-in-Integrationen, die sich nahtlos in dein System einfügen."])},
  "customer.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du jederzeit effizient zu bestimmten Aufgaben mit deinen Übersetzer*innen kommunizieren"])},
  "customer.modal.freemium_accounts.ai.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecke die Zukunft der Übersetzung mit HumanAI!"])},
  "customer.multilingual_project.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die mehrsprachige Datei ist erst verfügbar, wenn alle Sprachen bearbeitet sind. Wenn du einzelne bereits abgeschlossene Sprachen benötigst, wende dich bitte an den Support."])},
  "customer.nav.account.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "customer.nav.automation_flows.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierungs-Workflow"])},
  "customer.nav.automation_workflows.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierungs-Workflows"])},
  "customer.nav.copywriting.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting"])},
  "customer.nav.dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "customer.nav.getStarted.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loslegen"])},
  "customer.nav.libraries.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliotheken"])},
  "customer.nav.marketplace.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App-Marktplatz"])},
  "customer.nav.overview.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich"])},
  "customer.nav.overview.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich"])},
  "customer.nav.payment.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
  "customer.nav.pricing.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
  "customer.nav.progress.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt"])},
  "customer.nav.progress.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "customer.nav.string_libraries.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String Libraries"])},
  "customer.nav.translation_assets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine personalisierte maschinelle Übersetzung"])},
  "customer.nav.translators.breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team aufbauen"])},
  "customer.nav.translators.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*innen"])},
  "customer.news.02-10-2023.task_layout.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-th-large"])},
  "customer.news.02-10-2023.task_layout.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben uns euer Feedback zu Herzen genommen und gerade ein neues Layout für deine Projektansicht veröffentlicht. Du kannst jetzt ganz einfach den Fortschritt deines Projekts überprüfen, deine Freelancer*innen bewerten, den Editor nutzen und dein Projekt herunterladen."])},
  "customer.news.02-10-2023.task_layout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Aufgabenlayout!"])},
  "customer.news.04-09-2023.automation_workflow_fix.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.04-09-2023.automation_workflow_fix.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst jetzt unser Feature für die automatische String-Bibliothek verwenden. Lege deinen Schwellenwert fest und sobald dieser überschritten ist, starten wir automatisch den Übersetzungsprozess."])},
  "customer.news.04-09-2023.automation_workflow_fix.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserung der Automatisierungs-Workflows!"])},
  "customer.news.04-09-2023.invite_colleagues.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.04-09-2023.invite_colleagues.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die Funktion „Benutzer*innen einladen“ zur Seitenleiste hinzugefügt, sodass du ganz einfach Kolleg*innen zur Plattform hinzufügen kannst."])},
  "customer.news.04-09-2023.invite_colleagues.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade deine Kolleg*innen zur Plattform ein"])},
  "customer.news.04-09-2023.kb_access.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-question"])},
  "customer.news.04-09-2023.kb_access.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt es offene Fragen? Du kannst jetzt über die Seitenleiste auf die Knowledge Base zugreifen."])},
  "customer.news.04-09-2023.kb_access.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneller Zugriff auf unsere Knowledge Base!"])},
  "customer.news.04-09-2023.mt_preselect.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.04-09-2023.mt_preselect.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst jetzt bei der Erstellung eines Projekts eine Vorauswahl an Sprachen für die MT treffen. Achtung! Dies ist ein Enterprise-Feature."])},
  "customer.news.04-09-2023.mt_preselect.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Enterprise-Feature!"])},
  "customer.news.04-09-2023.remove_supplier.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user"])},
  "customer.news.04-09-2023.remove_supplier.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Teamadministrator*in kannst du Supplier jetzt selbst aus deinem Team zu entfernen."])},
  "customer.news.04-09-2023.remove_supplier.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier aus deinem Team entfernen"])},
  "customer.news.06-07-2022.data.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst den Kundendienst nicht mehr manuell um Zugriff auf deine persönlichen Daten bitten. Exportiere einfach deine Daten, wenn du wissen möchtest, welche Informationen gespeichert wurden. Auch die Löschung deiner Daten kannst du jetzt mit wenigen Klicks direkt auf unserer Plattform beantragen."])},
  "customer.news.06-07-2022.data.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greife auf deine persönlichen Daten zu"])},
  "customer.news.06-07-2022.sso.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user"])},
  "customer.news.06-07-2022.sso.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich unkompliziert und sicher auf unserer Plattform an! Single Sign-On (SSO) ist eine Identifikationsmethode, mit der du dich bei mehreren Anwendungen mit einem einzigen Datensatz von Anmeldeinformationen anmelden kannst. Mit dem Anmelde-Feature für Soziale Netzwerke kannst du dich bei verschiedenen Anwendungen authentifizieren, indem du vorhandene Informationen aus einem Sozialen Netzwerkdienst verwendest. Beachte, dass dies derzeit nur mit Google Mail funktioniert."])},
  "customer.news.06-07-2022.sso.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO-Anmeldung"])},
  "customer.news.06-07-2022.two_factor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten jetzt 2FA für einen sichereren Zugriff auf dein Konto! Auf diese Weise wird deine Identität zweimal überprüft, wenn du dich auf der Plattform anmeldest. Zunächst mit Usernamen und Passwort. Danach, indem du die Authentifizierungsanforderung mit einer Authentifizierungs-App auf deinem Telefon genehmigst. 2FA schützt vor Phishing, Social Engineering und Brute-Force-Angriffen auf Passwörter. Es schützt auch deinen Login, da viele Passwörter leider zu schwach und leicht zu erraten sind."])},
  "customer.news.06-07-2022.two_factor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung"])},
  "customer.news.07-07-2022.new_privacy_terms.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "customer.news.07-07-2022.new_privacy_terms.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://easytranslate.com/en/terms-and-conditions-localisation-software-platform/"])},
  "customer.news.07-07-2022.new_privacy_terms.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir haben Änderungen an unseren Nutzungsbedingungen vorgenommen. Bitte lies sie dir hier sorgfältig durch: ", _interpolate(_named("link"))])},
  "customer.news.07-07-2022.new_privacy_terms.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "customer.news.08-12-2020.translate_yourself.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.08-12-2020.translate_yourself.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir führen jetzt einen zusätzlichen Workflow namens Selbst übersetzen für deinen Übersetzungsprozess ein. Du kannst jetzt Selbst übersetzen in project creation overview wählen, wodurch dein Projekt maschinell übersetzt und ohne manuelle Schritte direkt in deinen Editor übertragen werden kann. Mit dieser Option kannst du mühelos interne Übersetzer*innen für kleinere Sofortübersetzungen mit denselben effektiven Tools beauftragen."])},
  "customer.news.08-12-2020.translate_yourself.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbst übersetzen"])},
  "customer.news.12-06-2023.terms.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "customer.news.12-06-2023.terms.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben eine kleine Änderung an unseren Allgemeinen Geschäftsbedingungen vorgenommen. Du findest sie im Abschnitt „Laufzeit und Kündigung“."])},
  "customer.news.12-06-2023.terms.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kleines Update unserer AGB!"])},
  "customer.news.13-07-2022.github_action.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-code"])},
  "customer.news.13-07-2022.github_action.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die GitHub-Aktion ist unsere Art, Entwickler bei der Automatisierung ihrer Übersetzungsprozesse zu unterstützen. Mit GitHub können Sie Ihre Lokalisierungsdateien aus Ihrer Codebasis übersetzen. Es wird nur Ihre Ausgangssprache verwendet, die erforderlichen übersetzten Dateien werden automatisch in Ihrer Zielsprache erstellt."])},
  "customer.news.13-07-2022.github_action.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github-Aktion"])},
  "customer.news.13-08-2020.payment_history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-invoice"])},
  "customer.news.13-08-2020.payment_history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist jetzt möglich, einen vollständigen Überblick über alle Projektrechnungen zu erhalten. Um dir ein vollständiges Bild zu machen, gehe bitte zu Einstellungen, wähle die Registerkarte Payment und dann die Unterregisterkarte Verlauf. Du siehst all deine Kontorechnungen in einer einfachen Übersicht. Du kannst Rechnungen auch in einer druckfertigen Version herunterladen."])},
  "customer.news.13-08-2020.payment_history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsverlauf"])},
  "customer.news.13-08-2020.rating.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-star"])},
  "customer.news.13-08-2020.rating.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns, unsere neu gestaltete Funktion zu präsentieren – ratings. Durch Ratings ist es möglich, dem*der Übersetzer*in zu jedem completed Projekt Feedback zu geben. Die individuelle Bewertung ist für Übersetzer*innen nicht verfügbar, aber sie können den Durchschnitt ihrer Bewertungen sehen. Wir verwenden deine Bewertungen, um deine Übersetzer*innen nach einem Fachgebiet zu kategorisieren und zukünftige Schulungen zu ermitteln, die ihre Fähigkeiten verbessern könnten."])},
  "customer.news.13-08-2020.rating.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
  "customer.news.13-08-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.13-08-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endlich können wir unsere erste Version von EasyTranslate Review Editor vorstellen, wodurch du die von deinem Übersetzungsteam erstellten Übersetzungen überprüfen kannst. Die Überarbeitungen werden in deinen Translation Memory aktualisiert, um zukünftige Übersetzungen sofort zu verbessern. Bitte gib deinen Manager*innen Feedback zu dieser wichtigen Funktion."])},
  "customer.news.13-08-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur-Editor"])},
  "customer.news.13-10-2023.content_pillars.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.13-10-2023.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Content-Roadmap-Tool, das sich auf SEO-optimierte Inhalte konzentriert – Content-Säulen! Wir haben ein Tool entwickelt, mit dem Sie Ihre Inhalte auf die Domain, mit der Sie arbeiten, zuschneiden können – die Erstellung von umfangreichem, zielgerichtetem Content wird so zum Kinderspiel. Sie müssen lediglich Ihre Parameter festlegen, den Rest erledigt Ihr Texteditor."])},
  "customer.news.13-10-2023.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ab sofort steht Ihnen ein neues Feature zur Verfügung – Content-Säulen!"])},
  "customer.news.14-02-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-cog"])},
  "customer.news.14-02-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben eine Symbolleiste hinzugefügt, damit du besseren Content erstellen kannst. Hinweis: Du benötigst einen kostenpflichtigen Plan, um sie nutzen zu können."])},
  "customer.news.14-02-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Tools zur Inhaltsgenerierung!"])},
  "customer.news.14-02-2023.upgrade.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-chevron-up"])},
  "customer.news.14-02-2023.upgrade.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Freemium-User*in kannst du jetzt direkt von der Nutzungsübersicht aus upgraden."])},
  "customer.news.14-02-2023.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade innerhalb der Plattform!"])},
  "customer.news.16-09-2020.filters.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-filter"])},
  "customer.news.16-09-2020.filters.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank, dass du unsere App so oft nutzt. Mit der zunehmenden Nutzung haben wir erkannt, wie wichtig filters sind. Du kannst projects jetzt unter anderem nach creation date und created by filtern. Wir werden die Filter- und Suchfunktion laufend verbessern."])},
  "customer.news.16-09-2020.filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "customer.news.16-09-2020.messages.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "customer.news.16-09-2020.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die Erfahrung gemacht, dass es wichtig ist, bei bestimmten Projekten direkt mit deinen Übersetzer*innen kommunizieren zu können. Deshalb führen wir Nachrichten ein. Nachrichten ist deine Möglichkeit, direkt mit deinen Übersetzer*innen zu kommunizieren. Um eine Nachricht an deine*n Übersetzer*in zu erstellen, gehe zum Projekt und wähle die Aufgabe mit dem*der Übersetzer*in, dem*der du die Nachricht senden möchtest"])},
  "customer.news.16-09-2020.messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "customer.news.16-09-2020.new_task_view.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-th-large"])},
  "customer.news.16-09-2020.new_task_view.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben mit New task view deine Benutzererfahrung optimiert. Mit der jüngsten Veröffentlichung von review editor und ratings wurde uns klar, dass es Zeit für eine neue Benutzererfahrung ist. Du kannst nun all deine Aufgaben unter einem bestimmten Projekt als Karten sehen, so wie du es aus deinem Arbeitsbereich kennst."])},
  "customer.news.16-09-2020.new_task_view.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aufgabenansicht"])},
  "customer.news.21-06-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-cog"])},
  "customer.news.21-06-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben nun unsere bisherige Content Generation durch unsere neue Version ersetzt und damit die Betaphase beendet. Schaue sie dir in der linken Seitenleiste an."])},
  "customer.news.21-06-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Version der Content Generation!"])},
  "customer.news.21-06-2023.upgrade.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-chevron-up"])},
  "customer.news.21-06-2023.upgrade.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben mehrere Funktionen und Buttons im Editor und in der String-Bibliothek übersichtlicher und anwendungsfreundlicher gestaltet."])},
  "customer.news.21-06-2023.upgrade.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrades innerhalb der Plattform!"])},
  "customer.news.21-07-2023.text_area.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.21-07-2023.text_area.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben jetzt einen Freitextbereich hinzugefügt, in dem du beim Erstellen eines Projekts einen Kommentar oder eine Anweisung hinzuzufügen kannst!"])},
  "customer.news.21-07-2023.text_area.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Textfeld für Projekte"])},
  "customer.news.23-01-2023.caas.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "customer.news.23-01-2023.caas.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben gerade unser neuestes Tool, die Content Generation veröffentlicht, wo du mit nur wenigen Worten qualitativ hochwertige Inhalte erstellen kannst! Wir unterstützen derzeit E-Mails, Blogs, Anzeigen und Produktbeschreibungen und werden in Zukunft noch viele weitere Bereiche hinzufügen!"])},
  "customer.news.23-01-2023.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieh dir unser neuestes Content Generation Tool an!"])},
  "customer.news.23-01-2023.menu.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-bars"])},
  "customer.news.23-01-2023.menu.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben deine Einstellungen und den Nachrichtenbereich im Profilmenü unten links in der Anwendung platziert. Wir haben auch die Nachrichtenfunktion in die linke Seitenleiste verschoben."])},
  "customer.news.23-01-2023.menu.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen im Menü!"])},
  "customer.news.23-09-2020.capacity_calculated_deadline.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-stopwatch"])},
  "customer.news.23-09-2020.capacity_calculated_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir berechnen deinen Abgabetermin jetzt basierend auf der Kapazität deiner bevorzugten Übersetzer*innen. Das bedeutet, dass wir andere laufende Projekte Ihres Übersetzers berücksichtigen, um Ihnen eine genaueren Abgabetermin zu geben."])},
  "customer.news.23-09-2020.capacity_calculated_deadline.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kapazität berechneter Abgabetermin"])},
  "customer.news.23-09-2020.new_price_list_review.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-invoice-dollar"])},
  "customer.news.23-09-2020.new_price_list_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit du einen klaren Überblick über die mit Translation and Review verbundenen Kosten hast, haben wir eine Preisliste für Review hinzugefügt. Du kannst dich jederzeit an deine*n Kundenbetreuer*in wenden, um weitere Informationen zu Preisen und Optionen zu erhalten."])},
  "customer.news.23-09-2020.new_price_list_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Preisliste für Korrektur"])},
  "customer.news.23-09-2020.translation_and_review.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.23-09-2020.translation_and_review.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir führen einen zusätzlichen Workflow ein, um die Übersetzungsqualität zu optimieren: Translation and Review. Du kannst Translation and Review jetzt in dem Project creation Überblick auswählen. Dein Projekt wird jetzt sowohl von deinem*deiner Lieblingsübersetzer*in übersetzt als auch von einem deiner anderen Lieblingsübersetzer*innen überprüft. NB. Du benötigst ein Team von mindestens 2 Übersetzer*innen innerhalb der Sprachkombination, um diese Funktion zu aktivieren. Bitte wende dich an deine*n Kundenbetreuer*in, um dein Team zu erweitern."])},
  "customer.news.23-09-2020.translation_and_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung und Korrektur"])},
  "customer.news.24-11-2021.automation_workflow.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.24-11-2021.automation_workflow.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir freuen uns, dir eine unserer neuesten Funktionen vorzustellen: ", _interpolate(_named("title")), ". Du kannst jetzt deine eigenen Workflows entwerfen, um sie an bestimmte Teams, Projekte und Anforderungen anzupassen. Vermeide mit unseren automatisierten Workflows sich wiederholende Übersetzungsprozesse und sorge dafür, dass alle Arbeitsschritte so genau und effizient wie möglich sind. Sobald dein Workflow aktiv ist, wähle ihn einfach während der Erstellung deines neuen Projekts aus."])},
  "customer.news.24-11-2021.automation_workflow.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierter Workflow"])},
  "customer.news.24-11-2021.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-comments"])},
  "customer.news.24-11-2021.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dir einen besseren visibility zu geben, was du schreibst, haben wir die Gestaltung der Chat-Eingabe im Editor reviewed and implemented. Du musst nicht mehr durch das Geschriebene navigieren, um es zu lesen oder zu ändern. Das Eingabefeld zeigt deine Inhalte jetzt in Zeilen an, um dir einen vollständigen Überblick zu geben. NB. Die Chat-Funktion ist nur verfügbar, wenn ein*e oder mehrere Übersetzer*innen deines Teams an dem Projekt beteiligt sind."])},
  "customer.news.24-11-2021.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "customer.news.24-11-2021.history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-history"])},
  "customer.news.24-11-2021.history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Korrektur-Editor von EasyTranslate hat eine new functionality dazubekommen, die dir gefallen wird: the history. Dank des Verlaufs hast du jederzeit den Überblick über alle an einem Segment vorgenommenen changes und kannst einfach den Korrekturprozess analysieren oder restore previous versions – du hast die Wahl."])},
  "customer.news.24-11-2021.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
  "customer.news.24-11-2021.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.24-11-2021.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt nun eine overview der number of words and characters eines Segments. Klicke einfach auf ein Segment und sieh unten links in der Tabelle nach. Darüber hinaus kannst du jetzt die correct ID number jedes Segments sehen, auch wenn du die Seiten wechselst."])},
  "customer.news.24-11-2021.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur-Editor"])},
  "customer.news.24-11-2021.string_library.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.24-11-2021.string_library.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir arbeiten ständig daran, deine Erfahrung mit unserer Plattform zu verbessern. Deshalb haben wir die neue Funktion ", _interpolate(_named("title")), " eingeführt, die es dir ermöglicht, integrate data für deine Seiten und Beiträge mit weniger Aufwand und mehr Flexibilität zu gestalten. Erstelle individual libraries für deine speziellen Betriebssysteme, füge Daten intern oder mit Hilfe von Übersetzer*innen zu deiner Bibliothek hinzu und profitiere von einer schnelleren Bereitstellung deiner Inhalte. Mit title kannst du deine Daten jederzeit reuse, ohne an Dateien gebunden zu sein."])},
  "customer.news.24-11-2021.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String-Bibliothek"])},
  "customer.news.25-01-2022.string_library.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-database"])},
  "customer.news.25-01-2022.string_library.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nutzt du schon", _interpolate(_named("title")), "? Jetzt kannst du deine Strings auch direkt in der Bibliothek übersetzen. Sobald du einen Workflow dafür eingerichtet hast, wähle deine Sprachen aus und schon kannst du Strings zur Übersetzung schicken!"])},
  "customer.news.25-01-2022.string_library.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String-Bibliothek"])},
  "customer.news.25-01-2022.usage.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-dollar-sign"])},
  "customer.news.25-01-2022.usage.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://easytranslate.com/en/pricing/"])},
  "customer.news.25-01-2022.usage.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kennst du schon unseren neuen Preisplan, ", _interpolate(_named("title")), " auf Umfang und Preisstaffelung? Öffne ", _interpolate(_named("link")), ", um mehr zu erfahren, oder kontaktiere uns über den Chat!"])},
  "customer.news.25-01-2022.usage.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])},
  "customer.news.29-04-2021.search_within_the_editor_feature.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.29-04-2021.search_within_the_editor_feature.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Translation Memory und deine Term Bank sind jetzt im Editor-Tool zugänglich und können durchsucht werden, sodass du im Handumdrehen für Einheitlichkeit sorgen kannst, ohne die Seite verlassen zu müssen. Verwende das Suchfeld in der Korrekturphase, um Wörter nachzuschlagen und zu sehen, ob sie mit deinem Translation Memory und/oder deiner Termbank übereinstimmen. Alle gefundenen Übereinstimmungen werden im Quellenabschnitt Ihres Inhalts hervorgehoben. Musst du auf deine Zeichenanzahl achten? Diese sind jetzt auch in den Quellen- und Zielfeldern sichtbar."])},
  "customer.news.29-04-2021.search_within_the_editor_feature.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche innerhalb der Editor-Funktion"])},
  "customer.news.29-04-2022.automated_content.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstatt einen 300-Wörter-Produkttext zu schreiben, gib einfach ein paar Schlüsselwörter ein und die Content Generation erstellt den Text für dich und speichert ihn in Segmenten. Du profitierst auch vom Texterstellungseditor, in dem du die Segmente, die dir gefallen, auswählen, bearbeiten und zum endgültigen Produkttext kombinieren kannst."])},
  "customer.news.29-04-2022.automated_content.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierte Inhalte"])},
  "customer.news.29-04-2022.improved_workflows.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-angles-up"])},
  "customer.news.29-04-2022.improved_workflows.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Lokalisierungsprozesse sind nun noch mehr auf dich abgestimmt als zuvor, da du einer Aufgabe in deinem Workflow jetzt eine*n bestimmte*n Übersetzer*in zuweisen kannst. Du kannst die 1., 2. und 3. Priorität für den*die Übersetzer*in deiner Übersetzungs- und Revisionsaufgaben festlegen. Mit dieser Erweiterung kannst du auch mehrere Sprachen in kürzerer Zeit abdecken."])},
  "customer.news.29-04-2022.improved_workflows.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Workflows"])},
  "customer.news.29-04-2022.qa.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-star"])},
  "customer.news.29-04-2022.qa.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Workflow wird einfacher, da du jetzt eine Qualitätssicherungsaufgabe mit deiner eigentlichen Übersetzungsaufgabe verknüpfen kannst. Du kannst die Übersetzer*innen auswählen, die mit der QA überprüft werden, sowie die Übersetzer*innen, die das Verfahren durchführen. Passe die QA an deine Prioritäten an – Du entscheidest, welche Parameter am wichtigsten sind (Sprache, Stil, Lokalisierung, Genauigkeit oder Terminologie)."])},
  "customer.news.29-04-2022.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "customer.news.29-04-2022.translation_reports.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file"])},
  "customer.news.29-04-2022.translation_reports.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte einen vollständigen Überblick darüber, wie viele Aufgaben bearbeitet wurden, wer sie hochgeladen hat, welche Workflows verwendet wurden und vieles mehr"])},
  "customer.news.29-04-2022.translation_reports.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsberichte"])},
  "customer.news.29-07-2020.multiple_files_translation_projects.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "customer.news.29-07-2020.multiple_files_translation_projects.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir können jetzt multiple-files projects bearbeiten, was bedeutet, dass du bei der create a project jetzt attach multiple files. Dadurch erstellst du multiple projects at once. Damit dein Arbeitsbereich übersichtlich bleibt, musst du die neuen Projekte entweder einem bestehenden Ordner zuordnen oder einfach einen neuen Ordner erstellen."])},
  "customer.news.29-07-2020.multiple_files_translation_projects.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle Übersetzungsprojekte mit mehreren Dateien"])},
  "customer.news.29-07-2020.remove_users_from_your_team.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.29-07-2020.remove_users_from_your_team.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du versehentlich eine*n Kolleg*in eingeladen hast oder deren Zugang entfernen möchtest, kannst du sie jetzt ganz einfach von deinem Team remove. Das bedeutet, dass der Benutzer keinen Zugriff mehr auf deinen Arbeitsbereich hat. Hierfür wählst du den remove button aus Teams > Users."])},
  "customer.news.29-07-2020.remove_users_from_your_team.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entferne Benutzer*innen aus deinem Team"])},
  "customer.news.29-07-2020.translation_projects_into_folders.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-folder"])},
  "customer.news.29-07-2020.translation_projects_into_folders.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während du zuvor nur alle deine Projekte sehen und neue erstellen konntest, kannst du sie jetzt bequem in dem workspace deines Kontos in folders gruppieren. Es ist ganz einfach! Du create a new folder, indem du rechts in deinem my workspace auf die Schaltfläche new folder klickst oder du kannst es bei Bedarf bei der project creation tun."])},
  "customer.news.29-07-2020.translation_projects_into_folders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisiere deine Übersetzungsprojekte in Ordnern"])},
  "customer.news.29-07-2020.users_can_decline_invitations.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "customer.news.29-07-2020.users_can_decline_invitations.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine eingeladenen Benutzer*innen können jetzt Einladungen ablehnen."])},
  "customer.news.29-07-2020.users_can_decline_invitations.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer*innen können Einladungen ablehnen"])},
  "customer.news.31-08-2022.custom_mt.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Custom Machine Translation ermöglicht es dir, deine eigene personalisierte maschinelle Übersetzung zu bekommen, die auf deinen bestehenden Inhalten basiert.\nWir verwenden dein aktuelles Translation Memory wieder, damit wir die Machine-Translation-Engine für dich intelligenter machen und genauer auf die Themen deiner vorhandenen Übersetzungen ausrichten können."])},
  "customer.onboarding.btn.login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast bereits ein Konto? ", _interpolate(_named("link")), " einloggen"])},
  "customer.onboarding.create_account.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Konto wurde erstellt. Du kannst dich jetzt in deinem Konto anmelden."])},
  "customer.onboarding.loader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen, ob alle Kommata richtig gesetzt wurden ..."])},
  "customer.onboarding.loader_signup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A, B, C, D, E, F, G ... fast geschafft!"])},
  "customer.overview.folders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner"])},
  "customer.overview.projects.animation.title.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen, ob alle Kommata richtig gesetzt wurden ..."])},
  "customer.overview.projects.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
  "customer.payment.usage.pricing_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieh dir unsere Preise an"])},
  "customer.permission.approve-project-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektpreis genehmigen"])},
  "customer.permission.create-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmethode hinzufügen"])},
  "customer.permission.create-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkonto erstellen"])},
  "customer.permission.create-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument zur Inhaltsgenerierung erstellen"])},
  "customer.permission.create-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage zur Inhaltsgenerierung erstellen"])},
  "customer.permission.create-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt erstellen"])},
  "customer.permission.create-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner erstellen"])},
  "customer.permission.create-task-revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenrevision übermitteln"])},
  "customer.permission.create-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammitglied hinzufügen"])},
  "customer.permission.create-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Teamrolle erstellen"])},
  "customer.permission.create-translation-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer hinzufügen"])},
  "customer.permission.create-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-Endpunkt erstellen"])},
  "customer.permission.delete-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmethode löschen"])},
  "customer.permission.delete-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkonto löschen"])},
  "customer.permission.delete-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument zur Inhaltsgenerierung löschen"])},
  "customer.permission.delete-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage zur Inhaltsgenerierung löschen"])},
  "customer.permission.delete-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner löschen"])},
  "customer.permission.delete-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammitglied entfernen"])},
  "customer.permission.delete-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Teamrolle löschen"])},
  "customer.permission.delete-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-Endpunkt löschen"])},
  "customer.permission.download-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung herunterladen"])},
  "customer.permission.download-project-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektdateien herunterladen"])},
  "customer.permission.join-channel-chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat-Kanal beitreten"])},
  "customer.permission.read-billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdetails anzeigen"])},
  "customer.permission.read-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmethoden anzeigen"])},
  "customer.permission.read-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkonten anzeigen"])},
  "customer.permission.read-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente zur Inhaltsgenerierung anzeigen"])},
  "customer.permission.read-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen zur Inhaltserstellung anzeigen"])},
  "customer.permission.read-oauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth-Anmeldeinformationen anzeigen"])},
  "customer.permission.read-payin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisinformationen anzeigen"])},
  "customer.permission.read-price-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisliste anzeigen"])},
  "customer.permission.read-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektdetails anzeigen"])},
  "customer.permission.read-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner anzeigen"])},
  "customer.permission.read-task-revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenrevisionen anzeigen"])},
  "customer.permission.read-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinformationen anzeigen"])},
  "customer.permission.read-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammitglieder anzeigen"])},
  "customer.permission.read-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrollen und -berechtigungen anzeigen"])},
  "customer.permission.read-translation-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzugefügte Freelancer anzeigen"])},
  "customer.permission.read-translation-team-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise für Freiberufler anzeigen"])},
  "customer.permission.read-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-Endpunkt anzeigen"])},
  "customer.permission.update-automation-price-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preislimit für Automatisierung aktualisieren"])},
  "customer.permission.update-billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdetails aktualisieren"])},
  "customer.permission.update-billing-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmethode aktualisieren"])},
  "customer.permission.update-child-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkonto aktualisieren"])},
  "customer.permission.update-content-generation-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument zur Inhaltsgenerierung aktualisieren"])},
  "customer.permission.update-content-generation-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage zur Inhaltsgenerierung aktualisieren"])},
  "customer.permission.update-project-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner aktualisieren"])},
  "customer.permission.update-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinformationen aktualisieren"])},
  "customer.permission.update-team-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammitglied aktualisieren"])},
  "customer.permission.update-team-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Teamrolle aktualisieren"])},
  "customer.permission.update-translation-team-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie die Preise für Freiberufler"])},
  "customer.permission.update-user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrollen und -berechtigungen aktualisieren"])},
  "customer.permission.update-webhook-endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-Endpunkt aktualisieren"])},
  "customer.phrase_connector.active_connetor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver Anschluss"])},
  "customer.phrase_connector.connection_unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung fehlgeschlagen"])},
  "customer.phrase_connector.header_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integriere dein EasyTranslate-Konto in dein Phrase-Konto"])},
  "customer.phrase_connector.header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration mit Phrase"])},
  "customer.phrase_connector.language_pairing.create_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Erstellen der Sprachpaare ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
  "customer.phrase_connector.language_pairing.create_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaare erfolgreich erstellt"])},
  "customer.phrase_connector.language_pairing.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaare erstellen"])},
  "customer.phrase_connector.language_pairing.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaare erfolgreich erstellt"])},
  "customer.phrase_connector.language_pairing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaare"])},
  "customer.phrase_connector.language_pairing.update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aktualisieren der Sprachpaare ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
  "customer.phrase_connector.language_pairing.update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaare erfolgreich aktualisiert"])},
  "customer.phrase_connector.step_language_pairing.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denke daran, nach Änderungen oder Löschungen an den Sprachpaaren auf „Speichern“ zu klicken"])},
  "customer.phrase_connector.step_language_pairing.phrase_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrasecode"])},
  "customer.phrase_connector.step_language_pairing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaare einrichten"])},
  "customer.phrase_connector.step_one.hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zu Phrase hinzufügen"])},
  "customer.phrase_connector.step_one.hint_1_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiere den untenstehenden Link und füge ihn bei Phrase hinzu"])},
  "customer.phrase_connector.step_one.hint_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund*innen-ID hinzufügen"])},
  "customer.phrase_connector.step_one.hint_2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deine Kund*innen-ID von Phrase ein"])},
  "customer.phrase_connector.step_one.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfe dein Phrase-Konto mit deinem EasyTranslate-Konto"])},
  "customer.phrase_connector.step_one.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zu Phrase aktivieren"])},
  "customer.phrase_connector.step_three.click_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten auf den Button, um den Verifizierungsprozess zu starten"])},
  "customer.phrase_connector.step_three.go_to_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase öffnen"])},
  "customer.phrase_connector.step_three.provider_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister*in erstellen"])},
  "customer.phrase_connector.step_three.provider_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister*in erfolgreich erstellt"])},
  "customer.phrase_connector.step_three.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst jetzt deine allererste Aufgabe über Phrase erstellen"])},
  "customer.phrase_connector.step_three.success_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achte darauf, dass du den ET_admin als Übersetzer*in zuweist und den richtigen Workflow verwendest"])},
  "customer.phrase_connector.step_three.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Flows verifizieren"])},
  "customer.phrase_connector.step_three.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Flows verifizieren"])},
  "customer.phrase_connector.step_three.token_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfe dein Phrase-Konto mit deinem EasyTranslate-Konto"])},
  "customer.phrase_connector.step_three.token_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugangstoken erfolgreich erstellt"])},
  "customer.phrase_connector.step_three.webhook_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook erstellen"])},
  "customer.phrase_connector.step_three.webhook_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook erfolgreich erstellt"])},
  "customer.phrase_connector.step_three.workflow_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle deinen Workflow und aktiviere ihn"])},
  "customer.phrase_connector.step_three.workflow_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow erfolgreich erstellt"])},
  "customer.phrase_connector.step_two.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deinen Workflow erstellen"])},
  "customer.phrase_connector.step_two.text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge die Workflow-ID in das Feld „Notizen“ bei Phrase hinzu"])},
  "customer.phrase_connector.step_two.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow erstellen"])},
  "customer.placeholder.search_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach einem Projektnamen/Bezeichner oder Ordnernamen"])},
  "customer.price_limit.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestimme ein Preislimit für den automatisierten Prozess. Alle Projekte mit einem höheren Preiswert benötigen deine Zustimmung, bevor sie ausgeführt werden."])},
  "customer.price_limit.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lege dein Preislimit fest"])},
  "customer.price_limit.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lege ein Preislimit für deine automatisierte Übersetzungslösung fest."])},
  "customer.privacy.consent.marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
  "customer.privacy.consent.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
  "customer.privacy.consent.product_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorien"])},
  "customer.privacy.consent.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja, ich stimme dem Empfang von", _interpolate(_named("marketing")), " per E-Mail von EasyTranslate A/S bezüglich ihrer ", _interpolate(_named("product_categories")), " zu. EasyTranslate verarbeitet meine personenbezogenen Daten gemäß seiner ", _interpolate(_named("privacy_policy")), ". Meine Zustimmung kann ich jederzeit widerrufen: ", _interpolate(_named("here")), "."])},
  "customer.privacy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "customer.progress.add_billing_method.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Abrechnungsmethode hinzufügen"])},
  "customer.progress.add_review_supplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturleser hinzufügen (optional)"])},
  "customer.progress.add_translation_supplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer hinzufügen (optional)"])},
  "customer.progress.automation_flows.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierte Workflows"])},
  "customer.progress.automation_flows.steps.create_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierten Workflow erstellen (optional)"])},
  "customer.progress.create_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierten Workflow erstellen (optional)"])},
  "customer.progress.create_review_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle dein erstes Übersetzungs- und Korrekturprojekt (optional)"])},
  "customer.progress.create_translation_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle dein erstes Übersetzungsprojekt"])},
  "customer.progress.enable_wallet.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere dein Wallet"])},
  "customer.progress.first_project.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein erstes Projekt"])},
  "customer.progress.getting_started.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Schritte"])},
  "customer.progress.getting_started.steps.add_billing_method.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Abrechnungsmethode hinzufügen"])},
  "customer.progress.getting_started.steps.enable_wallet.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere dein Wallet"])},
  "customer.progress.getting_started.steps.update_billing_details.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiere deine Rechnungsdaten"])},
  "customer.progress.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erfahre, welche Schritte du durchführen musst, um loszulegen. Alle Schritte aus ", _interpolate(_named("name")), " sind verpflichtend"])},
  "customer.progress.update_billing_details.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiere deine Rechnungsdaten"])},
  "customer.progress.use_account_workflow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierten Workflow verwenden (optional)"])},
  "customer.project.approve_project.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätige den Preis deines Projekts"])},
  "customer.project.button.download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles herunterladen"])},
  "customer.project.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektname existiert bereits"])},
  "customer.project.skipped_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert: Nicht ausgeführt, da die Kriterien nicht erfüllt wurden"])},
  "customer.project.zip.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade die ZIP-Datei herunter oder erstelle eine neue Vorbereitungsdatei"])},
  "customer.project.zip.email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du erhältst eine E-Mail, wenn die ZIP-Datei zum Download bereitsteht"])},
  "customer.project.zip.prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke auf die Schaltfläche Datei vorbereiten, um den Vorgang zu starten"])},
  "customer.project.zip.prepare_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei vorbereiten"])},
  "customer.project.zip.prepare_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei vorbereiten"])},
  "customer.project.zip.preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird vorbereitet"])},
  "customer.project.zip.re_prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst die Datei hier neu vorbereiten"])},
  "customer.project_modal.preferred_deadline.set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um den Wunschtermin festzulegen"])},
  "customer.project_modal.preferred_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Festlegen eines bevorzugten Abgabetermins übergeht die Zuweisungslogik in LaaS, indem die Verfügbarkeit deines*deiner Übersetzer*in ignoriert wird. Dies bedeutet, dass dein*e Übersetzer*in möglicherweise nicht zu deinem bevorzugten Abgabetermin liefern kann, was bedeutet, dass deine Aufgabe möglicherweise einem*einer anderen Übersetzer*in in deinem Team zugewiesen wird und es keine Garantie dafür gibt, dass jemand in deinem Team die Kapazität hat, zu deinem bevorzugten Termin zu liefern. Bitte kontaktiere im Zweifelsfall immer deine*n Übersetzer*in über die Nachrichtenfunktion."])},
  "customer.project_modal.preferred_deadline_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass bei der Bestellung von Übersetzung + Korrektur deine bevorzugte Frist nur für Übersetzer*innen und nicht für Korrektor*innen sichtbar ist. Du solltest deine*n Übersetzer*in informieren, damit er*sie dies berücksichtigen kann."])},
  "customer.project_modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass wir nur Geschäftskund*innen akzeptieren"])},
  "customer.project_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle hier dein Projekt"])},
  "customer.project_modal.video_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-Tutorial zum Erstellen eines Projekts"])},
  "customer.projects.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keine laufenden Projekte."])},
  "customer.rate.modal.recommend_freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Würdest du deine*n Freelancer*in weiterempfehlen?"])},
  "customer.reassign_task.customer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund*in neu zuordnen"])},
  "customer.reassign_task.freelancer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer*in neu zuordnen"])},
  "customer.reassign_task.not_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer*in nicht zugeordnet"])},
  "customer.reports.btn.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht herunterladen"])},
  "customer.reports.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Bericht enthält Informationen zu deinen Projekten wie den Benutzernamen der Projektersteller*innen, die Anzahl der Wörter, der verwendete Workflow sowie den Projektstatus, den Aufgabentyp und mehr."])},
  "customer.reports.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade deine persönlichen Übersetzungsberichte herunter"])},
  "customer.roles.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Rolle erstellen"])},
  "customer.roles.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine benutzerdefinierten Rollen gefunden"])},
  "customer.roles.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Rollen nicht gefunden"])},
  "customer.signup.success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Anmeldung. Du wirst nun auf die Anmeldeseite weitergeleitet."])},
  "customer.sub_nav.account_info.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinformation"])},
  "customer.sub_nav.account_structor.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenstruktur"])},
  "customer.sub_nav.api.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
  "customer.sub_nav.balance.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
  "customer.sub_nav.billing.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung"])},
  "customer.sub_nav.caas.save_your_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Dokumente speichern"])},
  "customer.sub_nav.folders.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner"])},
  "customer.sub_nav.impersonate.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer*in übernehmen"])},
  "customer.sub_nav.price_limit.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preislimit"])},
  "customer.sub_nav.privacy.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz & Einwilligung"])},
  "customer.sub_nav.profile.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "customer.sub_nav.projects.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
  "customer.sub_nav.reports.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsberichte"])},
  "customer.sub_nav.revision_price.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturpreis"])},
  "customer.sub_nav.roles_permission.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerberechtigungen"])},
  "customer.sub_nav.translation_price.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungspreis"])},
  "customer.sub_nav.usage.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])},
  "customer.sub_nav.users_permission.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerberechtigungen"])},
  "customer.sub_nav.wallet.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "customer.subscription.update.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement aktualisiert"])},
  "customer.template.modal.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorlagenname existiert bereits"])},
  "customer.terms_conditions.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben unsere Allgemeinen Geschäftsbedingungen aktualisiert. Bevor du unsere Plattform weiternutzen kannst, musst du unsere neuen Geschäftsbedingungen akzeptieren."])},
  "customer.terms_conditions.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Geschäftsbedingungen"])},
  "customer.toast.error.caas_invalid_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Schlüsselwort. Bitte berücksichtige das im Tooltip beschriebene Format"])},
  "customer.toast.error.caas_no_special_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderzeichen sind in Schlüsselnamen nicht erlaubt"])},
  "customer.toast.error.caas_no_whitespaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerzeichen sind in Schlüsselnamen nicht erlaubt"])},
  "customer.toast.error.connect_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Versuch, eine Verbindung zu Stripe herzustellen, ist ein Fehler aufgetreten"])},
  "customer.toast.error.folder_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen: Dein Ordner konnte nicht gelöscht werden"])},
  "customer.toast.error.invalid_account": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es konnte kein Konto mit dem angegebenen Namen „", _interpolate(_named("account")), "“ gefunden werden"])},
  "customer.toast.error.invalid_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Kontoname"])},
  "customer.toast.error.min_five_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib vor dem Speichern mindestens 5 Schlüsselwörter ein"])},
  "customer.toast.error.prefill_account_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen und wir konnten deine Kontoinformationen nicht automatisch ausfüllen"])},
  "customer.toast.error.rating_min_one_star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst mindestens einen Start auswählen"])},
  "customer.toast.error.register_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen und wir konnten dich nicht anmelden"])},
  "customer.toast.error.registration_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist bei der Registrierung schief gelaufen"])},
  "customer.toast.error.something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen - bitte versuche es erneut"])},
  "customer.toast.error.sub_tier_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Abonnementstufe ist aufgebraucht"])},
  "customer.toast.error.team_no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast nicht die erforderlichen Berechtigungen, um diese Aktion auszuführen"])},
  "customer.toast.error.update_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Versuch, deine Zahlungsmethode zu aktualisieren, ist ein Fehler aufgetreten"])},
  "customer.toast.error.user_stopped_action_close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten: Deine Anfrage wurde gestoppt, da das Fenster während der Bearbeitung geschlossen wurde."])},
  "customer.toast.success.accept_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Projekt angenommen"])},
  "customer.toast.success.card_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Standardkarte wurde aktualisiert"])},
  "customer.toast.success.confirm_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Segmente wurden bestätigt"])},
  "customer.toast.success.create_project_basic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Projekt „", _interpolate(_named("project")), "“ wurde erstellt."])},
  "customer.toast.success.create_project_unnamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Projekt wurde erstellt. Du findest deine Projekt in deinem", _interpolate(_named("workspaceLink")), "."])},
  "customer.toast.success.create_project_unnamed_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde erstellt."])},
  "customer.toast.success.flow_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Automatisierungsablauf wurde erfolgreich gelöscht"])},
  "customer.toast.success.folder_create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ordner „", _interpolate(_named("folder")), "“ wurde erstellt"])},
  "customer.toast.success.inactive_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Wallet ist jetzt inaktiv"])},
  "customer.toast.success.invitation_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einladung wurde gelöscht und der*die Benutzer*in wurde entfernt"])},
  "customer.toast.success.library_add_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("language")), "“ wurde deiner Bibliothek hinzugefügt"])},
  "customer.toast.success.library_automation_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Automatisierung für diese Bibliothek wurde eingeschaltet und ist jetzt aktiv"])},
  "customer.toast.success.library_create_key": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlüssel „", _interpolate(_named("key")), "“ wurde erstellt"])},
  "customer.toast.success.library_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bibliothek „", _interpolate(_named("name")), "“ wurde erstellt"])},
  "customer.toast.success.library_delete_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schlüssel wurden gelöscht"])},
  "customer.toast.success.library_remove": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bibliothek „", _interpolate(_named("library")), "“ wurde erfolgreich gelöscht"])},
  "customer.toast.success.library_remove_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("language")), "“ wurde aus deiner Bibliothek entfernt"])},
  "customer.toast.success.library_save_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen an deinem String wurden gespeichert"])},
  "customer.toast.success.library_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deine Änderungen an der Bibliothek „", _interpolate(_named("library")), "“ wurden gespeichert"])},
  "customer.toast.success.rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank, deine Bewertung wurde übermittelt"])},
  "customer.toast.success.remove_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zahlungsmethode wurde erfolgreich entfernt"])},
  "customer.toast.success.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der*die Benutzer*in wurde erfolgreich entfernt"])},
  "customer.toast.success.request_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Anfrage wird bearbeitet und ein*e neue*r Linguist*in wird in Kürze zugewiesen"])},
  "customer.toast.success.segments_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Segmente wurden bestätigt"])},
  "customer.toast.success.segments_unconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Segmente wurden auf nicht bestätigt gesetzt"])},
  "customer.toast.success.update_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Rechnungsinformationen wurden aktualisiert"])},
  "customer.toast.success.update_price_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Preislimit wurde aktualisiert"])},
  "customer.toast.success.update_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Dienstleistungspreise wurden aktualisiert"])},
  "customer.toast.success.update_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Profilinformationen wurden aktualisiert"])},
  "customer.toast.success.update_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA wurde erfolgreich aktualisiert"])},
  "customer.toast.success.update_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Wallet-Informationen wurden aktualisiert"])},
  "customer.toast.success.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast ein Upgrade erhalten"])},
  "customer.toast.warning.existing_team_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben dein Team gefunden. Du wirst benachrichtigt, wenn der*die Administrator*in deine Anfrage angenommen hat, dem Team beizutreten."])},
  "customer.toast.warning.incomplete_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor du aktualisieren kannst, musst du deine Zahlungsinformationen vervollständigen"])},
  "customer.toasts.error.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Korrektur konnte nicht als abgeschlossen markiert werden"])},
  "customer.tooltip.string_library.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Produktbibliothek, wenn du Inhalte generieren möchtest"])},
  "customer.tooltip.string_library.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Übersetzungsbibliothek aus, die du übersetzen möchtest"])},
  "customer.tooltip.string_library.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn mehr als der ausgewählte Prozentsatz der Strings in deiner Bibliothek nicht übersetzt sind, lösen wir diesen Workflow automatisch aus"])},
  "customer.tooltip.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass die Aufgabe dem*der ersten von dir ausgewählten Übersetzer*in zugewiesen wird. Wenn diese*r Übersetzer*in ablehnt, wird die Aufgabe den folgenden von dir ausgewählten Übersetzer*innen in chronologischer Reihenfolge zugewiesen."])},
  "customer.translation_assets.custom_mt.bleu_legend.10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast nutzlos"])},
  "customer.translation_assets.custom_mt.bleu_legend.19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwer das Wesentliche zu verstehen"])},
  "customer.translation_assets.custom_mt.bleu_legend.29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Wesentliche ist klar, weist jedoch erhebliche Grammatikfehler auf"])},
  "customer.translation_assets.custom_mt.bleu_legend.39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verständlich bis gute Übersetzungen"])},
  "customer.translation_assets.custom_mt.bleu_legend.49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochwertige Übersetzungen"])},
  "customer.translation_assets.custom_mt.bleu_legend.59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr hochwertige, angemessene und flüssige Übersetzungen"])},
  "customer.translation_assets.custom_mt.bleu_legend.60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualität oft besser als Mensch"])},
  "customer.translation_assets.custom_mt.bleu_legend.interpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolmetschen"])},
  "customer.translation_assets.custom_mt.cancel.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, Training abbrechen"])},
  "customer.translation_assets.custom_mt.cancel.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Training der abbrechen möchten", _interpolate(_named("name")), " Modell?"])},
  "customer.translation_assets.custom_mt.cancel.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung abbrechen"])},
  "customer.translation_assets.custom_mt.cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
  "customer.translation_assets.custom_mt.modal_instructions.requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst dieses Feature nur nutzen, wenn dein Konto die folgenden Anforderungen erfüllt:"])},
  "customer.translation_assets.custom_mt.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuelle MT"])},
  "customer.translation_assets.custom_mt.training_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsinfos"])},
  "customer.translation_assets.custom_mt.training_pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingspaare"])},
  "customer.translation_assets.custom_mt.training_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingszeit"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1000 Segmente/Strings"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nur unterstützte Sprachen - klicke", _interpolate(_named("here")), " , um die Liste zu sehen"])},
  "customer.translation_assets.custom_mt.view.info_block.requirement_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal 200 Wörter pro Segment/String"])},
  "customer.translation_assets.custom_mt.view.info_block.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein gespeichertes TM muss diese Anforderungen erfüllen"])},
  "customer.translation_assets.custom_mt.view_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle dein MT-Trainingsmodell"])},
  "customer.translation_assets.glossary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossar"])},
  "customer.translation_assets.translation_memory.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "customer.translation_price.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du die vereinbarte Preisgestaltung und das TM-Rabattschema (Translation Memory) einsehen."])},
  "customer.translation_price.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparenz hat für uns oberste Priorität."])},
  "customer.translation_price.discount.match_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 0-49 %"])},
  "customer.translation_price.discount.match_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 100 %"])},
  "customer.translation_price.discount.match_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 101 %"])},
  "customer.translation_price.discount.match_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 50-74 %"])},
  "customer.translation_price.discount.match_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 74-84 %"])},
  "customer.translation_price.discount.match_85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 85-94 %"])},
  "customer.translation_price.discount.match_95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung 95-99 %"])},
  "customer.translation_price.discount.repetitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholungen"])},
  "customer.translation_price.discount.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsrabatt"])},
  "customer.translation_price.price_list.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue hinzufügen"])},
  "customer.translation_price.price_list.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Preiseintrag"])},
  "customer.translation_price.price_list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisliste"])},
  "customer.translator.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es befinden sich noch keine Freelancer*innen in deinen Team"])},
  "customer.translator.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Freelancer*innen"])},
  "customer.translator.modal_service.change_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis ändern"])},
  "customer.translator.modal_service.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachte, dass die Dienstleistung dauerhaft gelöscht wird und diese Aktion nicht rückgängig gemacht werden kann."])},
  "customer.translator.modal_service.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Sprachpaare aus, die du aus deinem Konto löschen möchtest"])},
  "customer.translator.modal_service.delete.title_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du die folgenden Sprachpaare wirklich löschen?"])},
  "customer.translator.modal_service.delete_lang_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaar löschen"])},
  "customer.translator.modal_service.remove_translator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bist du sicher, dass du ", _interpolate(_named("name")), " aus deinem Konto löschen möchtest?"])},
  "customer.translator.modal_service.service_deleted.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ausgewählte Sprachpaar wurde aus deinen Dienstleistungen gelöscht"])},
  "customer.translator.modal_service.service_deleted.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistung gelöscht"])},
  "customer.translator.translator_remove.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " wurde erfolgreich von deinen Übersetzer*innen entfernt"])},
  "customer.translator.translator_remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in entfernt"])},
  "customer.translator_team.loader.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir analysieren dein Dokument und finden die beste(n) Übereinstimmung(en) für dich"])},
  "customer.translator_team.loader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in finden"])},
  "customer.upgarde_sub.word_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortguthaben"])},
  "customer.upgarde_sub.word_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortlimit"])},
  "customer.upgrade_sub.12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Monate"])},
  "customer.upgrade_sub.1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Monate"])},
  "customer.upgrade_sub.billed_annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährliche Abrechnung (50 % Ersparnis)"])},
  "customer.upgrade_sub.billed_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Abrechnung"])},
  "customer.upgrade_sub.contact_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales kontaktieren"])},
  "customer.upgrade_sub.contact_sus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "customer.upgrade_sub.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein aktueller Plan"])},
  "customer.upgrade_sub.custom_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierter Preis"])},
  "customer.upgrade_sub.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade"])},
  "customer.upgrade_sub.enterprise_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzte Möglichkeiten"])},
  "customer.upgrade_sub.feature.caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Einstellungen der Content Generation"])},
  "customer.upgrade_sub.feature.custom_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Anzahl von Wörtern"])},
  "customer.upgrade_sub.feature.customer_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugter Kund*innensupport"])},
  "customer.upgrade_sub.feature.optimisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Optimierungsgespräch"])},
  "customer.upgrade_sub.feature.technical_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugter technischer Support"])},
  "customer.upgrade_sub.feature.translator_recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierte Auswahl von Übersetzer*innen"])},
  "customer.upgrade_sub.finish_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung abschließen"])},
  "customer.upgrade_sub.free_trial.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte jetzt deine kostenlose Testversion und entdecke unsere Plattform"])},
  "customer.upgrade_sub.free_trial_anim.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*bei Jahresplänen"])},
  "customer.upgrade_sub.free_trial_anim.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt testen, später entscheiden"])},
  "customer.upgrade_sub.free_trial_anim.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 Tage kostenlose Testversion!"])},
  "customer.upgrade_sub.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "customer.upgrade_sub.payment.cancel_anytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jederzeit kündbar"])},
  "customer.upgrade_sub.payment.free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 Tage kostenlos testen"])},
  "customer.upgrade_sub.paymentInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib deine Zahlungsinformationen ein, damit du loslegen kannst"])},
  "customer.upgrade_sub.period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zeitraum:", _interpolate(_named("fromDate")), " bis ", _interpolate(_named("toDate"))])},
  "customer.upgrade_sub.plan_get_started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hol dir deinen ", _interpolate(_named("plan")), "-Plan"])},
  "customer.upgrade_sub.scaleup_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am beliebtesten"])},
  "customer.upgrade_sub.start_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt kostenlos testen"])},
  "customer.upgrade_sub.startup_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Einschränkungen nutzen"])},
  "customer.upgrade_sub.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge eine Zahlungsmethode für ein Upgrade hinzu"])},
  "customer.upgrade_sub.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dein Projekt übersteigt ", _interpolate(_named("text")), " deines aktuellen Plans"])},
  "customer.upgrade_sub.title.caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiere dein Konto, um dieses Feature freizuschalten"])},
  "customer.upgrade_sub.title.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finde einen Plan, der zu dir passt"])},
  "customer.upgrade_sub.trial.first_bullet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ersten 7 Tage sind kostenlos, eine Kündigung ist jederzeit innerhalb der 7 Tage möglich"])},
  "customer.upgrade_sub.trial.second_bullet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deine erste Zahlung wird abgebucht am ", _interpolate(_named("date"))])},
  "customer.upgrade_sub.trial.third_bullet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden dich 1 Tag vor Ablauf deiner Testversion daran erinnern"])},
  "customer.upgrade_sub.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
  "customer.usage.subaccount.account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamname"])},
  "customer.usage.subaccount.meta.content_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation"])},
  "customer.usage.subaccount.meta.machine_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MT &amp; Individuelle MT"])},
  "customer.usage.subaccount.meta.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menschliche Übersetzung"])},
  "customer.usage.subaccount.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte Nutzungsansicht für jedes Unterkonto"])},
  "customer.usage.subaccount.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzung von Unterkonten"])},
  "customer.user_permissions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewähren Sie Zugriff auf Konten"])},
  "customer.users_permissions.account.cancel_invite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bist dabei, die Einladung für ", _interpolate(_named("name")), " zu löschen. Möchtest du diese Aktion wirklich durchführen?"])},
  "customer.users_permissions.account.remove_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bist dabei, ", _interpolate(_named("name")), " von deinem Teamkonto zu entfernen. Bist du sicher, dass du diese Aktion ausführen möchtest?"])},
  "customer.users_permissions.btn.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue*n Benutzer*in einladen"])},
  "customer.users_permissions.btn.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer*in aus Konto entfernen?"])},
  "customer.users_permissions.modal.btn.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung senden"])},
  "customer.users_permissions.modal.edit.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst deine eigenen Rollen und Berechtigungen nicht bearbeiten"])},
  "customer.users_permissions.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer*in per E-Mail einladen"])},
  "customer.users_permissions.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Benutzer*innen in dein Team ein"])},
  "customer.users_permissions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Benutzer*innen"])},
  "customer.users_permissions.title_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benutzeranfragen"])},
  "customer.voucher.added_cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine hinzugefügten Karten"])},
  "customer.voucher.no_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoppla! Du bist nicht berechtigt, die Rechnungsdetails zu ändern. Wende dich an deine*n Teamadministrator*in", _interpolate(_named("admin")), " für die benötigten Berechtigungen."])},
  "customer.voucher.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überblick"])},
  "customer.voucher.paymet_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zahlungsinformationen"])},
  "customer.voucher.project_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektpreis"])},
  "customer.voucher.redeem_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein einlösen"])},
  "customer.voucher.saving_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlungsmethode wird für zukünftige Projekte in deinem Konto gespeichert"])},
  "customer.voucher.set_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard festlegen"])},
  "customer.voucher.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge eine Zahlungsmethode hinzu, um mit deinem Projekt fortzufahren"])},
  "customer.voucher.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
  "customer.voucher.total_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt (exkl. MwSt.)"])},
  "customer.voucher.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MwSt."])},
  "customer.voucher.voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein"])},
  "customer.wallet.about.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere dein Wallet und lege den gewünschten Betrag für das Aufladen und Wiederaufladen fest."])},
  "customer.wallet.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen leicht gemacht"])},
  "customer.wallet.monthly_spend.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist der Betrag, um den wir dein Guthaben wieder aufladen und erhöhen sollen."])},
  "customer.wallet.monthly_spend.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine erwarteten monatlichen Ausgaben"])},
  "customer.wallet.re_charge.day.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufladetag"])},
  "customer.wallet.re_charge.default_method.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwende deine voreingestellte Standardmethode, um das Aufladen des Wallets auszuführen."])},
  "customer.wallet.re_charge.default_method.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardabrechnungsmethode"])},
  "customer.wallet.re_charge.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle deine bevorzugte Aufladeart."])},
  "customer.wallet.re_charge.invoice.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwende eine Rechnung, die nicht sofort bezahlt wird, sondern dir zugesandt wird. Hierbei ist nur SEPA als Zahlungsmethode verfügbar."])},
  "customer.wallet.re_charge.settings.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die automatische Aufladefunktion lädt dein Wallet automatisch auf, wenn dein Guthaben unter das Mindestguthaben fällt oder wenn du das gewünschte Datum für die automatische Aufladung festlegst."])},
  "customer.wallet.re_charge.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen zum automatischen Aufladen"])},
  "customer.wallet.re_charge.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmethode für das Aufladen"])},
  "customer.wallet.status.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "customer.wallet.status.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "customer.wallet.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "customer.workflows.ai_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI"])},
  "customer.workflows.copywriting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting"])},
  "customer.workflows.copywriting_translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting &amp; Übersetzung"])},
  "customer.workflows.multilingual.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrsprachig"])},
  "customer.workflows.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherung"])},
  "customer.workflows.translation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung"])},
  "customer.workflows.translation_review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung &amp; Korrektur"])},
  "customre.library.shared.btn.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierung starten"])},
  "folders.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ordner erstellt"])},
  "folders.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ordner"])},
  "folders.folder.project_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["keine Projekte"]), _normalize(["ein Projekt"]), _normalize([_interpolate(_named("count")), " Projekte"])])},
  "folders.remove.confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchtest du den Ordner ", _interpolate(_named("folderName")), " wirklich entfernen?"])},
  "folders.remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner entfernen"])},
  "folders.remove.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Ordner wird dauerhaft entfernt und diese Aktion kann nicht rückgängig gemacht werden. Die Projekte im Ordner werden nicht gelöscht, sie werden in deine Übersicht verschoben."])},
  "folders.select.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Ordner auswählen"])},
  "label.notification.invite_dms_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wurdest hinzugefügt zu einer Unterhaltung mit"])},
  "label.notification.invite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Unterhaltung"])},
  "label.shared.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "label.shared.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA"])},
  "modal.title.supplier.recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl von Dienstleister*innen"])},
  "projects.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keine laufenden Projekte."])},
  "projects.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Projekte"])},
  "server.validation.accepted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das", _interpolate(_named("attribute")), " muss akzeptiert werden."])},
  "server.validation.account_workflow_duplicate_string_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die ausgewählte Bibliothek wurde bereits ein automatisierter Workflow gefunden"])},
  "server.validation.account_workflow_invalid_initial_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der ausgewählte Anfangsschritt ist nicht zulässig."])},
  "server.validation.account_workflow_invalid_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Workflow-Schritt „", _interpolate(_named("step")), "“ kann nicht gefolgt werden von „", _interpolate(_named("following_step")), "“"])},
  "server.validation.active_url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("attribute")), " ist keine gültige URL."])},
  "server.validation.add_language_to_library_duplicates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bereits hinzugefügte Sprachen: ", _interpolate(_named("target_languages"))])},
  "server.validation.after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Datum nach sein", _interpolate(_named("date")), "."])},
  "server.validation.after_or_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Datum nach oder genau ", _interpolate(_named("date")), " sein."])},
  "server.validation.alpha": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nur Buchstaben enthalten."])},
  "server.validation.alpha_dash": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nur Buchstaben, Zahlen, Bindestriche und Unterstriche enthalten."])},
  "server.validation.alpha_num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nur Buchstaben und Zahlen enthalten."])},
  "server.validation.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Feld sein."])},
  "server.validation.before": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Datum vor ", _interpolate(_named("date")), " sein."])},
  "server.validation.before_or_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Datum vor oder genau ", _interpolate(_named("date")), " sein."])},
  "server.validation.between.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " Elemente haben."])},
  "server.validation.between.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " Kilobyte liegen."])},
  "server.validation.between.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " liegen."])},
  "server.validation.between.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " Zeichen haben."])},
  "server.validation.boolean": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " Feld muss wahr oder falsch sein."])},
  "server.validation.chat_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nachrichten können aufgrund ", _interpolate(_named("reason")), " nicht gesendet werden"])},
  "server.validation.company_email_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine geschäftliche E-Mail-Adresse ein"])},
  "server.validation.confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die", _interpolate(_named("attribute")), "-Bestätigung stimmt nicht überein."])},
  "server.validation.country_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein gültiger ISO-3166 Alpha-2-Code sein"])},
  "server.validation.currency_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Wert ist keine gültige ISO-4217-Währung"])},
  "server.validation.date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " ist kein gültiges Datum."])},
  "server.validation.date_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Datum wie ", _interpolate(_named("date")), " sein."])},
  "server.validation.date_format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " passt nicht zum Format ", _interpolate(_named("format")), "."])},
  "server.validation.different": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " und ", _interpolate(_named("other")), " müssen unterschiedlich sein."])},
  "server.validation.digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ", _interpolate(_named("digits")), " Ziffern sein."])},
  "server.validation.digits_between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das", _interpolate(_named("attribute")), " muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " Ziffern liegen."])},
  "server.validation.dimensions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " hat ungültige Bildmaße."])},
  "server.validation.distinct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld hat einen doppelten Wert."])},
  "server.validation.download_library_extra_target_languages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die angegebenen Sprachen sind nicht Bestandteil der Bibliothek: ", _interpolate(_named("language_codes"))])},
  "server.validation.email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine gültige E-Mail-Adresse sein."])},
  "server.validation.ends_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mit einem der folgenden Werte enden: ", _interpolate(_named("values"))])},
  "server.validation.exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das gewählte ", _interpolate(_named("attribute")), " ist ungültig."])},
  "server.validation.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine Datei sein."])},
  "server.validation.filled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld muss einen Wert haben."])},
  "server.validation.finish_automation_bulk_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfachaktualisierung der Ausgangssprache über Workflow nicht möglich"])},
  "server.validation.finish_automation_for_completed_workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow beendet"])},
  "server.validation.gdpr_action_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Aktion wurde bereits angefordert"])},
  "server.validation.gdpr_supplier_pending_payouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen kann nicht angefordert werden, da Auszahlungen ausstehen"])},
  "server.validation.gdpr_supplier_pending_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen kann nicht angefordert werden, da du ausstehende Aufgaben hast"])},
  "server.validation.gt.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mehr als ", _interpolate(_named("value")), " Elemente haben."])},
  "server.validation.gt.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss größer sein als ", _interpolate(_named("value")), " Kilobyte."])},
  "server.validation.gt.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss größer sein als ", _interpolate(_named("value")), "."])},
  "server.validation.gt.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss größer sein als ", _interpolate(_named("value")), " Zeichen."])},
  "server.validation.gte.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ", _interpolate(_named("value")), " Elemente oder mehr haben."])},
  "server.validation.gte.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss größer als oder genau ", _interpolate(_named("value")), " Kilobyte sein."])},
  "server.validation.gte.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss größer als oder genau ", _interpolate(_named("value")), " sein."])},
  "server.validation.gte.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mehr als oder genau ", _interpolate(_named("value")), " Zeichen haben."])},
  "server.validation.image": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein Bild sein."])},
  "server.validation.in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das gewählte ", _interpolate(_named("attribute")), " ist ungültig."])},
  "server.validation.in_array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld existiert nicht in ", _interpolate(_named("other")), "."])},
  "server.validation.integer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine ganze Zahl sein."])},
  "server.validation.ip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine gültige IP-Adresse sein."])},
  "server.validation.ipv4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine gültige IPv4-Adresse sein."])},
  "server.validation.ipv6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine gültige IPv6-Adresse sein."])},
  "server.validation.json": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein gültiger JSON-String sein."])},
  "server.validation.language_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein gültiger Sprachcode sein"])},
  "server.validation.login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Anmeldeinformationen stimmen nicht mit unseren Daten überein."])},
  "server.validation.lt.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss weniger als ", _interpolate(_named("value")), " Elemente haben."])},
  "server.validation.lt.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss kleiner sein als ", _interpolate(_named("value")), " Kilobyte."])},
  "server.validation.lt.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss kleiner sein als ", _interpolate(_named("value")), "."])},
  "server.validation.lt.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss kleiner sein als ", _interpolate(_named("value")), " Zeichen."])},
  "server.validation.lte.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nicht mehr als ", _interpolate(_named("value")), " Elemente haben."])},
  "server.validation.lte.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss kleiner als oder genau ", _interpolate(_named("value")), " Kilobyte sein."])},
  "server.validation.lte.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss kleiner als oder genau ", _interpolate(_named("value")), " sein."])},
  "server.validation.lte.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss weniger als oder genau ", _interpolate(_named("value")), " Zeichen haben."])},
  "server.validation.match_supplier_delete_service_from_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistung kann nicht gelöscht werden. Bereits Teil des Teams"])},
  "server.validation.match_supplier_missing_project_template_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade eine Datei hoch, damit wir deine zukünftigen Dateien verarbeiten können. Bitte beachte, dass dies eine kleine Datei sein sollte"])},
  "server.validation.match_supplier_update_price_per_word_on_laas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Wort kann für LaaS-Kund*innen nicht geändert werden"])},
  "server.validation.max.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nicht mehr als ", _interpolate(_named("max")), " Elemente haben."])},
  "server.validation.max.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nicht größer sein als ", _interpolate(_named("max")), " Kilobyte."])},
  "server.validation.max.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nicht größer sein als ", _interpolate(_named("max")), "."])},
  "server.validation.max.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " darf nicht größer sein als ", _interpolate(_named("max")), " Zeichen."])},
  "server.validation.mimes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine Datei des folgenden Typs sein:", _interpolate(_named("values")), "."])},
  "server.validation.mimetypes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine Datei des folgenden Typs sein:", _interpolate(_named("values")), "."])},
  "server.validation.min.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mindestens ", _interpolate(_named("min")), " Elemente haben."])},
  "server.validation.min.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mindestens ", _interpolate(_named("min")), " Kilobyte haben."])},
  "server.validation.min.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mindestens ", _interpolate(_named("min")), " sein."])},
  "server.validation.min.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mindestens ", _interpolate(_named("min")), " Zeichen haben."])},
  "server.validation.not_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das gewählte ", _interpolate(_named("attribute")), " ist ungültig."])},
  "server.validation.not_regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Format ist ungültig."])},
  "server.validation.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine Nummer sein."])},
  "server.validation.password_reset_error_throttled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warte, bevor du es erneut versuchst,"])},
  "server.validation.password_reset_error_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Token zum Zurücksetzen des Passworts ist ungültig"])},
  "server.validation.password_reset_error_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir können keine*n Benutzer*in mit dieser E-Mail-Adresse finden."])},
  "server.validation.present": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld muss vorhanden sein."])},
  "server.validation.price_list_discounts_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Format angegeben"])},
  "server.validation.price_list_languages_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Format angegeben"])},
  "server.validation.project_creation_meta_value_too_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Metawert für den Schlüssel ", _interpolate(_named("key")), " ist länger als ", _interpolate(_named("limit")), " Zeichen"])},
  "server.validation.project_creation_missing_billing_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne deine Rechnungsdaten können wir deine Bestellung nicht bearbeiten. Bitte gehe zur Abrechnung, um sie auszufüllen."])},
  "server.validation.project_creation_missing_billing_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor dem Erstellen eines neuen Projekts ist mindestens eine Abrechnungsmethode erforderlich"])},
  "server.validation.project_creation_missing_project_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist keine Projektvorlage eingerichtet für ", _interpolate(_named("source_language")), " ", _interpolate(_named("identifier"))])},
  "server.validation.project_creation_missing_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Abonnement ist abgelaufen"])},
  "server.validation.project_creation_missing_translation_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für angeforderte Ausgangs-/Zielsprachenkombinationen ist ein Übersetzungsteam erforderlich"])},
  "server.validation.project_creation_same_source_and_target_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleiches Ausgangs- und Zielsprachenpaar ist nicht zulässig"])},
  "server.validation.project_creation_supplier_without_account_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dienstleister hat die angegebenen Sprachpaare nicht mit dem*der Kund*in verbunden"])},
  "server.validation.project_status_not_ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ungültige Aktion. Projekt nicht ", _interpolate(_named("status"))])},
  "server.validation.regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Format ist ungültig."])},
  "server.validation.registration_private_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine geschäftliche E-Mail-Adresse ein"])},
  "server.validation.registration_sso_not_verified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ist noch nicht verifiziert"])},
  "server.validation.required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich."])},
  "server.validation.required_if": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich, wenn ", _interpolate(_named("other")), " ", _interpolate(_named("value")), " ist."])},
  "server.validation.required_unless": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich, es sei denn", _interpolate(_named("other")), " ist in", _interpolate(_named("values")), "."])},
  "server.validation.required_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich, wenn ", _interpolate(_named("values")), " vorhanden ist."])},
  "server.validation.required_with_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich, wenn ", _interpolate(_named("values")), " vorhanden sind."])},
  "server.validation.required_without": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich, wenn ", _interpolate(_named("values")), " nicht vorhanden sind."])},
  "server.validation.required_without_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Feld ist erforderlich, wenn nichts von ", _interpolate(_named("values")), " vorhanden ist."])},
  "server.validation.same": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " und ", _interpolate(_named("other")), " müssen passen."])},
  "server.validation.size.array": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ", _interpolate(_named("size")), " Elemente haben."])},
  "server.validation.size.file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ", _interpolate(_named("size")), " Kilobyte sein."])},
  "server.validation.size.numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ", _interpolate(_named("size")), " sein."])},
  "server.validation.size.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ", _interpolate(_named("size")), " Zeichen haben."])},
  "server.validation.sso_unable_to_use_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO-registrierte Benutzer*innen dürfen sich nicht mit einem Passwort anmelden oder ihr Passwort zurücksetzen"])},
  "server.validation.start_automation_extra_target_languages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Automatisierung für Zielsprachen, die nicht Teil der Bibliothek sind, kann nicht gestartet werden: ", _interpolate(_named("target_languages"))])},
  "server.validation.start_automation_for_disabled_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierung kann nicht gestartet werden. Bibliothek ist deaktiviert"])},
  "server.validation.start_automation_for_disabled_library_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierung kann nicht gestartet werden. Automatisierung der Bibliothek ist nicht eingestellt oder Workflow ist nicht aktiv!"])},
  "server.validation.start_automation_for_source_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Automatisierung für die Quellsprache kann nicht gestartet werden"])},
  "server.validation.start_automation_for_wrong_library_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Automatisierung kann nicht auf die Korrekturbibliothek angewendet werden"])},
  "server.validation.starts_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mit einem der folgenden Werte beginnen: ", _interpolate(_named("values"))])},
  "server.validation.string": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss ein String sein."])},
  "server.validation.subscription_invalid_subscription_tier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stufe ", _interpolate(_named("tier")), " existiert nicht für ", _interpolate(_named("metric"))])},
  "server.validation.subscription_maximum_tier_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximal erlaubt ", _interpolate(_named("maximum")), " Einheiten für ", _interpolate(_named("metric")), " bei ", _interpolate(_named("plan")), " (", _interpolate(_named("tier")), ")"])},
  "server.validation.subscription_unable_to_update_subscription_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LaaS-Abonnementstufen können nicht aktualisiert werden"])},
  "server.validation.task_revision_unconfirmed_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe hat unbestätigte Segmente. Bitte nochmal überprüfen."])},
  "server.validation.team_identifier_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind nur Kleinbuchstaben mit Bindestrich und Zahlen erlaubt und sie dürfen nicht mit einem Bindestrich beginnen oder enden."])},
  "server.validation.template_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es konnte keine Vorlage mit der Ausgangssprache", _interpolate(_named("source_language")), " und Zielsprache", _interpolate(_named("target_languages")), " gefunden werden. Bitte wende dich an das Support-Team"])},
  "server.validation.throttle_login_request": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zu viele Anmeldeversuche. Bitte versuche es erneut in ", _interpolate(_named("seconds")), " Sekunden."])},
  "server.validation.throttle_process": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Verfahren wurde bereits vor ", _interpolate(_named("minutes")), " Minuten eingeleitet, bitte versuche es später noch einmal"])},
  "server.validation.timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das", _interpolate(_named("attribute")), " muss eine gültige Zone sein."])},
  "server.validation.unable_to_modify_predefined_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predefined_entity kann nicht geändert werden"])},
  "server.validation.unique": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das", _interpolate(_named("attribute")), " wurde bereits übernommen."])},
  "server.validation.unique_domain_email_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Firmendomain ist bereits bei uns registriert. Bitte kontaktiere uns über den Chat, um mehr zu erfahren"])},
  "server.validation.update_own": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deine eigenen ", _interpolate(_named("attribute")), " können nicht aktualisiert werden"])},
  "server.validation.uploaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " konnte nicht hochgeladen werden."])},
  "server.validation.url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), "-Format ist ungültig."])},
  "server.validation.user_invitation_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einladung wurde bereits angenommen"])},
  "server.validation.user_invitation_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einladung wurde bereits abgelehnt"])},
  "server.validation.uuid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss eine gültige UUID sein."])},
  "server.validation.vat_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Format der USt-ID."])},
  "server.validation.wallet_greater_invalid_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ", _interpolate(_named("attribute")), " muss mindestens ", _interpolate(_named("amount")), " ", _interpolate(_named("currency")), " betragen"])},
  "server.validation.wallet_invalid_currency_for_recharge_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Rechnungsmethode ist nur verfügbar für Währung in ", _interpolate(_named("currency"))])},
  "server.validation.wallet_invalid_threshold_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mindestguthaben kann nicht höher sein als das Guthaben"])},
  "server.validation.wallet_missing_threshold_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens eine Option zur automatischen Aufladung ist erforderlich"])},
  "server.validation.wallet_positive_credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Guthaben ist im Plus. Bitte wende dich an den Kundenservice."])},
  "server.validation.wallet_unable_to_disable_for_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Wallet kann für das Freemium-Abonnement nicht deaktiviert werden"])},
  "server.validation.zip_file_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ZIP-Datei ist noch nicht fertig"])},
  "server.validaton.supplier_onboarding_no_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider gibt es keine*n Übersetzer*in mit dieser Kombination. Bitte kontaktiere uns über den Chat."])},
  "shard.label.multiple_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Sprachen"])},
  "shard.label.one_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Sprache"])},
  "shared.account.account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Kontoinformationen"])},
  "shared.account.profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Profilinformationen"])},
  "shared.account_list.label.choose_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle dein Konto"])},
  "shared.account_list.label.choose_supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle deine*n Dienstleister*in"])},
  "shared.account_list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle den Kundentyp"])},
  "shared.banner.donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bewerte uns auf G2 - Wir spenden 25€ an die Ukraine für jede nicht anonyme Bewertung, die wir ", _interpolate(_named("here")), " erhalten. Du hast noch ", _interpolate(_named("days")), " Tage."])},
  "shared.button.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmen"])},
  "shared.button.add_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung hinzufügen"])},
  "shared.button.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr hinzufügen"])},
  "shared.button.add_more_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Schlüsselwörter hinzufügen (optional)"])},
  "shared.button.add_tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TB hinzufügen"])},
  "shared.button.add_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TM hinzufügen"])},
  "shared.button.approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigen"])},
  "shared.button.approve_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung genehmigen"])},
  "shared.button.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "shared.button.back_translator_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Freelancer*innen-Übersicht"])},
  "shared.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "shared.button.cancel_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung löschen"])},
  "shared.button.change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
  "shared.button.chat_supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt anzeigen"])},
  "shared.button.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "shared.button.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "shared.button.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschließen"])},
  "shared.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "shared.button.confirm_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen und weiter"])},
  "shared.button.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
  "shared.button.content_in_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalte in meiner Bibliothek ansehen"])},
  "shared.button.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
  "shared.button.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
  "shared.button.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
  "shared.button.create_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument erstellen"])},
  "shared.button.create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Ordner erstellen"])},
  "shared.button.create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt erstellen"])},
  "shared.button.create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage erstellen"])},
  "shared.button.decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "shared.button.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "shared.button.delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto löschen"])},
  "shared.button.delete_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow löschen"])},
  "shared.button.delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner löschen"])},
  "shared.button.delete_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel löschen"])},
  "shared.button.delete_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache löschen"])},
  "shared.button.delete_language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaar löschen"])},
  "shared.button.delete_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek löschen"])},
  "shared.button.delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistung löschen"])},
  "shared.button.delete_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion und alle folgenden löschen"])},
  "shared.button.delete_time_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit löschen"])},
  "shared.button.disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
  "shared.button.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "shared.button.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "shared.button.download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei herunterladen"])},
  "shared.button.download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung herunterladen"])},
  "shared.button.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "shared.button.edit_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste bearbeiten"])},
  "shared.button.edit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis bearbeiten"])},
  "shared.button.edit_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistung bearbeiten"])},
  "shared.button.edit_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion bearbeiten"])},
  "shared.button.embed_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code einbetten"])},
  "shared.button.enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "shared.button.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "shared.button.finish_onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding beenden"])},
  "shared.button.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur beenden"])},
  "shared.button.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "shared.button.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loslegen"])},
  "shared.button.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "shared.button.here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
  "shared.button.import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
  "shared.button.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung senden"])},
  "shared.button.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
  "shared.button.lets_get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los geht‘s!"])},
  "shared.button.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr lesen"])},
  "shared.button.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "shared.button.memsource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memsource öffnen"])},
  "shared.button.move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Ordner verschieben"])},
  "shared.button.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "shared.button.no_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, abbrechen"])},
  "shared.button.phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe in Phrase anzeigen"])},
  "shared.button.preselect_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorausgewählte Sprachen"])},
  "shared.button.previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
  "shared.button.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "shared.button.remove_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto entfernen"])},
  "shared.button.remove_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in entfernen"])},
  "shared.button.remove_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer*in entfernen"])},
  "shared.button.rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner umbenennen"])},
  "shared.button.request_account_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontolöschung anfordern"])},
  "shared.button.request_company_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschung des Unternehmens anfordern"])},
  "shared.button.resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheben"])},
  "shared.button.revise_generated_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generierte Inhalte überarbeiten"])},
  "shared.button.revise_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung korrigieren"])},
  "shared.button.revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur-Editor öffnen"])},
  "shared.button.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "shared.button.save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf speichern"])},
  "shared.button.search_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets suchen"])},
  "shared.button.see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sehen"])},
  "shared.button.see_all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dienstleistungen anzeigen"])},
  "shared.button.see_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In ", _interpolate(_named("format")), " sehen"])},
  "shared.button.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "shared.button.set_word_credits_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufschlüsselung des Wortguthabens anzeigen"])},
  "shared.button.set_word_credits_ratio.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten in Wortguthaben pro Service anzeigen"])},
  "shared.button.set_word_credits_ratio.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortguthaben-Kosten"])},
  "shared.button.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "shared.button.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
  "shared.button.skip_for_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorerst überspringen"])},
  "shared.button.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "shared.button.start_translating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung starten"])},
  "shared.button.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "shared.button.switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto wechseln"])},
  "shared.button.try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
  "shared.button.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "shared.button.update_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement aktualisieren"])},
  "shared.button.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizieren"])},
  "shared.button.verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto verifizieren"])},
  "shared.button.video_tutorial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Video-Tutorial zum Hinzufügen von ", _interpolate(_named("title"))])},
  "shared.button.view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle ansehen"])},
  "shared.button.yes_im_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich bin sicher"])},
  "shared.button.zip_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip-Download"])},
  "shared.caas.ads.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generiere Content für Werbeanzeigen und steigere deine Conversion Rate"])},
  "shared.caas.ads.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
  "shared.caas.blogs.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generiere überzeugende, qualitativ hochwertige Blogbeiträge"])},
  "shared.caas.blogs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogbeiträge"])},
  "shared.caas.button.clear_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe löschen"])},
  "shared.caas.button.lets_go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen &amp; schreiben"])},
  "shared.caas.button.replace_and_write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text generieren"])},
  "shared.caas.button.start_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung starten"])},
  "shared.caas.button.write_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterschreiben"])},
  "shared.caas.buttons.document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument"])},
  "shared.caas.buttons.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenztext"])},
  "shared.caas.data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate empfiehlt dir als Datenverantwortliche*r, alle Daten vor der Generierung von Inhalten zu anonymisieren, um den Fluss personenbezogener Daten zu minimieren (Datenminimierung, Artikel 5 der DSGVO)."])},
  "shared.caas.emails.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generiere ansprechende Inhalte für automatisierte E-Mails"])},
  "shared.caas.emails.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mails"])},
  "shared.caas.modal.check_accurateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präzise Inhalte in jeder Sprache"])},
  "shared.caas.modal.check_highQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochwertiger Content – schnell und in großem Umfang"])},
  "shared.caas.modal.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Die mit diesem Tool generierten Wörter werden auf den gesamten Wortverbrauch deines Lizenzplans angerechnet."])},
  "shared.caas.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst lediglich deine Anfrage eingeben (z. B. „Ein Blogbeitrag über AI“) und innerhalb von Sekunden entsteht ansprechender Content."])},
  "shared.caas.modal.text_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst lediglich deine Anfrage eingeben (z. B. „Ein Blogbeitrag über AI“) und innerhalb von Sekunden entsteht ansprechender Content."])},
  "shared.caas.modal.text_freemium_letsGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecke jetzt die Funktionen der Plattform! Beginnen wir mit unserem AI-Tool zur Generierung von Inhalten:"])},
  "shared.caas.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben gerade unser neues Feature, das Content Generation Tool, veröffentlicht und du kannst es KOSTENLOS ausprobieren!"])},
  "shared.caas.modal.title_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei EasyTranslate!"])},
  "shared.caas.product.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generiere ansprechende Produktbeschreibungen"])},
  "shared.caas.product.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbeschreibungen"])},
  "shared.caas.reset_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage auf Standard zurücksetzen"])},
  "shared.caas.sidebar.advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitert"])},
  "shared.caas.sidebar.basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "shared.caas.sidebar.creativity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreativität"])},
  "shared.caas.sidebar.frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzug für Wiederholungen"])},
  "shared.caas.sidebar.high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoch"])},
  "shared.caas.sidebar.instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anweisungen"])},
  "shared.caas.sidebar.low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrig"])},
  "shared.caas.sidebar.maxchars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Zeichenanzahl"])},
  "shared.caas.sidebar.maxchars.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir empfehlen, die max. Wortanzahl bei Nutzung dieses Features zu deaktivieren"])},
  "shared.caas.sidebar.presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzug für Verwendung"])},
  "shared.caas.sidebar.tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Wortzahl"])},
  "shared.caas.sidebar.tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
  "shared.caas.sidebar.video.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So generierst du ansprechenden Content"])},
  "shared.caas.sidebar.watch_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieh dir das Tutorial zur Generierung von Blog-Inhalten an"])},
  "shared.caas.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutze unser AI-Tool zur Erstellung ansprechender Inhalte"])},
  "shared.caas.subtitle_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst deine Vorgaben in jeder beliebigen Sprache schreiben (z. B. „Schreibe einen Artikel über KI“)"])},
  "shared.caas.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Generation"])},
  "shared.caas.tooltip.replace_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion ersetzt deinen Befehl durch die Eingabe (Mac: Command + Enter, Andere: Strg + Enter)"])},
  "shared.caas.tooltip.writing_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion setzt deine ursprüngliche Eingabeaufforderung fort"])},
  "shared.chat.actions.leave_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhaltung verlassen"])},
  "shared.chat.actions.leave_conversation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Unterhaltung wirklich verlassen?"])},
  "shared.chat.button.go_offline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als ", _interpolate(_named("offline")), " anzeigen"])},
  "shared.chat.button.go_online": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als ", _interpolate(_named("online")), " anzeigen"])},
  "shared.chat.button.new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Nachricht"])},
  "shared.chat.button.show_all_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Mitglieder anzeigen"])},
  "shared.chat.buttons.delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht löschen"])},
  "shared.chat.buttons.save_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel speichern"])},
  "shared.chat.channels.about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worum geht es in diesem Kanal?"])},
  "shared.chat.channels.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal erstellen"])},
  "shared.chat.channels.choose_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder hinzufügen"])},
  "shared.chat.channels.make_private_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf privat stellen"])},
  "shared.chat.channels.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Kanäle kommuniziert dein Team. Sie funktionieren am besten, wenn sie auf ein Thema ausgerichtet sind – zum Beispiel #Marketing."])},
  "shared.chat.channels.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Kanal erstellen"])},
  "shared.chat.channels.private_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Kanal auf privat gesetzt ist, kann er nur auf Einladung angesehen oder betreten werden."])},
  "shared.chat.channels.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerhalb von EasyTranslate teilen"])},
  "shared.chat.customer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Gruppenchat. Der*die Kundin und alle an dieser Aufgabe beteiligten Linguist*innen können deine Nachricht sehen. Die Chat-Funktion wird für QA-Aufgaben nicht unterstützt."])},
  "shared.chat.delete_channel.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal löschen"])},
  "shared.chat.delete_direct_message.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktnachricht löschen"])},
  "shared.chat.group_message.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppennachricht erstellen"])},
  "shared.chat.group_message.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppennachricht erstellen"])},
  "shared.chat.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "shared.chat.label.attachments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast ", _interpolate(_named("count")), " Anhang/Anhänge erhalten:"])},
  "shared.chat.label.channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
  "shared.chat.label.channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanäle"])},
  "shared.chat.label.dm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktnachricht"])},
  "shared.chat.label.dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktnachrichten"])},
  "shared.chat.label.group_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppennachrichten"])},
  "shared.chat.label.group_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppennachrichten"])},
  "shared.chat.label.more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Aktionen"])},
  "shared.chat.label.new_message_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
  "shared.chat.label.new_message_to.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner*in..."])},
  "shared.chat.label.remove_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder entfernen"])},
  "shared.chat.label.saved_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeicherte Artikel"])},
  "shared.chat.search_channels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kanäle durchsuchen..."])},
  "shared.chat.supplier.client_gets_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dein Kunde", _interpolate(_named("customer")), " erhält deine Nachrichten."])},
  "shared.chat.supplier_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Gruppenchat. Der*die Kundin und alle an dieser Aufgabe beteiligten Linguist*innen können deine Nachricht sehen."])},
  "shared.combination.no_match": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es gibt keine ", _interpolate(_named("lang")), "-Kombination. Bitte klicken Sie ", _interpolate(_named("here")), " Ihrem Team weitere Übersetzer*innen hinzufügen"])},
  "shared.content_form.automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisiere sich wiederholende und zeitaufwändige Aufgaben"])},
  "shared.content_form.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standarddateien"])},
  "shared.content_form.files_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B.: Excel, Word, Xliff, Json"])},
  "shared.content_form.improve_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbessere die Qualität und Einheitlichkeit der Übersetzungen"])},
  "shared.content_form.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin neu, ich habe noch keine Inhalte"])},
  "shared.content_form.plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugins"])},
  "shared.content_form.repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Verwaltungssystem"])},
  "shared.content_form.repository_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B.: Github, Gitlab"])},
  "shared.content_form.start_localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richte deine Lokalisierung neu ein"])},
  "shared.content_form.streamline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimiere interne Workflows und das Aufgabenmanagement"])},
  "shared.content_generation.apply_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage anwenden"])},
  "shared.content_generation.content_brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaltsbeschreibung"])},
  "shared.content_generation.delete_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument löschen"])},
  "shared.content_generation.delete_document.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du das folgende Dokument wirklich löschen:"])},
  "shared.content_generation.delete_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage löschen"])},
  "shared.content_generation.delete_template.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du die folgende Vorlage wirklich löschen:"])},
  "shared.content_generation.error_empty_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vorgabe darf nicht leer sein"])},
  "shared.content_generation.error_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Parameter für die maximale Wortanzahl muss mindestens 12 betragen"])},
  "shared.content_generation.import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage importieren"])},
  "shared.content_generation.main_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptthema"])},
  "shared.content_generation.manage_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du deine Dokumente verwalten"])},
  "shared.content_generation.manage_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du deine Vorlagen verwalten"])},
  "shared.content_generation.modals.change_document_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier deinen Dokumentnamen ändern"])},
  "shared.content_generation.modals.change_template_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier deinen Vorlagennamen ändern"])},
  "shared.content_generation.modals.rename_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentnamen ändern"])},
  "shared.content_generation.modals.rename_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagennamen ändern"])},
  "shared.content_generation.modals.save_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument speichern"])},
  "shared.content_generation.modals.save_tempate_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichere dein Dokument als Vorlage und verwende es erneut, indem du es importierst"])},
  "shared.content_generation.modals.save_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
  "shared.content_generation.new_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues leeres Dokument"])},
  "shared.content_generation.new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue leere Vorlage"])},
  "shared.content_generation.template_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage aktiv"])},
  "shared.content_generation.tone_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone of Voice"])},
  "shared.content_generation.unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gespeichert"])},
  "shared.content_pillar_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabeneditor für Content-Säulen"])},
  "shared.copywriting.complete_task.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du die folgende Aufgabe wirklich abschließen:"])},
  "shared.copywriting.complete_task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting-Aufgabe abschließen"])},
  "shared.copywriting.finish_copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting beenden"])},
  "shared.copywriting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting"])},
  "shared.days.Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
  "shared.days.Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
  "shared.days.Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
  "shared.days.Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
  "shared.days.Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
  "shared.days.Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
  "shared.days.Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
  "shared.editor.ai_filters.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "shared.editor.ai_filters.filter_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtereinstellungen"])},
  "shared.editor.ai_filters.is_ai_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit KI überarbeitet"])},
  "shared.editor.ai_filters.is_human_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Menschen überarbeitet"])},
  "shared.editor.ai_filters.is_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrte Segmente"])},
  "shared.editor.ai_filters.is_untranslatable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht übersetzbare Segmente"])},
  "shared.editor.ai_filters.score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation-Memory-Übereinstimmungen"])},
  "shared.editor.btn.confirm_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Segmente bestätigen"])},
  "shared.editor.btn.unconfirm_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Segmente auf nicht bestätigt setzen"])},
  "shared.editor.linked_segments.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle identischen Segmente anzeigen"])},
  "shared.editor.tagging_issues.pro_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profi-Tipp: Quellsegment als Informationsquelle verwenden"])},
  "shared.editor.tagging_issues.step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke unten auf „Tags korrigieren“"])},
  "shared.editor.tagging_issues.step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe, ob die Tags richtig angeordnet sind"])},
  "shared.editor.tagging_issues.step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn nicht, klicke auf das Tag und platziere es an der gewünschten Stelle"])},
  "shared.editor.tagging_issues.step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kannst Tags auch erstellen, indem du Folgendes eingibst:", _interpolate(_named("tagExample")), " (achte darauf, die richtige Nummer einzugeben)"])},
  "shared.editor.tagging_issues.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei dieser Datei gibt es Tagging-Probleme!"])},
  "shared.editor.toast.char_limit_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Segment überschreitet die maximale Zeichenanzahl. Bitte passe den Text entsprechend der maximalen Zeichenanzahl an."])},
  "shared.editor_preview.tab.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "shared.editor_preview.tab.xlf_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLF-Hinweise"])},
  "shared.embed_code.toast.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code kopiert"])},
  "shared.empty.clients.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten keine Kund*innen finden. Versuche es später noch einmal."])},
  "shared.empty.clients.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kund*innen"])},
  "shared.empty.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Verlauf verfügbar."])},
  "shared.error_titles.generic_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fehler ", _interpolate(_named("code"))])},
  "shared.errors.generic_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angeforderte Ressource konnte nicht gefunden werden"])},
  "shared.gdpr.delete.admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese*r Benutzer*in hat die Löschung seines Konto angefordert. Klicke unten auf Löschen, um das Konto dauerhaft zu löschen."])},
  "shared.gdpr.delete.modal_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bestätige, dass ich den obigen Text gelesen und verstanden habe und möchte mein EasyTranslate-Konto löschen."])},
  "shared.gdpr.delete.modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion löscht dein EasyTranslate-Konto zusammen mit den ausgewählten Daten. Deine Daten werden gelöscht und dein EasyTranslate-Konto ist nicht mehr zugänglich."])},
  "shared.gdpr.delete.modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto löschen"])},
  "shared.gdpr.delete.option_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "shared.gdpr.delete.option_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "shared.gdpr.delete.option_source_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgangsdateien"])},
  "shared.gdpr.delete.option_target_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zieldateien"])},
  "shared.gdpr.delete.option_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "shared.gdpr.delete.select_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle aus, welche deiner Daten wir aus unseren Datenbanken löschen sollen, wenn wir dein Konto löschen:"])},
  "shared.gdpr.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Konto löschen"])},
  "shared.gdpr.delete.user_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zurzeit liegt eine ", _interpolate(_named("pending")), " Anfrage auf Löschung dieses Kontos vor. Wir bearbeiten derzeit deine Anfrage."])},
  "shared.gdpr.delete_company.modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion löscht dein Unternehmen aus EasyTranslate, einschließlich aller Unterkonten und deren Inhalte. Deine Daten werden außerdem dauerhaft gelöscht und Ihr EasyTranslate-Konto ist nicht mehr zugänglich."])},
  "shared.gdpr.delete_company.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle aus, welche deiner Daten wir aus unseren Datenbanken löschen sollen, wenn wir dein Unternehmen löschen:"])},
  "shared.gdpr.delete_company.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Unternehmen löschen"])},
  "shared.gdpr.delete_company.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion löscht alles im Zusammenhang mit deinem Unternehmen, einschließlich Unterkonten und deren Inhalt."])},
  "shared.gdpr.export.option_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "shared.gdpr.export.option_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])},
  "shared.gdpr.export.option_personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persönlich"])},
  "shared.gdpr.export.option_source_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source files"])},
  "shared.gdpr.export.option_target_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target files"])},
  "shared.gdpr.export.option_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "shared.gdpr.export.preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip-Datei wird vorbereitet ..."])},
  "shared.gdpr.export.request_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Download anfordern"])},
  "shared.gdpr.export.tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Memory"])},
  "shared.gdpr.team_admin.data_retention.never_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "shared.gdpr.team_admin.data_retention.standard_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Monate"])},
  "shared.gdpr.team_admin.data_retention_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichereinstellungen"])},
  "shared.gdpr.team_admin.delete.impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Administrator kannst du die Konten und Daten von Teammitgliedern löschen. Gehe hierfür zu Einstellungen > Benutzerberechtigungen > Optionen > „Benutzer*in übernehmen“"])},
  "shared.gdpr.team_admin.export.impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Administrator kannst du die Daten aller Teammitglieder herunterladen. Gehe hierfür zu Einstellungen > Benutzerberechtigungen > Optionen > „Benutzer*in übernehmen“"])},
  "shared.gdpr.team_admin.retention_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle aus, ob wir deine Daten nach 12 Monaten löschen sollen (Standard) oder ob wir sie nicht löschen müssen (nie)"])},
  "shared.gdpr.zip.button.download_latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste Datei herunterladen (.zip)"])},
  "shared.gdpr.zip.request_in_mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kannst in ", _interpolate(_named("zipTimestamp")), " Minuten eine neue Datei anfordern"])},
  "shared.gdpr.zip.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade deine Daten herunter"])},
  "shared.gdpr.zip.when_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du erhältst eine E-Mail, wenn die neue ZIP-Datei zum Download bereitsteht"])},
  "shared.google_signup.empty_consents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst unseren unten stehenden Nutzungsbedingungen zustimmen, um dich anmelden zu können"])},
  "shared.google_signup.sso_or_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder dein Konto manuell erstellen"])},
  "shared.header.create_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenfreies Konto erstellen"])},
  "shared.labe.last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bearbeitet"])},
  "shared.label.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "shared.label.account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotyp"])},
  "shared.label.action_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Änderung kann nicht rückgängig gemacht werden"])},
  "shared.label.action_may_take_few_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Vorgang kann einige Minuten dauern"])},
  "shared.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "shared.label.add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hinzufügen"])},
  "shared.label.add_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Schlüssel hinzufügen"])},
  "shared.label.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "shared.label.admin_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-Konto"])},
  "shared.label.ai_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI-Übersetzung"])},
  "shared.label.ai_translation.split_file_dropdown_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile Datei 1.a auf"])},
  "shared.label.ai_translation.split_file_dropdown_1.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle den Schwellenwert"])},
  "shared.label.ai_translation.split_file_dropdown_1.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert definiert, ob die Datei in kleinere Dateien aufgeteilt werden kann. Wenn die Wortanzahl in der Datei unter diesem Wert liegt, wird sie nicht aufgeteilt. Wenn der Wert überschritten wird, wird die Datei basierend auf der von dir ausgewählten Menge in mehrere Dateien aufgeteilt."])},
  "shared.label.ai_translation.split_file_dropdown_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile Datei 1.b auf"])},
  "shared.label.ai_translation.split_file_dropdown_2.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Anzahl der Wörter"])},
  "shared.label.ai_translation.split_file_dropdown_2.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die Wortanzahl der hochgeladenen Datei den Schwellenwert überschreitet, wird sie in kleinere Projekte aufgeteilt, von denen jedes weniger Wörter enthält als die hier angegebene Grenze."])},
  "shared.label.ai_translation.template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
  "shared.label.ai_translation.template.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Vorlage aus"])},
  "shared.label.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "shared.label.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
  "shared.label.any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebig"])},
  "shared.label.approval_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung benötigt"])},
  "shared.label.approve_new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Preis bestätigen"])},
  "shared.label.archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
  "shared.label.areas_expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachgebiete (max.: 3)"])},
  "shared.label.balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
  "shared.label.bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkonto"])},
  "shared.label.beta_editor.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Version zeigt nur markierte Segmente an. Deaktiviere „Beta“, um alle Segmente anzuzeigen."])},
  "shared.label.billed_annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährliche Abrechnung"])},
  "shared.label.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
  "shared.label.birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
  "shared.label.bleu_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLEU-Punktzahl"])},
  "shared.label.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting vereinbaren"])},
  "shared.label.btn.add_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags hinzufügen"])},
  "shared.label.btn.complete_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überarbeitung beenden"])},
  "shared.label.btn.fix_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags korrigieren"])},
  "shared.label.btn.reach_upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wende dich für ein Upgrade an den Support"])},
  "shared.label.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Unternehmens"])},
  "shared.label.button.message_freelancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht an Freelancer*innen"])},
  "shared.label.card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "shared.label.card_holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des*der Karteninhabers*Karteninhaberin"])},
  "shared.label.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartennummer"])},
  "shared.label.cardholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des*der Karteninhabers*Karteninhaberin"])},
  "shared.label.change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "shared.label.change_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild ändern"])},
  "shared.label.characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichen"])},
  "shared.label.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "shared.label.chat.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "shared.label.choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum wählen"])},
  "shared.label.choose_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Bibliothek auswählen"])},
  "shared.label.choose_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle Überprüfer*innen aus"])},
  "shared.label.choose_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit wählen"])},
  "shared.label.choose_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in auswählen"])},
  "shared.label.choose_translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in(nen) auswählen"])},
  "shared.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "shared.label.click_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier klicken"])},
  "shared.label.click_to_deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Deaktivieren klicken"])},
  "shared.label.client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "shared.label.client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Kund*innen"])},
  "shared.label.client_not_freemium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der*die Kund*in ist nicht auf Freemium"])},
  "shared.label.coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demnächst verfügbar"])},
  "shared.label.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
  "shared.label.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
  "shared.label.company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmentelefon"])},
  "shared.label.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "shared.label.confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
  "shared.label.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "shared.label.confirmed_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigte Segmente"])},
  "shared.label.content_pillars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content-Säulen"])},
  "shared.label.content_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content-Typ"])},
  "shared.label.copyeditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texteditor*innen"])},
  "shared.label.copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting"])},
  "shared.label.copywriting_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis für Copywriting"])},
  "shared.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "shared.label.create_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Dienstleistung erstellen"])},
  "shared.label.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
  "shared.label.created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
  "shared.label.created_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellt am ", _interpolate(_named("date"))])},
  "shared.label.credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Guthaben"])},
  "shared.label.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
  "shared.label.current_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Guthaben"])},
  "shared.label.custom_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Rolle"])},
  "shared.label.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund*in"])},
  "shared.label.data_retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Dateien löschen nach"])},
  "shared.label.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "shared.label.deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgabetermin"])},
  "shared.label.decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "shared.label.decline_new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Preis ablehnen"])},
  "shared.label.declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
  "shared.label.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "shared.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "shared.label.description_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung (max. 500 Zeichen)"])},
  "shared.label.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "shared.label.education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
  "shared.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "shared.label.email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "shared.label.enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihr Passwort ein"])},
  "shared.label.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft ab"])},
  "shared.label.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
  "shared.label.failed_segments.count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markierte Segmente"])},
  "shared.label.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
  "shared.label.filter_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segmente filtern nach"])},
  "shared.label.final_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endergebnis"])},
  "shared.label.first_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Funktion"])},
  "shared.label.first_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Workflow"])},
  "shared.label.first_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Bibliothek"])},
  "shared.label.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "shared.label.flow_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow-Beschreibung"])},
  "shared.label.flow_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow-Name"])},
  "shared.label.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "shared.label.freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer*innen"])},
  "shared.label.from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
  "shared.label.from_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von - nach"])},
  "shared.label.from_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von (Jahr)"])},
  "shared.label.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "shared.label.generated_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generierter Inhalt"])},
  "shared.label.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Schritte"])},
  "shared.label.headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift"])},
  "shared.label.history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
  "shared.label.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "shared.label.human_ai_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI-Übersetzungen"])},
  "shared.label.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "shared.label.import_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets importieren"])},
  "shared.label.invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Email-Adresse ein"])},
  "shared.label.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung"])},
  "shared.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
  "shared.label.key_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselname"])},
  "shared.label.keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
  "shared.label.keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselwörter"])},
  "shared.label.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "shared.label.language_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachoptionen"])},
  "shared.label.language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachpaar"])},
  "shared.label.languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache(n)"])},
  "shared.label.last_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Änderung"])},
  "shared.label.last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bearbeitet"])},
  "shared.label.last_modified_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bearbeitet am"])},
  "shared.label.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "shared.label.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten"])},
  "shared.label.match_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trefferquote"])},
  "shared.label.max_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungslimit"])},
  "shared.label.minimum_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestguthaben"])},
  "shared.label.money_saved_tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geld gespart mit TM"])},
  "shared.label.multilingual_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrsprachige Datei"])},
  "shared.label.my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufgaben"])},
  "shared.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "shared.label.native_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muttersprache"])},
  "shared.label.native_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzerrolle"])},
  "shared.label.never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "shared.label.new_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aktion"])},
  "shared.label.new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "shared.label.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "shared.label.not_found_freelancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch keine Freelancer*innen für diesen Service hinzugefügt"])},
  "shared.label.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
  "shared.label.number_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Projekte"])},
  "shared.label.offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
  "shared.label.online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "shared.label.open_in_cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Content-Säulen anzeigen"])},
  "shared.label.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
  "shared.label.or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder"])},
  "shared.label.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
  "shared.label.pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten"])},
  "shared.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "shared.label.passwords_dont_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
  "shared.label.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
  "shared.label.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
  "shared.label.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
  "shared.label.period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
  "shared.label.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "shared.label.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "shared.label.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "shared.label.preferred_app_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugte App-Sprache"])},
  "shared.label.preferred_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugtes Datum"])},
  "shared.label.preferred_deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugter Abgabetermin"])},
  "shared.label.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "shared.label.previous_revised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige (überarbeitet)"])},
  "shared.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
  "shared.label.price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis ab"])},
  "shared.label.price_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis/Monat"])},
  "shared.label.price_per_amount_words": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Preis ab ", _interpolate(_named("amount")), " Wörter"])},
  "shared.label.price_per_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Aufgabe"])},
  "shared.label.price_per_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortpreis ab"])},
  "shared.label.price_without_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis (exkl. MwSt.)"])},
  "shared.label.pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
  "shared.label.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird bearbeitet"])},
  "shared.label.product_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktschlüssel"])},
  "shared.label.product_keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt-Schlüsselwörter"])},
  "shared.label.product_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbibliothek"])},
  "shared.label.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt"])},
  "shared.label.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "shared.label.project_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektkennung"])},
  "shared.label.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA"])},
  "shared.label.qa_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA-Parameter"])},
  "shared.label.qa_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA-Preis"])},
  "shared.label.qa_translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutachter*in"])},
  "shared.label.quality_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätsergebnisse"])},
  "shared.label.rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
  "shared.label.receipt_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse für die Rechnung"])},
  "shared.label.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
  "shared.label.replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen"])},
  "shared.label.replace_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles ersetzen"])},
  "shared.label.replace_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen mit"])},
  "shared.label.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur"])},
  "shared.label.review_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis überprüfen"])},
  "shared.label.reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfer*innen"])},
  "shared.label.reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfer*innen"])},
  "shared.label.reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
  "shared.label.revised_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrigiert am"])},
  "shared.label.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
  "shared.label.roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
  "shared.label.sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox-Umgebung"])},
  "shared.label.seacrh_and_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen und ersetzen"])},
  "shared.label.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "shared.label.see_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund*innen ansehen"])},
  "shared.label.segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segmente"])},
  "shared.label.select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle auswählen"])},
  "shared.label.select_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Liste auswählen"])},
  "shared.label.select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
  "shared.label.select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
  "shared.label.select_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe auswählen"])},
  "shared.label.select_workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow auswählen"])},
  "shared.label.service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungstyp"])},
  "shared.label.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
  "shared.label.set_as_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Standard einstellen"])},
  "shared.label.source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
  "shared.label.source_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelldatei"])},
  "shared.label.source_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgangssprache"])},
  "shared.label.source_lang_copyediting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "shared.label.source_language_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert für Ausgangssprache"])},
  "shared.label.source_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle(n)"])},
  "shared.label.source_term_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle: Glossar"])},
  "shared.label.specific_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifische Anweisungen"])},
  "shared.label.specific_instructions.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare anzeigen"])},
  "shared.label.specific_instructions.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare zu deiner*deinem Freelancer*in"])},
  "shared.label.specific_instructions_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwende den Tone of Voice unserer Marke"])},
  "shared.label.standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard (12 Monate)"])},
  "shared.label.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "shared.label.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum des Abonnements"])},
  "shared.label.start_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt starten"])},
  "shared.label.started_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begonnen"])},
  "shared.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "shared.label.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
  "shared.label.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
  "shared.label.supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister*in"])},
  "shared.label.supplier_margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistermarge"])},
  "shared.label.supported_formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützte Formate"])},
  "shared.label.supported_language_pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützte Sprachkombinationen"])},
  "shared.label.table_data_pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " -", _interpolate(_named("to")), " von ", _interpolate(_named("of"))])},
  "shared.label.tag_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag-Unstimmigkeit"])},
  "shared.label.tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "shared.label.target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
  "shared.label.target_initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zieltext (ursprünglich)"])},
  "shared.label.target_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielsprache"])},
  "shared.label.target_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielsprache(n)"])},
  "shared.label.target_revised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zieltext (überarbeitet)"])},
  "shared.label.target_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel(e)"])},
  "shared.label.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
  "shared.label.task_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabentyp"])},
  "shared.label.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
  "shared.label.team_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamadministrator*in"])},
  "shared.label.terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "shared.label.tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe"])},
  "shared.label.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitzone"])},
  "shared.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "shared.label.tm_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation-Memory-Übereinstimmung"])},
  "shared.label.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach"])},
  "shared.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
  "shared.label.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
  "shared.label.total_strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Strings"])},
  "shared.label.total_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks insgesamt"])},
  "shared.label.total_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Wörter"])},
  "shared.label.total_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Wörter"])},
  "shared.label.translate_yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbst übersetzen"])},
  "shared.label.translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzt"])},
  "shared.label.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung"])},
  "shared.label.translation_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsbibliothek"])},
  "shared.label.translation_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung & Revision"])},
  "shared.label.translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen"])},
  "shared.label.translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in"])},
  "shared.label.translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer*in(nen)"])},
  "shared.label.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auslöser"])},
  "shared.label.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "shared.label.type_keywords_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deine Schlüsselwörter hier ein"])},
  "shared.label.unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzt"])},
  "shared.label.unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl aufheben"])},
  "shared.label.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "shared.label.upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hochladen"])},
  "shared.label.upload_files.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder nach Dateien suchen"])},
  "shared.label.upload_files.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehe die Dateien zum Hochladen hierher"])},
  "shared.label.used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebraucht"])},
  "shared.label.user.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User*instatus festlegen"])},
  "shared.label.vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt-ID"])},
  "shared.label.vat_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MwSt. (25 %)"])},
  "shared.label.view_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektdetails öffnen"])},
  "shared.label.welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich willkommen"])},
  "shared.label.word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Wörter"])},
  "shared.label.word_count_pr_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortzahl pr. Sprachpaar"])},
  "shared.label.words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörter"])},
  "shared.label.worflow.name_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Workflowname existiert bereits"])},
  "shared.label.workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow"])},
  "shared.label.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "shared.label.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "shared.label.yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
  "shared.label.your_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Nachrichten"])},
  "shared.labels.characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichen"])},
  "shared.labels.created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
  "shared.labels.here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier."])},
  "shared.labels.or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
  "shared.labels.workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich"])},
  "shared.languages.aa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afar"])},
  "shared.languages.af": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrikaans"])},
  "shared.languages.am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amharic"])},
  "shared.languages.ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch"])},
  "shared.languages.ar_EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch (Ägypten)"])},
  "shared.languages.ar_MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch (Marokko)"])},
  "shared.languages.ar_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch (Sudan)"])},
  "shared.languages.ar_SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch (Syrien)"])},
  "shared.languages.az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijani"])},
  "shared.languages.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarussisch"])},
  "shared.languages.bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulgarisch"])},
  "shared.languages.bm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambara"])},
  "shared.languages.bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bengali"])},
  "shared.languages.bo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tibetisch"])},
  "shared.languages.bs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bosnisch"])},
  "shared.languages.byn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blin (Bilin)"])},
  "shared.languages.ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["katalanisch"])},
  "shared.languages.ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschetschenisch"])},
  "shared.languages.ckb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorani"])},
  "shared.languages.cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschechisch"])},
  "shared.languages.cy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walisisch"])},
  "shared.languages.da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dänisch"])},
  "shared.languages.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "shared.languages.de_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch (Österreich)"])},
  "shared.languages.de_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch (Schweiz)"])},
  "shared.languages.dv_MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhivehi"])},
  "shared.languages.dz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzongkha"])},
  "shared.languages.el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["griechisch"])},
  "shared.languages.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "shared.languages.en_AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch (Australien)"])},
  "shared.languages.en_GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisches (Vereinigtes Königreich)"])},
  "shared.languages.en_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (India)"])},
  "shared.languages.en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch (USA)"])},
  "shared.languages.en_ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch (Südafrika)"])},
  "shared.languages.eo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperanto"])},
  "shared.languages.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])},
  "shared.languages.es_419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch (Lateinamerika)"])},
  "shared.languages.es_ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch (europäisch)"])},
  "shared.languages.es_MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch (Mexiko)"])},
  "shared.languages.et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estnisch"])},
  "shared.languages.eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baskisch"])},
  "shared.languages.fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farsi"])},
  "shared.languages.fa_IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persisch (Farsi)"])},
  "shared.languages.ff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fula"])},
  "shared.languages.fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finnisch"])},
  "shared.languages.fil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipino"])},
  "shared.languages.fo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Färöisch"])},
  "shared.languages.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
  "shared.languages.fr_BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch (Belgien)"])},
  "shared.languages.fr_CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch (Kanada)"])},
  "shared.languages.fr_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch (Schweiz)"])},
  "shared.languages.fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch (Standard)"])},
  "shared.languages.fy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisian"])},
  "shared.languages.ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irisch"])},
  "shared.languages.gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["galizisch"])},
  "shared.languages.gu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
  "shared.languages.ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausa"])},
  "shared.languages.he": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hebräisch"])},
  "shared.languages.hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "shared.languages.hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kroatisch"])},
  "shared.languages.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ungarisch"])},
  "shared.languages.hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenisch"])},
  "shared.languages.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesisch"])},
  "shared.languages.ig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igbo"])},
  "shared.languages.is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isländisch"])},
  "shared.languages.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
  "shared.languages.it_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch (Schweiz)"])},
  "shared.languages.it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch (Standard)"])},
  "shared.languages.iu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inuktitut"])},
  "shared.languages.ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["japanisch"])},
  "shared.languages.jv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javanese"])},
  "shared.languages.ka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgian"])},
  "shared.languages.kk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakh"])},
  "shared.languages.kl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grönländisch)"])},
  "shared.languages.km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
  "shared.languages.kmr_SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurmanji (Syrien)"])},
  "shared.languages.kmr_TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurmanji (Türkei)"])},
  "shared.languages.kn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
  "shared.languages.ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
  "shared.languages.kri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krio"])},
  "shared.languages.kz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirgisisch"])},
  "shared.languages.la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latein"])},
  "shared.languages.lb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburgisch"])},
  "shared.languages.ln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingala"])},
  "shared.languages.lo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
  "shared.languages.lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["litauisch"])},
  "shared.languages.lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
  "shared.languages.man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandingo (Mandinka)"])},
  "shared.languages.mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagassisch"])},
  "shared.languages.mk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mazedonisch"])},
  "shared.languages.ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malayalam"])},
  "shared.languages.mn_MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolian"])},
  "shared.languages.mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
  "shared.languages.ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["malaiisch"])},
  "shared.languages.mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maltesisch"])},
  "shared.languages.my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["birmanisch"])},
  "shared.languages.nb_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegisch (Bokmål)"])},
  "shared.languages.ne_NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepali"])},
  "shared.languages.nl_BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisch (Belgien)"])},
  "shared.languages.nl_NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisch (Standard)"])},
  "shared.languages.nn_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegisch (Nynorsk)"])},
  "shared.languages.om": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oromo"])},
  "shared.languages.pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjabi"])},
  "shared.languages.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polieren"])},
  "shared.languages.prs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
  "shared.languages.ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pashto"])},
  "shared.languages.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch"])},
  "shared.languages.pt_BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch (Brasilien)"])},
  "shared.languages.pt_PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch (europäisch)"])},
  "shared.languages.rm_CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rätoromanisch (Schweiz)"])},
  "shared.languages.rn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirundi"])},
  "shared.languages.ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rumänisch"])},
  "shared.languages.ro_MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldauisch"])},
  "shared.languages.ro_RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rumänisch"])},
  "shared.languages.rom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romani"])},
  "shared.languages.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
  "shared.languages.rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinyarwanda"])},
  "shared.languages.se_FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sami"])},
  "shared.languages.si_LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singhalesisch"])},
  "shared.languages.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
  "shared.languages.sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowenisch"])},
  "shared.languages.so": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somali"])},
  "shared.languages.sq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanian"])},
  "shared.languages.sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serbisch"])},
  "shared.languages.sr_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbisch (Montenegro)"])},
  "shared.languages.sr_cyrl_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbisch (Kyrillisches Alphabet, Montenegro)"])},
  "shared.languages.sr_cyrl_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbisch (Kyrillisches Alphabet, Serbien)"])},
  "shared.languages.sr_latn_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbisch (Lateinisches Alphabet, Montenegro)"])},
  "shared.languages.sr_latn_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbisch (Lateinisches Alphabet, Serbien)"])},
  "shared.languages.sr_latn_SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbisch (Lateinisches Alphabet, Serbien)"])},
  "shared.languages.st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotho"])},
  "shared.languages.sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwedisch"])},
  "shared.languages.sw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swahili"])},
  "shared.languages.ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
  "shared.languages.te": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegu"])},
  "shared.languages.tg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajik"])},
  "shared.languages.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
  "shared.languages.ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tigrinya"])},
  "shared.languages.tig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiger"])},
  "shared.languages.tk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenisch"])},
  "shared.languages.tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagalog"])},
  "shared.languages.tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkisch"])},
  "shared.languages.tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twi"])},
  "shared.languages.ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uigurisch"])},
  "shared.languages.uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukrainisch"])},
  "shared.languages.ur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urdu"])},
  "shared.languages.uz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usbekisch"])},
  "shared.languages.vi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
  "shared.languages.wo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolof"])},
  "shared.languages.xh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xhosa"])},
  "shared.languages.yi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiddish"])},
  "shared.languages.yo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoruba"])},
  "shared.languages.zh_CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinfachtes Chinesisch)"])},
  "shared.languages.zh_HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditionelles Chinesisch (Hongkong)"])},
  "shared.languages.zh_TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditionelles Chinesisch (Taiwan)"])},
  "shared.languages.zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulu"])},
  "shared.loader.text.0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein linker Daumen zeigt nach rechts und dein rechter Daumen zeigt nach links."])},
  "shared.loader.text.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sorge – ein paar Tiere haben versucht, zu entkommen, aber wir haben sie gefangen"])},
  "shared.loader.text.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...zumindest bist du nicht in der Warteschleife..."])},
  "shared.loader.text.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie ist das Wetter so?"])},
  "shared.loader.text.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einhörner warten am Ende des Weges, versprochen"])},
  "shared.loader.text.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bauen die Gebäude so schnell wir können"])},
  "shared.mfa.authenticator.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiziere dein Konto mit dem Zwei-Faktor-Authentifizierungscode"])},
  "shared.mfa.banner_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge deinem Konto eine zusätzliche Sicherheitsebene hinzu, indem du die Zwei-Faktor-Authentifizierung aktivierst."])},
  "shared.mfa.banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung"])},
  "shared.mfa.confirm_password_disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um 2FA zu deaktivieren, musst du dein Passwort bestätigen."])},
  "shared.mfa.confirm_password_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um 2FA zu aktivieren, musst du dein Passwort bestätigen."])},
  "shared.mfa.enable_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du die Zwei-Faktor-Authentifizierung aktivierst, fügst du deinem Konto eine zusätzliche Sicherheitsebene hinzu. Bei der Anmeldung wirst du aufgefordert, einen Zufallscode einzugeben, der von der Authentifizierungs-App deiner Wahl generiert wird."])},
  "shared.mfa.enable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung aktivieren"])},
  "shared.mfa.enabled_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast die Zwei-Faktor-Authentifizierung bereits aktiviert. Du kannst sie deaktivieren, indem du unten auf „Deaktivieren“ klickst."])},
  "shared.mfa.recovery_code.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit einem Wiederherstellungscode verifizieren"])},
  "shared.mfa.scan_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne diesen QR mit der Authentifizierungs-App deiner Wahl und speichere deine Wiederherstellungscodes an einem sicheren Ort. QR-Codes und die anderen Codes werden nur einmal angezeigt und beim Schließen dieses Dialogfensters gelöscht."])},
  "shared.mfa.scan_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne deinen QR-Code"])},
  "shared.mfa.verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe deine Zwei-Faktor-Authentifizierung"])},
  "shared.modal.repetitions.linked_segments.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Segmente aus, für die du die Änderungen übernehmen möchtest"])},
  "shared.modal.repetitions.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du deine Änderung(en) für alle identischen Segmente übernehmen? Wenn du auf „Ja“ klickst, wird diese Funktion für alle identischen Segmente aktiviert"])},
  "shared.modal.repetitions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Segment kommt mehrfach vor."])},
  "shared.nav.account.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "shared.nav.logout.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "shared.nav.news.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten"])},
  "shared.nav.news.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was gibt's Neues?"])},
  "shared.nav.payment.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
  "shared.nav.settings.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "shared.news.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir entwickeln uns ständig weiter! Bleibe auf dem Laufenden über unsere neuesten Updates, Funktionen und vieles mehr."])},
  "shared.news.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste Updates"])},
  "shared.pagination.items_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente pro Seite"])},
  "shared.pagination.page_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du befindest dich auf Seite Nr."])},
  "shared.placceholder.no_result_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einträge gefunden"])},
  "shared.placeholder.caas_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaffee, dunkel, Espresso, Shop, 15 g, Koffein"])},
  "shared.placeholder.channel_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung (optional)"])},
  "shared.placeholder.channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "shared.placeholder.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "shared.placeholder.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier erscheint der Kommentar ..."])},
  "shared.placeholder.comment_freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterlasse einen Kommentar für deine*n Freelancer*in"])},
  "shared.placeholder.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "shared.placeholder.csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Datei einfügen"])},
  "shared.placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier erscheint die Beschreibung ..."])},
  "shared.placeholder.description_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Beschreibung eingeben"])},
  "shared.placeholder.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "shared.placeholder.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer"])},
  "shared.placeholder.filter_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelltext filtern"])},
  "shared.placeholder.filter_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zieltext filtern"])},
  "shared.placeholder.folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordnernamen"])},
  "shared.placeholder.format.csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".csv"])},
  "shared.placeholder.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
  "shared.placeholder.library_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliotheksname"])},
  "shared.placeholder.new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Name"])},
  "shared.placeholder.no_match_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Übereinstimmung gefunden"])},
  "shared.placeholder.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "shared.placeholder.project_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordnername (optional für 1 Datei, obligatorisch für mehrere)"])},
  "shared.placeholder.project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt-ID"])},
  "shared.placeholder.project_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname"])},
  "shared.placeholder.project_name_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname (optional)"])},
  "shared.placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche ..."])},
  "shared.placeholder.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Suchergebnisse werden hier angezeigt"])},
  "shared.placeholder.search_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach einem Namen oder einer Kennung suchen"])},
  "shared.placeholder.translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung"])},
  "shared.placeholder.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib hier deine Nachricht ein ..."])},
  "shared.placeholder.type_in_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib hier deine Antwort ein"])},
  "shared.placeholder.type_message_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib hier deine Nachricht ein"])},
  "shared.placeholder.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hier ablegen oder zum Hochladen klicken"])},
  "shared.qa.accuracy.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Bedeutung, Fehler in Zahlen und Verweisen, Auslassungen oder falsche Tags."])},
  "shared.qa.accuracy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
  "shared.qa.comment.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "shared.qa.error_severities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerklassen"])},
  "shared.qa.language.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichensetzungs-, Rechtschreibungs-, Großschreibungs- oder Grammatikfehler."])},
  "shared.qa.language.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "shared.qa.locale.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler in Länderstandards wie Datumsformate und Währungen sowie kulturelle Aspekte."])},
  "shared.qa.locale.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regionale Standards"])},
  "shared.qa.select_segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment auswählen, um QA zu bearbeiten"])},
  "shared.qa.status.critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritischer Fehler"])},
  "shared.qa.status.critical_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großes Manko: ein inakzeptabler Fehler, z.B. Fehlinterpretationen oder Fehler, die rechtliche, gesundheitliche oder finanzielle Folgen haben können."])},
  "shared.qa.status.major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptfehler"])},
  "shared.qa.status.major_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler in der Überschrift, ignorierte Übersetzungsanweisungen oder wesentliche Bedeutungsänderungen, die zu einer Fehlleitung oder Verzerrung des Produkts führen."])},
  "shared.qa.status.minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebenfehler"])},
  "shared.qa.status.minor_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichensetzungsfehler oder kleinere Tippfehler. Fehler, die zu geringfügigen Bedeutungsänderungen führen."])},
  "shared.qa.status.no-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Fehler"])},
  "shared.qa.style.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilistische Fehler, Missachtung bestimmter Richtlinien oder Fehler im Sprachregister/in der Sprachvariante."])},
  "shared.qa.style.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])},
  "shared.qa.terminology.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine einheitliche Verwendung von Terminologie oder Missachtung branchenüblicher/bereitgestellter Glossare."])},
  "shared.qa.terminology.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminologie"])},
  "shared.qa.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherung (QA)"])},
  "shared.reset_password.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "shared.reset_password.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib deine E-Mail-Adresse ein, um ein Zurücksetzen des Passworts anzufordern."])},
  "shared.section.text.gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine persönlichen Daten herunterladen und/oder löschen."])},
  "shared.section.title.transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparenz hat für uns oberste Priorität"])},
  "shared.status.action_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßnahmen erforderlich"])},
  "shared.status.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
  "shared.status.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
  "shared.status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "shared.status.in_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "shared.status.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "shared.status.in_progress_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung läuft"])},
  "shared.status.need_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung benötigt"])},
  "shared.status.not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht begonnen"])},
  "shared.status.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
  "shared.status.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird bearbeitet"])},
  "shared.status.project_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt abgebrochen"])},
  "shared.status.project_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt erstellt"])},
  "shared.status.project_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt abgelehnt"])},
  "shared.status.ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung abgeschlossen"])},
  "shared.status.unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verfügbar"])},
  "shared.stripe.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es gibt einige zusätzliche Schritte, die du ausführen musst, um dein ", _interpolate(_named("stripe")), "-Konto zu verifizieren und Zahlungen empfangen zu können."])},
  "shared.sub_nav.history.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
  "shared.sub_nav.profile.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "shared.terms.ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte am AI-Learning-Programm von EasyTranslate teilnehmen und von intelligenteren, schnelleren und datengesteuerten Analysen meiner Arbeit profitieren."])},
  "shared.terms.condition.btn.terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "shared.terms.conditions.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ich stimme den ", _interpolate(_named("terms_conditions")), " und ", _interpolate(_named("privacy_policy")), " von EasyTranslate zu. Ich erkläre mich außerdem damit einverstanden, von EasyTranslate A/S per E-Mail ", _interpolate(_named("marketing")), " zu ", _interpolate(_named("product_categories")), " zu erhalten, was ich jederzeit widerrufen kann."])},
  "shared.terms.dpa": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Klicken Sie ", _interpolate(_named("hier")), ", um unsere DPA herunterzuladen."])},
  "shared.terms.knowledge_base.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Base"])},
  "shared.terms.processors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Klicke hier ", _interpolate(_named("here")), " für weitere Informationen über unsere Unterdatenverarbeiter."])},
  "shared.terms.vat_id.example": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("label")), " Dänische Umsatzsteuer-Identifikationsnummer: DK12345678; Norwegische Umsatzsteuer-Identifikationsnummer: NO123456789 MVA; Schweizer Mehrwertsteuernummer: CHE 123.456.789 MWST / CHE 123.456.789 TVA / CHE 123.456.789 IVA"])},
  "shared.terms.vat_id.for_instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Beispiel:"])},
  "shared.terms.vat_id.requirment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Umsatzsteuer-Identifikationsnummer muss mit dem Code des jeweiligen Landes beginnen, gefolgt von einer Reihe von Ziffern und/oder Zeichen."])},
  "shared.text.create_your_client_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle dein EasyTranslate-Konto"])},
  "shared.text.iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Indem du deine IBAN angibst, ermächtigst du *Rocketship Inc* und Stripe, unseren Zahlungsdienstleister, Anweisungen an deine Bank zu senden, um dein Konto gemäß diesen Anweisungen zu belasten. Bei nachfolgenden Zahlungen hast du Anspruch auf eine Erstattung durch deine Bank gemäß den Nutzungsbedingungen deiner Bank. Eine Erstattung muss innerhalb von acht Wochen ab dem Datum der Belastung deines Kontos beantragt werden."])},
  "shared.text.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
  "shared.text.steps_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Schritte"])},
  "shared.text.upload_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Profilbild hochladen"])},
  "shared.text.your_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Kontoinformation"])},
  "shared.text.your_billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Rechnungsinformationen"])},
  "shared.text.your_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zahlungsmethode"])},
  "shared.title.hygraph_pat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Hygrapth PAT"])},
  "shared.toast.error.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine geschäftliche E-Mail-Adresse ein"])},
  "shared.toast.error.download_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Datei konnte nicht heruntergeladen werden, da die Datei nicht fertig ist oder die URL ungültig ist"])},
  "shared.toast.error.no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist nicht berechtigt, diese Aktion auszuführen. Bitte wende dich an deine Teamadministrator*innen (E-Mail der Teamadministrator*innen)"])},
  "shared.toast.error.redirect_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Versuch, dich zu Stripe weiterzuleiten, ist ein Fehler aufgetreten"])},
  "shared.toast.error.send_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Nachricht konnte nicht gesendet werden"])},
  "shared.toast.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "shared.toast.info.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "shared.toast.label.freelancer_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast dein Limit an Freelancer*innen erreicht. Bitte entferne eine*n Freelancer*in, bevor du jemand Neues hinzufügst."])},
  "shared.toast.message.file.notsupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateityp nicht unterstützt"])},
  "shared.toast.success.congrats_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glückwunsch!"])},
  "shared.toast.success.data_retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Einstellungen zur Speicherung personenbezogener Daten wurden aktualisiert"])},
  "shared.toast.success.finish_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Korrektur wurde als abgeschlossen markiert"])},
  "shared.toast.success.mfa_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zwei-Faktor-Authentifizierung wurde in deinem Profil deaktiviert"])},
  "shared.toast.success.mfa_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zwei-Faktor-Authentifizierung wurde in deinem Profil aktiviert"])},
  "shared.toast.success.password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein neues Passwort wurde gespeichert"])},
  "shared.toast.success.privacy_consent_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Datenschutz- und Einwilligungseinstellungen wurden aktualisiert."])},
  "shared.toast.success.requesst_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Anfrage zum Löschen dieses Kontos wurde gesendet. Der Vorgang kann bis zu 14 Werktage dauern."])},
  "shared.toast.success.request_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Löschanfrage wurde gesendet und wird in Kürze bearbeitet"])},
  "shared.toast.success.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Änderungen wurden gespeichert"])},
  "shared.toast.success.saved_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeichert"])},
  "shared.toast.success.send_forgot_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast die Wiederherstellung deines Passworts angefordert. Du erhältst in Kürze eine E-Mail mit Anweisungen."])},
  "shared.toast.success.subaccount_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Unterkonto wurde erstellt"])},
  "shared.toast.success.tag_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag-Korrektur abgeschlossen"])},
  "shared.toast.success.thanks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank!"])},
  "shared.toast.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "shared.toast.success.uncofirm_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Segmente wurden auf nicht bestätigt gesetzt"])},
  "shared.toast.success.update_profile_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Profilinformationen wurden aktualisiert"])},
  "shared.toast.warning.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung"])},
  "shared.tooltip.documents.word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedes Wort in deinem Dokument zählt zu der unten angegebenen Wortzahl"])},
  "shared.unsupported.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte versuche, von einem Desktop-Gerät aus auf den Editor zuzugreifen"])},
  "shared.unsupported.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Editor wird auf kleineren Bildschirmgrößen nicht unterstützt"])},
  "shared.user.status.not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht unterstützt"])},
  "shared.validation.password.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine geschäftliche E-Mail-Adresse ein"])},
  "shared.validation.password.min_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 6 Zeichen lang sein"])},
  "shared.validation.password.not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
  "shared.validation.password.strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen lang sein und Ziffern, Sonderzeichen, Klein- und Großbuchstaben enthalten"])},
  "shared.validation.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Telefonnummer ein"])},
  "shared.voucher.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erhalte einen Gutschein im Wert von ", _interpolate(_named("price")), " € und nutze alle unsere Funktionen kostenlos!"])},
  "supplier.account.delete_modal.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Konto entfernen?"])},
  "supplier.account.delete_modal.confirm_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchtest du das Konto ", _interpolate(_named("account")), " wirklich entfernen?"])},
  "supplier.account.delete_modal.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachte, dass das Konto dauerhaft gelöscht wird und dass diese Aktion nicht rückgängig gemacht werden kann"])},
  "supplier.availability.calendar.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Kalender"])},
  "supplier.availability.empty.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es scheint, als hättest du noch keine Abwesenheitszeit geplant"])},
  "supplier.availability.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Abwesenheit gefunden"])},
  "supplier.availability.modal_add.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle ein Start- und Enddatum für deine Abwesenheit aus."])},
  "supplier.availability.modal_add.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Abwesenheitszeit hinzufügen"])},
  "supplier.availability.modal_remove.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du dich an den folgenden Terminen auf verfügbar setzen möchtest?"])},
  "supplier.availability.modal_remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit entfernen"])},
  "supplier.availability.settings.days_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An welchen Tagen kannst du arbeiten?"])},
  "supplier.availability.settings.hourly_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist deine stündliche Wortleistung?"])},
  "supplier.availability.settings.hourly_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Stunden/Tag arbeitest du?"])},
  "supplier.availability.settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeitseinstellungen"])},
  "supplier.availability.settings.work_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lege die Start- und Endzeit deines Arbeitstages fest (von - bis)"])},
  "supplier.availability.time.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit hinzufügen"])},
  "supplier.availability.time.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Abwesenheit"])},
  "supplier.clients_list.no_clients_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisher hat noch kein*e Kund*in diese Dienstleistung genutzt."])},
  "supplier.clients_list.no_clients_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kund*innen"])},
  "supplier.content_pillars.editor.header.content_pillar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content-Säule"])},
  "supplier.content_pillars.editor.header.intro.text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bearbeitest gerade einen Text, der mithilfe von Eingaben deiner Kund*innen und relevanten Schlüsselwörtern von der KI generiert wurde."])},
  "supplier.content_pillars.editor.header.intro.text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte nutze das Referenzmaterial in der oberen Infobox – es beinhaltet wertvolle Informationen zu Kontext und Content-Typ, eine kurze Aufgabenbeschreibung und Schlüsselwörter."])},
  "supplier.content_pillars.editor.header.intro.text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für einen noch besseren Einblick stellen wir dir, falls verfügbar, den Link zu der Website zur Verfügung, von der die Schlüsselwörter stammen. Bitte bearbeite den Text sorgfältig entsprechend den Anforderungen deiner Kund*innen und nutze ggf. die Prompt-Funktion. Achte darauf, dass das Textfeld nur deinen endgültigen Text enthält, bevor du die Aufgabe abschließt."])},
  "supplier.content_pillars.editor.header.intro.thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank!"])},
  "supplier.content_pillars.editor.header.intro.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo, wir haben eine neue Textbearbeitungsaufgabe für dich!"])},
  "supplier.content_pillars.editor.header.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselwort"])},
  "supplier.content_pillars.error.task_id_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"task id is undefined\". Wir konnten Ihre Aufgabe nicht abschließen. Bitte neu laden und erneut versuchen."])},
  "supplier.finish_copywriting.toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast die Aufgabe erfolgreich abgeschlossen"])},
  "supplier.language_pair.delete.modal.confirmation_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchtest du die Dienstleistung ", _interpolate(_named("service")), " wirklich vom Konto", _interpolate(_named("account")), " löschen?"])},
  "supplier.language_pair.delete.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Sprachpaar löschen?"])},
  "supplier.language_pair.delete.modal.warning_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachte, dass die Dienstleistung dauerhaft gelöscht wird und dass diese Aktion nicht rückgängig gemacht werden kann"])},
  "supplier.messages.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du jederzeit effizient zu bestimmten Aufgaben mit deinen Kund*innen kommunizieren"])},
  "supplier.methods.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate wird deine Zahlungsinformationen niemals ohne dein Wissen und deine Zustimmung an Dritte weitergeben"])},
  "supplier.methods.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen leicht gemacht"])},
  "supplier.methods.btn.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Stripe-Konto ansehen"])},
  "supplier.methods.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Stripe, um sicherzustellen, dass du pünktlich bezahlt wist und deine persönlichen Daten und Bankdaten sicher sind."])},
  "supplier.methods.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
  "supplier.my_clients.btn.services_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen &amp; Preise ansehen"])},
  "supplier.nav.availability.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
  "supplier.nav.clients.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kund*innen"])},
  "supplier.nav.tasks.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufgaben"])},
  "supplier.news.03-07-2023.more_customers.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-user-friends"])},
  "supplier.news.03-07-2023.more_customers.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um für potenzielle Kund*innen noch sichtbarer zu werden, musst du dich nur für die Freigabe deines Profils auf unserer öffentlichen Website entscheiden, um in unseren Freelance-Katalog aufgenommen zu werden. Du kannst dich jederzeit abmelden, indem du diese Einstellung wieder auf die Standardeinstellung zurücksetzt."])},
  "supplier.news.03-07-2023.more_customers.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinne mehr Kund*innen!"])},
  "supplier.news.13-10-2023.content_pillars.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-microchip"])},
  "supplier.news.13-10-2023.content_pillars.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kund*in kann nun auf unkomplizierte Weise eine Content-Roadmap erstellen, die sich auf SEO-optimierte Inhalte konzentriert. Halten Sie Ausschau nach neuen Aufgaben. Sie ähneln Textbearbeitungsaufgaben, enthalten jedoch eine detaillierte Anleitung zur korrekten Anwendung des Tools, damit Sie Ihren Kund*innen bestmögliche Ergebnisse liefern können."])},
  "supplier.news.13-10-2023.content_pillars.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ab sofort steht Ihnen ein neues Feature zur Verfügung – Content-Säulen!"])},
  "supplier.news.14-04-2021.preferred_deadline.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-calendar-day"])},
  "supplier.news.14-04-2021.preferred_deadline.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein*e Kund*in hat jetzt die Möglichkeit, eine bevorzugte Abgabefrist festzulegen, die möglicherweise nicht zu deiner festgelegten Verfügbarkeit passt. Bitte bestätige den bevorzugten Abgabetermin, indem du die Aufgabe in Memsource annimmst oder lehne sie ab, wenn du die Frist nicht einhalten kannst. Wenn du den gewünschten Termin nicht einhalten kannst, wende dich bitte über die Nachrichtenfunktion der Plattform an deine*n Kund*in, um dich abzustimmen."])},
  "supplier.news.14-04-2021.preferred_deadline.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugter Abgabetermin"])},
  "supplier.news.16-09-2020.availability.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-calendar-day"])},
  "supplier.news.16-09-2020.availability.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir helfen dir bei der Berechnung der Lieferzeit für deine Kund*innen mit Verfügbarkeit. Du kannst jetzt unter anderem in Urlaubszeiten deine Verfügbarkeit einschränken, damit deine Kund*innen wissen, wann sie mit der Lieferung ihrer Übersetzungen rechnen können."])},
  "supplier.news.16-09-2020.availability.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
  "supplier.news.16-09-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-envelope"])},
  "supplier.news.16-09-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die Erfahrung gemacht, dass es wichtig ist, bei bestimmten Projekten direkt mit deinen Kund*innen kommunizieren zu können. Deshalb führen wir Nachrichten ein. Nachrichten ist deine Möglichkeit, direkt mit deinen Kund*innen zu kommunizieren. Um Kund*innen eine Nachricht zu schreiben, gehe zu dem Projekt, zu dem du Kund*innen eine Nachricht senden möchtest, und schreibe die Nachricht."])},
  "supplier.news.16-09-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur-Editor für den NEUEN Projekttyp „Korrektur“."])},
  "supplier.news.23-11-2020.review_editor.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "supplier.news.23-11-2020.review_editor.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endlich können wir unsere erste Version des EasyTranslate Review Editor zeigen, wodurch du deine Korrekturaufgabe direkt in der EasyTranslate Platform erledigen kannst. Die Korrekturen werden im Translation Memory aktualisiert, um zukünftige Übersetzungen sofort zu verbessern. Du kannst auch die Änderungen sehen, die der*die Kund*in nach der Lieferung vorgenommen hat, um zukünftige Verbesserungen vorzunehmen."])},
  "supplier.news.23-11-2020.review_editor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrektur-Editor für den NEUEN Projekttyp „Korrektur“."])},
  "supplier.news.24-11-2021.chat.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-comments"])},
  "supplier.news.24-11-2021.chat.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dir einen besseren visibility zu geben, was du schreibst, haben wir die Gestaltung der Chat-Eingabe im Editor reviewed and implemented. Du musst nicht mehr durch das Geschriebene navigieren, um es zu lesen oder zu ändern. Das Eingabefeld zeigt deine Inhalte jetzt in Zeilen an, um dir einen vollständigen Überblick zu geben. NB. Die Chat-Funktion ist nur verfügbar, wenn ein*e oder mehrere Übersetzer*innen deines Teams an dem Projekt beteiligt sind."])},
  "supplier.news.24-11-2021.chat.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "supplier.news.24-11-2021.history.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-history"])},
  "supplier.news.24-11-2021.history.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Korrektur-Editor von EasyTranslate hat eine new functionality dazubekommen, die dir gefallen wird: the history. Dank des Verlaufs hast du jederzeit den Überblick über alle an einem Segment vorgenommenen changes und kannst einfach den Korrekturprozess analysieren oder Restore previous versions – du hast die Wahl."])},
  "supplier.news.24-11-2021.history.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
  "supplier.news.25-01-2022.client_overview.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-list"])},
  "supplier.news.25-01-2022.client_overview.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben jetzt eine Kundenübersicht eingerichtet, damit du all your clients in a grid view sehen kannst, sowie welche Dienstleistungen du ihnen anbietest."])},
  "supplier.news.25-01-2022.client_overview.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund*innenübersicht"])},
  "supplier.news.25-01-2022.new_pricing.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-dollar-sign"])},
  "supplier.news.25-01-2022.new_pricing.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben jetzt einen neuen Preisplan für unsere Kund*innen eingeführt: freemium! Wenn sich Interessenten für den Plan anmelden, können sie dich als ihre*n Übersetzer*in auswählen und dir Projekte zusenden. Es funktioniert genauso wie jetzt, aber ohne Testübersetzungen!"])},
  "supplier.news.25-01-2022.new_pricing.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Preise"])},
  "supplier.news.25-01-2023.menu.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-bars"])},
  "supplier.news.25-01-2023.menu.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben deine Einstellungen und den Nachrichtenbereich im Profilmenü unten links in der Anwendung platziert. Wir haben auch die Nachrichtenfunktion in die linke Seitenleiste verschoben."])},
  "supplier.news.25-01-2023.menu.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen im Menü!"])},
  "supplier.news.29-03-2021.search_editor_feature.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa-file-alt"])},
  "supplier.news.29-03-2021.search_editor_feature.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Translation Memory und deine Term Bank sind jetzt im Editor-Tool zugänglich und können durchsucht werden, sodass du im Handumdrehen für Einheitlichkeit sorgen kannst, ohne die Seite verlassen zu müssen. Verwende das Suchfeld in der Korrekturphase, um Wörter nachzuschlagen und zu sehen, ob sie mit deinem Translation Memory und/oder deiner Termbank übereinstimmen. Alle gefundenen Übereinstimmungen werden im Quellenabschnitt Ihres Inhalts hervorgehoben. Musst du auf deine Zeichenanzahl achten? Diese sind jetzt auch in den Quellen- und Zielfeldern sichtbar."])},
  "supplier.news.29-03-2021.search_editor_feature.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche innerhalb der Editor-Funktion"])},
  "supplier.onboarding.about_you.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über mich"])},
  "supplier.onboarding.about_you.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzähle uns mehr über dich"])},
  "supplier.onboarding.payout.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
  "supplier.onboarding.payout.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Stripe, um sicherzustellen, dass du pünktlich bezahlt wirst und deine persönlichen Daten und Bankdaten sicher sind."])},
  "supplier.onboarding.payout.text_two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Klicke auf", _interpolate(_named("next")), ", um deine Zahlungen auf Stripe einzurichten."])},
  "supplier.onboarding.payout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zahlungsinformationen"])},
  "supplier.onboarding.services.hourly_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist deine stündliche Wortleistung?"])},
  "supplier.onboarding.services.hours_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Stunden arbeitest du pro Tag?"])},
  "supplier.onboarding.services.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Dienstleistungen"])},
  "supplier.onboarding.services.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie organisierst du deine Arbeit?"])},
  "supplier.onboarding.services.whih_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An welchen Tagen kannst du arbeiten?"])},
  "supplier.onboarding.services.working_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sind deine Arbeitszeiten? (von - bis)"])},
  "supplier.onboarding.success.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "supplier.onboarding.success.text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns, dass du dich für eine Partnerschaft mit uns entschieden hast! Deine Arbeit ist uns wichtig, und jetzt, wo du dein Konto eingerichtet hast, kann es losgehen."])},
  "supplier.onboarding.success.text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele Aufgaben kommen auf dich zu."])},
  "supplier.onboarding.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle dein Dienstleisterkonto"])},
  "supplier.profile.about.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate wird deine personenbezogenen Daten niemals ohne dein Wissen und deine Zustimmung an Dritte weitergeben"])},
  "supplier.profile.about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir geben deine Informationen nicht an andere weiter"])},
  "supplier.profile.catalogue.switch_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviere die Freigabe deines Profils auf unserer öffentlichen Website unter der folgenden Adresse:"])},
  "supplier.profile.catalogue.switch_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Freigabe deines Profils auf unserer öffentlichen Website unter der folgenden Adresse:"])},
  "supplier.profile.embed_code.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bettest du dieses Code-Snippets in deine Website, E-Mail-Signatur usw. ein, kannst du ein interaktives Bild hinzufügen, das User*innen zu deinem EasyTranslate-Profil weiterleitet."])},
  "supplier.profile.embed_code.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code für den Link zu deinem EasyTranslate-Profil einbetten"])},
  "supplier.profile.label.public_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zu deinem öffentlichen Profil"])},
  "supplier.profile.placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib hier deine Beschreibung ein ..."])},
  "supplier.save_document.toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument wurde erfolgreich gespeichert"])},
  "supplier.services.create.button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Dienstleistung erstellen"])},
  "supplier.services.create.modal.button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistung erstellen"])},
  "supplier.services.create.modal.select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Sprache für die neue Dienstleistung aus"])},
  "supplier.services.create.modal.select_language_pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle ein Sprachpaar für die neue Dienstleistung aus"])},
  "supplier.services.create.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Dienstleistung erstellen"])},
  "supplier.services.delete.modal.confirm_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchtest du die Dienstleistung ", _interpolate(_named("service")), " wirklich löschen?"])},
  "supplier.services.delete.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Dienstleistung löschen?"])},
  "supplier.services.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du deine Dienstleistungen und deren Preise einsehen und verwalten. Du kannst Dienstleistungen erstellen und löschen sowie deren Preise ändern."])},
  "supplier.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
  "supplier.status_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als nicht unterstützt markieren"])},
  "supplier.sub_nav.gdpr.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSGVO"])},
  "supplier.sub_nav.history.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
  "supplier.sub_nav.methods.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methoden"])},
  "supplier.sub_nav.services.btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
  "supplier.task.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabeninformationen"])},
  "supplier.terms.contract_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTranslate Partnerprogrammvertrag"])},
  "supplier.terms.contract_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ich bestätige, die Nutzungsbedingungen ", _interpolate(_named("link")), " gelesen zu haben und zu befolgen, um Sprachprojekte für Kund*innen zu bearbeiten.*"])},
  "supplier.terms.data_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme zu, die oben genannten Daten zu teilen und ein Übersetzerkonto zu erstellen, um mit der Plattform und den Kund*innen von EasyTranslate zu arbeiten.*"])},
  "supplier.toast.error.empty_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten deine Einladung nicht finden"])},
  "supplier.toast.error.invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Download ist etwas schief gelaufen. Bitte versuche es etwas später noch einmal."])},
  "supplier.toast.error.load_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Versuch, deine Aufgaben zu laden, ist etwas schief gelaufen"])},
  "supplier.toast.error.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Öffnen deiner Aufgabe ist etwas schief gelaufen"])},
  "supplier.toast.error.payout_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Laden deines Auszahlungsverlaufs ist etwas schief gelaufen"])},
  "supplier.toast.success.delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Dienstleistung wurde gelöscht"])},
  "supplier.toast.success.invitations_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einladungen wurden erfolgreich an die Dienstleister*innen versendet"])},
  "supplier.toast.success.update_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profilbild wurde aktualisiert"])},
  "suppliers.my_clients.title.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungs- und Korrekturdienstleistungen"])},
  "suppliers.my_clients.title.services_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HumanAI-Übersetzungsdienste"])},
  "suppliers.my_clients.title.services_copywriting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriting-Dienste"])},
  "suppliers.my_clients.title.services_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherungsdienstleistungen"])}
}
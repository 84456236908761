<template>
  <div
    class="header__inner__column header__breadcrumbs relative flex items-center text-base overflow-y-hidden overflow-x-scroll row-[2_/_-1] mobileLandscape:row-[1_/_-1] scrollbarHide"
  >
    <InlineSvg
      class="[&>path]:fill-black mr-2"
      :src="route.meta.icon"
      alt="dot"
      width="20"
    />
    <div
      v-for="(breadcrumb, i) in route.meta.breadcrumb"
      :key="i"
      class="header__breadcrumbs__container"
    >
      <Breadcrumb v-if="i === 0">
        <template #text>
          <BreadcrumbLink
            v-if="breadcrumb.hasOwnProperty('linkTo')"
            :route-to="{ name: breadcrumb.linkTo }"
            :title="breadcrumb.name"
          />
          <BreadcrumbText v-else :title="breadcrumb.name" />
        </template>
      </Breadcrumb>
      <Breadcrumb
        v-else-if="i > 0 && route.meta.breadcrumb.length - 1 !== i"
        :add-slash="true"
      >
        <template #text>
          <BreadcrumbLink
            v-if="breadcrumb.hasOwnProperty('linkTo')"
            :route-to="{ name: breadcrumb.linkTo }"
            :title="breadcrumb.name"
          />
          <BreadcrumbText v-else :title="breadcrumb.name" />
        </template>
      </Breadcrumb>
      <Breadcrumb v-else :add-slash="true">
        <template #text>
          <BreadcrumbLink
            :route-to="{ name: breadcrumb.linkTo }"
            :title="breadcrumb.name"
          />
        </template>
      </Breadcrumb>
    </div>

    <Breadcrumb
      v-if="route.name === 'clientFolder'"
      :add-slash="true"
      :is-active="route.name === 'clientFolder'"
    >
      <template #text>
        <BreadcrumbLink
          :title="folderName"
          :route-to="{ name: 'clientFolder' }"
        />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="route.name === 'clientProject'"
      :add-slash="true"
      :is-active="route.name === 'clientProject'"
    >
      <template #text>
        <BreadcrumbLink
          :title="projectName"
          :route-to="{ name: 'clientProject' }"
        />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="
        route.name === 'clientRevisionTask' ||
        route.name === 'clientRevisionEditor'
      "
      :add-slash="true"
      :is-active="true"
    >
      <template #text>
        <BreadcrumbLink
          :title="projectName"
          :route-to="{ name: 'clientProject' }"
        />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="route.name === 'clientLibrary'"
      :add-slash="true"
      :is-active="true"
    >
      <template #text>
        <BreadcrumbLink :title="libraryName" :route-to="{ name: null }" />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="route.name === 'clientRevisionEditor'"
      :add-slash="true"
      :is-active="true"
    >
      <template #text>
        <BreadcrumbLink :route-to="{ name: null }" :title="getTaskBreadcrumb" />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="route.name === 'clientAutomationFlow'"
      :add-slash="true"
      :is-active="true"
    >
      <template #text>
        <BreadcrumbLink
          data-cy="breadcrumb_workflow_title"
          :route-to="{ name: null }"
          :title="workflowTitle"
        />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="onTaskView"
      :add-slash="true"
      :is-active="
        onTaskView && Object.keys(currentTask.attributes).length === 0
      "
    >
      <template #text>
        <BreadcrumbLink
          :route-to="{ name: 'clientProject', id: route.params.id }"
          :title="reduceProjectTitle(projectName)"
        />
      </template>
    </Breadcrumb>
    <Breadcrumb
      v-if="onTaskView && currentTask.attributes"
      :add-slash="true"
      :is-active="onTaskView && Object.keys(currentTask.attributes).length > 0"
    >
      <template #text>
        <BreadcrumbLink
          :route-to="{ name: 'clientTask', id: route.params.id }"
          :title="currentTask.attributes.target_language"
        />
      </template>
    </Breadcrumb>
  </div>
</template>
<script setup>
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import BreadcrumbLink from '@/components/breadcrumbs/BreadcrumbLink'
import BreadcrumbText from '@/components/breadcrumbs/BreadcrumbText'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()

const folderName = ref('')
const projectName = ref('')
const libraryName = ref(null)

const currentFolder = computed(() => store.state.workspace.currentFolder)
const currentProject = computed(() => store.state.project.currentProject)
const library = computed(() => store.state.library.library)
const currentTask = computed(() => store.state.task.currentTask)
const getTaskBreadcrumb = computed(
  () => store.getters['task/getTaskBreadcrumb']
)
const workflowTitle = computed(
  () => store.getters['automationFlow/workflowTitle']
)
const onTaskView = computed(() => {
  return route.name === 'clientTask'
})

watch(
  () => currentFolder.value,
  (newFolder) => {
    if (Object.keys(newFolder).length > 0) {
      folderName.value = newFolder.attributes.name
    }
  }
)

watch(
  () => currentProject.value,
  (newProject) => {
    if (Object.keys(newProject).length > 0) {
      projectName.value = newProject.attributes.name
    }
  },
  { immediate: true }
)

watch(
  () => route.name,
  (name) => {
    if (name === 'clientLibrary') {
      setTimeout(() => {
        libraryName.value = library.value.attributes.name
      }, 1200)
    }
  },
  { deep: true, immediate: true }
)

function reduceProjectTitle(title) {
  return title.substring(0, 30)
}
</script>

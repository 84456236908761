<template>
  <ul
    class="relative text-black list-none mt-3 border-t border-b border-neutral-80"
  >
    <li class="cursor-pointer my-2 p-1">
      <div
        class="no-underline flex justify-between items-center w-full"
        @click="toggleDashboard"
      >
        <TextRegular bold>{{ $t('customer.nav.overview.title') }}</TextRegular>
        <i
          :class="{ '!rotate-90': showDashboard }"
          class="rotate-0 transition-transform will-change-transform duration-300 fas fa-chevron-right"
        />
      </div>
      <ul
        class="transition-all duration-500 bg-neutral-50 overflow-hidden max-h-0 mx-[-20px]"
        :class="{ 'max-h-[300px] py-2': showDashboard }"
      >
        <li
          v-for="link in routes.workspace"
          :key="link.title"
          class="px-5 py-1"
          @click="toggleSidebar(false)"
        >
          <router-link :to="{ name: link.route }">
            <span>{{ $t(link.title) }}</span>
          </router-link>
        </li>
      </ul>
    </li>
    <li class="cursor-pointer my-2 p-1">
      <div
        class="no-underline flex justify-between items-center w-full"
        @click="toggleCaas"
      >
        <TextRegular bold>{{ $t('shared.caas.title') }}</TextRegular>
        <i
          :class="{ '!rotate-90': showCaas }"
          class="rotate-0 transition-transform will-change-transform duration-300 fas fa-chevron-right"
        />
      </div>
      <ul
        class="transition-all duration-500 bg-neutral-50 overflow-hidden max-h-0 mx-[-20px]"
        :class="{ 'max-h-[300px] py-2': showCaas }"
      >
        <li
          v-for="link in routes.caas"
          :key="link.title"
          class="px-5 py-1"
          @click="toggleSidebar(false)"
        >
          <router-link :to="{ name: link.route }">
            <span>{{ $t(link.title) }}</span>
          </router-link>
        </li>
      </ul>
    </li>
    <li
      v-if="showContentPillars"
      class="cursor-pointer my-2 p-1"
      @click="toggleSidebar(false)"
    >
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'clientPillarsOverview' }"
      >
        <TextRegular bold>{{ $t('shared.label.content_pillars') }}</TextRegular>
      </router-link>
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSidebar(false)">
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'clientAutomationFlows' }"
      >
        <TextRegular bold>{{
          $t('customer.nav.automation_flows.btn')
        }}</TextRegular>
      </router-link>
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSidebar(false)">
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'clientLibrariesLayout' }"
      >
        <TextRegular bold>{{ $t('customer.nav.libraries.btn') }}</TextRegular>
      </router-link>
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSidebar(false)">
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'clientTranslators' }"
      >
        <TextRegular bold>{{ $t('customer.nav.translators.btn') }}</TextRegular>
      </router-link>
    </li>
    <li class="cursor-pointer my-2 p-1">
      <div
        class="no-underline flex justify-between items-center w-full"
        @click="toggleLangAssets"
      >
        <TextRegular bold>{{
          $t('customer.language_assets.title')
        }}</TextRegular>
        <i
          :class="{ '!rotate-90': showLangAssets }"
          class="rotate-0 transition-transform will-change-transform duration-300 fas fa-chevron-right"
        />
      </div>
      <ul
        class="transition-all duration-500 bg-neutral-50 overflow-hidden max-h-0 mx-[-20px]"
        :class="{ 'max-h-[300px] py-2': showLangAssets }"
      >
        <li
          v-for="link in routes.languageAssets"
          :key="link.title"
          class="px-5 py-1"
          @click="toggleSidebar(false)"
        >
          <router-link :to="{ name: link.route }">
            <span>{{ $t(link.title) }}</span>
          </router-link>
        </li>
      </ul>
    </li>
    <li class="cursor-pointer my-2 p-1" @click="handleInviteModal">
      <TextRegular
        bold
        class="no-underline flex justify-between items-center w-full"
        >{{ $t('customer.users_permissions.btn.invite') }}</TextRegular
      >
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSidebar(false)">
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'clientMarketplace' }"
      >
        <TextRegular bold>{{ $t('customer.nav.marketplace.btn') }}</TextRegular>
      </router-link>
    </li>
    <li class="cursor-pointer my-2 p-1">
      <a target="_blank" href="https://knowledge.easytranslate.com/">
        <TextRegular
          bold
          class="no-underline flex justify-between items-center w-full"
          >{{ $t('shared.terms.knowledge_base.title') }}</TextRegular
        ></a
      >
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSettings">
      <div class="no-underline flex justify-between items-center w-full">
        <TextRegular bold>{{ $t('shared.nav.settings.btn') }}</TextRegular>
        <i
          :class="{ '!rotate-90': showSettings }"
          class="rotate-0 transition-transform will-change-transform duration-300 fas fa-chevron-right"
        />
      </div>
      <ul
        class="transition-all duration-500 bg-neutral-50 overflow-hidden overflow-y-scroll max-h-0 mx-[-20px]"
        :class="{ 'max-h-[300px]': showSettings }"
      >
        <!-- Billing -->
        <SidebarDropdown
          :title="$t('customer.sub_nav.billing.btn')"
          :list="routes.billing"
          @clickTitle="handleBilling"
        />
        <!-- Team -->
        <SidebarDropdown :title="$t('shared.label.team')" :list="routes.team" />
        <!-- Account -->
        <SidebarDropdown
          :title="$t('customer.nav.account.btn')"
          :list="routes.account"
        />
      </ul>
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSidebar(false)">
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'news' }"
      >
        <TextRegular bold>{{ $t('shared.nav.news.btn') }}</TextRegular>
      </router-link>
    </li>
    <li class="cursor-pointer my-2 p-1" @click="toggleSidebar(false)">
      <router-link
        class="no-underline flex justify-between items-center w-full"
        :to="{ name: 'clientProgressOnboarding' }"
      >
        <TextRegular bold>{{ $t('customer.nav.progress.btn') }}</TextRegular>
      </router-link>
    </li>
    <li
      v-if="enableUpgrade"
      class="cursor-pointer my-2 p-1"
      @click="toggleUpgrade"
    >
      <TextRegular
        bold
        class="no-underline flex justify-between items-center w-full"
        >{{ $t('customer.upgrade_sub.upgrade') }}</TextRegular
      >
    </li>
    <li class="cursor-pointer my-2 p-1">
      <div
        class="no-underline flex justify-between items-center w-full"
        @click.prevent="toggleProfile"
      >
        <ProfilePhoto
          :url="currentUser.attributes.avatar"
          class="cursor-pointer border-2 border-primary-100"
        />
        <i
          :class="{ '!rotate-90': showProfile }"
          class="rotate-0 transition-transform will-change-transform duration-300 fas fa-chevron-right"
        />
      </div>
      <div
        class="transition-all duration-500 bg-neutral-50 overflow-hidden overflow-y-scroll max-h-0"
        :class="{ 'max-h-[300px]': showProfile }"
      >
        <ProfileMenuBody
          is-sidebar
          :show="showProfile"
          :show-accounts="showAccounts"
          @logout="logout"
          @setShowAccounts="setShowAccounts"
        />
        <ProfileMenuAccountList
          :show-accounts="showProfile && showAccounts"
          @setShowAccounts="setShowAccounts"
        />
      </div>
    </li>
  </ul>
</template>
<script setup>
import { TokenService } from '@/api/new/services/storage.service'
import ProfileMenuAccountList from '@/components/navbar/ProfileMenuAccountList'
import ProfileMenuBody from '@/components/navbar/ProfileMenuBody'
import ProfilePhoto from '@/components/shared/ProfilePhoto'
import TextRegular from '@/components/shared/font/text/TextRegular'
import SidebarDropdown from '@/components/sidebar/SidebarDropdown'
import i18n from '@/i18n'
import { animateScroll } from '@/utils/animateScroll'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const router = useRouter()
const $t = i18n.t

const showDashboard = ref(false)
const showSettings = ref(false)
const showProfile = ref(false)
const showAccounts = ref(false)
const showAssets = ref(false)
const showCaas = ref(false)
const showLangAssets = ref(false)

const routes = computed(() => store.state.navigation.routes)
const currentUser = computed(() => store.state.workspace.currentUser)
const enableUpgrade = computed(() => store.getters['account/enableUpgrade'])
const enableOnTesting = computed(() => store.getters['account/enableOnTesting'])

const showContentPillars = computed(() => {
  return process.env.VUE_APP_RELEASE_CONTENT_PILLARS == 'true'
})

watch(
  () => showSettings.value,
  (isOpen) => {
    let navigation = document.querySelector('.navigation__dropdown--settings')
    let items = [
      ...document.querySelectorAll(
        '.navigation__dropdown--settings .navigation__subnav'
      )
    ]
    if (isOpen) {
      let height = 0
      items.forEach((item) => {
        const offsetTop = item.getBoundingClientRect().top
        height += offsetTop
      })
      navigation.style.maxHeight = `${height}px`
    } else {
      navigation.style.maxHeight = `${0}px`
    }
  }
)

const startLoader = () => store.dispatch('loading/startLoader')
const stopLoader = () => store.dispatch('loading/stopLoader')
const getStateUsersInvitations = () =>
  store.dispatch('invitations/getStateUsersInvitations')
const logoutUser = () => store.dispatch('auth/logoutUser')
const getStateTeamUser = (payload) =>
  store.dispatch('teams/getStateTeamUser', payload)
const getStateBilling = () => store.dispatch('payment/getStateBilling')

const setEngStartTime = (payload) =>
  store.commit('workspace/setEngStartTime', payload)
const toggleSidebar = (payload) =>
  store.commit('navigation/toggleSidebar', payload)
const toggleUpgradeSubscriptionModal = () =>
  store.commit('modals/toggleUpgradeSubscriptionModal')
const toggleInviteUserModal = () => store.commit('modals/toggleInviteUserModal')

function handleBilling() {
  toggleSidebar(false)
  router.push({ name: 'clientPaymentBilling' })
}
function toggleDashboard() {
  showDashboard.value = !showDashboard.value
  showSettings.value = false
  showProfile.value = false
  showAssets.value = false
  showCaas.value = false
  showLangAssets.value = false
}
function toggleSettings() {
  showSettings.value = !showSettings.value
  showDashboard.value = false
  showProfile.value = false
  showAssets.value = false
  showCaas.value = false
  showLangAssets.value = false
}
function toggleProfile() {
  showProfile.value = !showProfile.value
  showDashboard.value = false
  showSettings.value = false
  showAssets.value = false
  showCaas.value = false
  showLangAssets.value = false
}
function toggleAssets() {
  showProfile.value = false
  showDashboard.value = false
  showSettings.value = false
  showCaas.value = false
  showLangAssets.value = false
  showAssets.value = !showAssets.value
}
function toggleCaas() {
  showProfile.value = false
  showDashboard.value = false
  showSettings.value = false
  showAssets.value = false
  showLangAssets.value = false
  showCaas.value = !showCaas.value
}
function toggleLangAssets() {
  showProfile.value = false
  showDashboard.value = false
  showSettings.value = false
  showAssets.value = false
  showCaas.value = false
  showLangAssets.value = !showLangAssets.value
}
function setShowAccounts(show) {
  if (show) {
    showAccounts.value = show
    // scrolls down when account list opens
    animateScroll({
      id: 'scrollSidebarTarget',
      targetPosition: 300,
      initialPosition: document.getElementById('scrollSidebarTarget').scrollTop,
      duration: 500
    })
  } else {
    // scrolls back to the top when account list closes
    setTimeout(() => {
      showAccounts.value = show
    }, 650)
    animateScroll({
      id: 'scrollSidebarTarget',
      targetPosition: 0,
      initialPosition: document.getElementById('scrollSidebarTarget')
        .scrollHeight,
      duration: 500
    })
  }
}
async function logout() {
  try {
    await logoutUser()
    await TokenService.removeAll()
  } finally {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      loginStatus: 'logged out',
      userId: currentUser?.value?.attributes?.hash
    })
    store.reset()
    router.push({ name: 'BaseLogin' })
  }
}
async function toggleUpgrade() {
  await getStateBilling()
  await getStateTeamUser(currentUser.value.id)
  setEngStartTime(Date.now())
  toggleSidebar(false)
  toggleUpgradeSubscriptionModal()
  router.replace({
    query: { ...route.query, upgradeSubscriptionStart: true },
    params: { upgradeUsage: true }
  })
}
async function handleInviteModal() {
  startLoader()
  try {
    await getStateUsersInvitations()
    toggleInviteUserModal()
  } finally {
    stopLoader()
  }
}
</script>

<template>
  <div class="[&>.warning]:!m-0">
    <slot name="label" />
    <div class="flex relative">
      <input
        :id="id"
        v-model="inputValue"
        :data-cy="id"
        class="block w-full p-2.5 pr-8 text-black placeholder:text-neutral-100 text-sm bg-neutral-50 border border-neutral-80 rounded-lg focus:border-primary-100 hover:border-primary-100 focus:ring-0"
        :class="{
          'bg-white': !disabled,
          '!text-error-100 !border-error-100': isRequired
        }"
        :autocomplete="autocomplete"
        :placeholder="`${placeholder}${indicator}`"
        :disabled="disabled"
        :type="showPassword ? 'text' : 'password'"
        @click="copyMode && handleCopy()"
      />
      <template v-if="copyMode">
        <div
          class="cursor-pointer absolute right-2.5 bottom-2.5"
          @click="emit('copy', inputValue)"
        >
          <font-awesome-icon icon="fa-solid fa-clipboard" />
        </div>
      </template>
      <template v-else-if="!isRequired">
        <div class="cursor-pointer absolute right-2.5 bottom-2.5">
          <i
            :class="['fas', `${showPassword ? 'fa-eye-slash' : 'fa-eye'}`]"
            @click="toggleSecret"
          />
        </div>
      </template>
      <template v-if="isRequired">
        <div class="absolute right-2.5 bottom-2.5">
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="text-error-100"
          />
        </div>
      </template>
    </div>
    <div v-if="showStrengthMeter" class="relative mt-2 w-8/12">
      <small>{{ $t('shared.label.password_strength') }}</small>
      <PasswordMeter :password="inputValue"> </PasswordMeter>

      <slot name="warning" />
    </div>
  </div>
</template>
<script setup>
import PasswordMeter from '@/components/shared/password/PasswordMeter'
import i18n from '@/i18n'
import { computed, onMounted, ref, watch } from 'vue'

const $t = i18n.t

const props = defineProps({
  value: String,
  disabled: Boolean,
  required: Boolean,
  optional: Boolean,
  placeholder: String,
  autocomplete: String,
  copyMode: Boolean,
  id: String,
  showStrengthMeter: {
    type: Boolean,
    default: false
  }
})
defineExpose({ triggerValidaton, resetValidation })
const emit = defineEmits(['change', 'copy'])

const showPassword = ref(false)
const inputValue = ref('')
const isRequired = ref(false)
const isCopied = ref(false)

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})

watch(
  () => inputValue.value,
  (value) => {
    emit('change', value)
    if (value === '') {
      isRequired.value = true
    } else {
      isRequired.value = false
    }
  }
)
watch(
  () => props.value,
  (value) => {
    inputValue.value = value
  }
)

onMounted(() => {
  inputValue.value = props.value
})

function handleCopy() {
  isCopied.value = true
  emit('copy', inputValue.value)
  setTimeout(() => {
    isCopied.value = false
  }, 5000)
}
function toggleSecret() {
  if (!props.copyMode) {
    showPassword.value = !showPassword.value
  }
}
function triggerValidaton() {
  isRequired.value = true
}
function resetValidation() {
  isRequired.value = false
}
</script>

<template>
  <Modal :show-modal="showModal" :loading="loading" @close="handleClose">
    <template #header>
      <TitleSmall
        v-if="newTemplate || copyTemplateFromDocument"
        class="text-center"
        :title="$t('shared.content_generation.create_new_template')"
      />
      <TitleSmall
        v-else
        class="text-center"
        :title="$t('shared.content_generation.edit_template')"
      />
    </template>
    <template #body>
      <InputField
        id="template_name_input"
        :value="template?.attributes?.title || ''"
        :required="true"
        :placeholder="$t('shared.content_generation.edit_template_placeholder')"
        @inputChange="handleTemplateName"
      />
      <div v-if="templateNameAlreadyExists" class="mt-1 text-error-100 text-sm">
        {{ $t('customer.template.modal.name_exists') }}
        <font-awesome-icon
          icon="fa-solid fa-triangle-exclamation"
          color="red"
        />
      </div>
      <CaasSidebar
        ref="caasSidebarRef"
        class="my-4"
        :existing-template="
          copyTemplateFromDocument ? null : template.attributes
        "
        :show-save-button="false"
        :new-template="newTemplate"
        @settings="handleSettings"
      />
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          class="w-full"
          :disabled="disableButton"
          data-cy="save"
          :title="$t('shared.content_generation.modals.save_template')"
          @click="handleSaveSettings"
        />

        <BtnSecondary
          :title="$t('shared.button.cancel')"
          type="button"
          data-cy="close"
          @click="handleClose"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import CaasSidebar from '@/components/contentGeneration/CaasSidebar'
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import InputField from '@/components/shared/input/InputField'
import i18n from '@/i18n'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const $t = i18n.t
const store = useStore()
const emit = defineEmits(['close'])

const props = defineProps({
  template: {
    type: Object,
    default: () => {
      return {}
    }
  },
  showModal: {
    type: Boolean,
    default: false
  },
  newTemplate: {
    type: Boolean,
    default: false
  },
  copyTemplateFromDocument: {
    type: Boolean,
    default: false
  }
})

const caasSidebarRef = ref(null)
const templateName = ref('')
const loading = ref(false)

const templates = computed(() => store.state.contentGeneration.templates)
const currentDocumentSettings = computed(
  () =>
    store.state.contentGeneration.currentDocument?.attributes?.meta[0]
      ?.attributes ?? null
)

const settings = ref({})

const getSettings = () => {
  if (props.copyTemplateFromDocument && currentDocumentSettings.value) {
    return {
      type: 'basic',
      maximum_word_length: currentDocumentSettings.value.maximum_word_length,
      creativity: currentDocumentSettings.value.creativity,
      frequency_penalty: currentDocumentSettings.value.frequency_penalty,
      presence_penalty: currentDocumentSettings.value.presence_penalty,
      subject: currentDocumentSettings.value.subject,
      content_brief: currentDocumentSettings.value.content_brief,
      tone_of_voice: currentDocumentSettings.value.tone_of_voice,
      content_type: currentDocumentSettings.value.content_type,
      keywords: currentDocumentSettings.value.keywords
    }
  } else if (props.template) {
    return { ...props.template.attributes }
  } else {
    return {
      type: 'basic',
      maximum_word_length: 1000,
      creativity: 0.5,
      frequency_penalty: 0,
      presence_penalty: 0,
      subject: null,
      content_brief: null,
      tone_of_voice: {},
      keywords: {},
      content_type: ''
    }
  }
}

const templatePayload = computed(() => {
  if (!settings.value) {
    return {}
  }
  return {
    ...(Object.values(props.template).length > 0
      ? { id: props.template.id }
      : {}),
    attributes: {
      title: templateName.value,
      maximum_word_length: settings.value.maximum_word_length,
      creativity: settings.value.creativity,
      presence_penalty: settings.value.presence_penalty,
      frequency_penalty: settings.value.frequency_penalty,
      subject: settings.value.subject,
      content_brief: settings.value.content_brief,
      tone_of_voice: settings.value.tone_of_voice,
      content_type: settings.value.content_type,
      keywords: settings.value.keywords
    }
  }
})

const templateNameAlreadyExists = computed(() => {
  return (
    !loading.value &&
    templates.value.some(
      (obj) =>
        obj.attributes.title.toUpperCase() ===
          templateName.value.toUpperCase() &&
        obj.attributes.title.toUpperCase() !=
          props.template?.attributes?.title.toUpperCase()
    )
  )
})

const disableButton = computed(() => {
  return !templateName.value || templateNameAlreadyExists.value || loading.value
})

const success = () => store.dispatch('toast/success')
const createTemplate = (payload) =>
  store.dispatch('contentGeneration/createTemplate', payload)
const updateTemplate = (payload) =>
  store.dispatch('contentGeneration/updateTemplate', payload)

onMounted(() => {
  settings.value = getSettings()
})

onBeforeUnmount(() => {
  settings.value = {}
})

function handleClose() {
  templateName.value = ''
  emit('close')
}

function handleSettings(value) {
  settings.value[value[0]] = value[1].value
}
function handleTemplateName(val) {
  templateName.value = val.value
}
function handleSaveSettings() {
  if (
    Object.values(props.template).length > 0 &&
    !props.copyTemplateFromDocument
  ) {
    handleUpdateTemplate()
  } else {
    handleCreateTemplate()
  }
}

async function handleCreateTemplate() {
  loading.value = true
  try {
    await handleTagsSettings()
    await createTemplate(templatePayload.value)
  } finally {
    handleClose()
    loading.value = false
    success({
      message: $t('customer.content_generation.template.save.success')
    })
  }
}

async function handleUpdateTemplate() {
  loading.value = true
  try {
    await handleTagsSettings()
    await updateTemplate(templatePayload.value)
  } finally {
    handleClose()
    loading.value = false
    success({
      message: $t('customer.content_generation.template.save.success')
    })
  }
}

async function handleTagsSettings() {
  const toneTags = caasSidebarRef.value?.toneTags
  const keywordsTags = caasSidebarRef.value?.keywordsTags
  const contentTags = caasSidebarRef.value?.contentTags
  if (toneTags) {
    if (toneTags.$el.tagifyValue) {
      settings.value.tone_of_voice = JSON.parse(toneTags.$el.tagifyValue).map(
        (tag) => tag.value
      )
    } else {
      settings.value.tone_of_voice = []
    }
  } else {
    settings.value.tone_of_voice = []
  }
  if (keywordsTags) {
    if (keywordsTags.$el.tagifyValue) {
      settings.value.keywords = JSON.parse(keywordsTags.$el.tagifyValue).map(
        (tag) => tag.value
      )
    } else {
      settings.value.keywords = []
    }
  } else {
    settings.value.keywords = []
  }
  if (contentTags) {
    if (contentTags.$el.tagifyValue) {
      settings.value.content_type = JSON.parse(contentTags.$el.tagifyValue)
        .map((tag) => tag.value)
        .join(', ')
    } else {
      settings.value.content_type = ''
    }
  } else {
    settings.value.content_type = ''
  }
  return Promise.resolve(settings.value)
}
</script>

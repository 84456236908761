<template>
  <div
    class="hidden desktop:z-[2] desktop:row-start-1 desktop:row-end-[-1] desktop:col-[1_/_1] desktop:border-r desktop:border-neutral-80 desktop:bg-white desktop:block"
    data-cy="navbar"
  >
    <div class="flex flex-col justify-between h-screen py-[10px]">
      <div class="grid grid-rows-[60px_repeat(8,_45px)]">
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.nav.dashboard.title')"
            class="w-full"
            :class="{ '[&>*]:!w-[80%] !mx-[5px]': expanded }"
          >
            <router-link :to="{ name: 'clientProgressOnboarding' }">
              <Logo :with-text="expanded" class="mr-1" />
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            data-cy="clientOverview_link"
            :disabled="expanded"
            :label="$t('customer.nav.overview.btn')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'clientOverview' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80': baseRoute === 'clientOverview' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 hover: hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'clientOverview'
                }"
              >
                <InlineSvg
                  class="[&>path]:fill-black"
                  :src="
                    baseRoute === 'clientOverview'
                      ? require('@/assets/icons/navbar/active/overview.svg')
                      : require('@/assets/icons/navbar/default/overview.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.nav.overview.btn') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('shared.caas.title')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'contentGenerationV2' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80':
                  baseRoute === 'contentGenerationV2' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'contentGenerationV2'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'contentGenerationV2'
                      ? require('@/assets/icons/navbar/active/content-generation.svg')
                      : require('@/assets/icons/navbar/default/content-generation.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('shared.caas.title') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          v-if="showContentPillars"
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('shared.label.content_pillars')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'clientPillarsOverview' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80': baseRoute === 'clientPillars' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'clientPillars'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'clientPillars'
                      ? require('@/assets/icons/navbar/active/seo-active.svg')
                      : require('@/assets/icons/navbar/default/seo.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('shared.label.content_pillars') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            data-cy="clientAutomationFlows_link"
            :disabled="expanded"
            :label="$t('customer.nav.automation_flows.btn')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'clientAutomationFlows' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80':
                  baseRoute === 'clientAutomationFlows' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'clientAutomationFlows'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'clientAutomationFlows'
                      ? require('@/assets/icons/navbar/active/automation.svg')
                      : require('@/assets/icons/navbar/default/automation.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.nav.automation_flows.btn') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.nav.libraries.btn')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'clientLibrariesLayout' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80': baseRoute === 'clientLibraries' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'clientLibraries'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'clientLibraries'
                      ? require('@/assets/icons/navbar/active/string-library.svg')
                      : require('@/assets/icons/navbar/default/string-library.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.nav.libraries.btn') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.nav.translators.btn')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'clientTranslators' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80': baseRoute === 'clientTranslators' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'clientTranslators'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'clientTranslators'
                      ? require('@/assets/icons/navbar/active/translators.svg')
                      : require('@/assets/icons/navbar/default/translators.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.nav.translators.btn') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.language_assets.title')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'translationMemories' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80':
                  (baseRoute === 'languageAssets' && expanded) ||
                  (baseRoute === 'importLanguageAssets' && expanded),
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'languageAssets' ||
                    baseRoute === 'importLanguageAssets'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'languageAssets' ||
                    baseRoute === 'importLanguageAssets'
                      ? require('@/assets/icons/navbar/active/assets.svg')
                      : require('@/assets/icons/navbar/default/assets.svg')
                  "
                  alt="dot"
                  width="30"
                  height="28"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.language_assets.title') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('shared.label.messages')"
            class="w-full !mx-[5px] [&>.tooltip]:!top-[-25%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'customerChat' }"
              class="w-full pr-[15px] rounded"
              :class="{
                'bg-primary-80': baseRoute === 'customerChat' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100 before:!content-['']"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'customerChat'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'customerChat'
                      ? require('@/assets/icons/navbar/active/chat.svg')
                      : require('@/assets/icons/navbar/default/chat.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('shared.label.messages') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
      </div>
      <!-- here -->
      <div class="grid grid-rows-[repeat(auto-fill,_70px) gap-y-1.5 bg-white">
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.users_permissions.btn.invite')"
            class="w-full !mx-[5px] cursor-pointer [&>.tooltip]:!top-[-5%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <a
              class="w-full rounded"
              :class="{
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
              @click="handleInviteModal"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100"
              >
                <InlineSvg
                  :src="
                    require('@/assets/icons/navbar/default/user-plus-solid.svg')
                  "
                  alt="dot"
                  width="20"
                  fill="#000000"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.users_permissions.btn.invite') }}
              </TextRegular>
            </a>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.nav.marketplace.btn')"
            class="w-full !mx-[5px] cursor-pointer [&>.tooltip]:!top-[-5%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <router-link
              :to="{ name: 'clientMarketplace' }"
              :class="{
                'bg-primary-80': baseRoute === 'clientMarketplace' && expanded,
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100"
                :class="{
                  'bg-primary-80 before:!absolute before:!h-full before:!w-1 before:!bg-primary-100 before:!left-[-10px] before:rounded-tr-[10px] before:rounded-br-[10px]':
                    baseRoute === 'clientMarketplace'
                }"
              >
                <InlineSvg
                  :src="
                    baseRoute === 'clientMarketplace'
                      ? require('@/assets/icons/navbar/active/app-marketplace.svg')
                      : require('@/assets/icons/navbar/default/app-marketplace.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.nav.marketplace.btn') }}
              </TextRegular>
            </router-link>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('shared.terms.knowledge_base.title')"
            class="w-full !mx-[5px] cursor-pointer [&>.tooltip]:!top-[-5%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <a
              class="w-full rounded"
              :class="{
                'flex flex-row justify-start items-center hover:bg-primary-80 [&>.navIcon]:hover:bg-primary-80 hover:text-primary-100':
                  expanded
              }"
              href="https://knowledge.easytranslate.com/"
              target="_blank"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px] hover:bg-primary-80 hover:text-primary-100"
              >
                <InlineSvg
                  class="mr-0.5"
                  :src="
                    require('@/assets/icons/navbar/default/lightbulb-regular.svg')
                  "
                  alt="dot"
                  width="16"
                  fill="#000000"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('shared.terms.knowledge_base.title') }}
              </TextRegular>
            </a>
          </Tooltip>
        </li>
        <li
          v-if="enableUpgrade"
          :class="[
            'flex justify-center px-[3px]',
            { '!justify-start items-center': expanded }
          ]"
          data-cy="toggle_upgrade"
          @click="toggleUpgrade"
          @mouseover="upgradeHover = true"
          @mouseleave="upgradeHover = false"
        >
          <Tooltip
            black-theme
            :disabled="expanded"
            :label="$t('customer.upgrade_sub.upgrade')"
            class="w-full !mx-[5px] cursor-pointer [&>.tooltip]:!top-[-5%]"
            :class="{ '[&>*]:!w-full': expanded }"
          >
            <div
              class="w-full rounded bg-success-50"
              :class="{
                'flex flex-row justify-start items-center': expanded
              }"
            >
              <span
                class="navIcon relative flex items-center justify-center rounded-sm w-[40px] h-[40px]"
              >
                <InlineSvg
                  class="kreep"
                  :src="
                    (showUpgradeSubscriptionModal &&
                      route.params.upgradeUsage == 'true') ||
                    upgradeHover
                      ? require('@/assets/icons/navbar/active/upgrade_hover.svg')
                      : require('@/assets/icons/navbar/default/upgrade_default.svg')
                  "
                  alt="dot"
                  width="20"
                />
              </span>
              <TextRegular v-if="expanded" semi-bold>
                {{ $t('customer.upgrade_sub.upgrade') }}
              </TextRegular>
            </div>
          </Tooltip>
        </li>
        <li
          :class="[
            'flex justify-center px-[3px] cursor-pointer',
            { '!justify-start items-center': expanded }
          ]"
        >
          <div
            class="w-full rounded !mx-[5px]"
            :class="{
              'flex flex-row justify-start items-center hover:bg-primary-80':
                expanded
            }"
            v-click-outside="onClickOutside"
          >
            <ProfileMenu
              :expanded="expanded"
              :show-profile="showProfile"
              data-cy="profile_menu"
              @logout="logout"
              @toggleProfile="toggleProfile"
            />
            <TextRegular v-if="expanded" semi-bold @click="toggleProfile">
              {{ profileName }}
            </TextRegular>
          </div>
        </li>
      </div>
    </div>
  </div>
</template>
<script setup>
import { TokenService } from '@/api/new/services/storage.service'
import ProfileMenu from '@/components/navbar/ProfileMenu'
import TextRegular from '@/components/shared/font/text/TextRegular'

import Tooltip from '@/components/shared/tooltip/Tooltip'
import Logo from '@/components/sidebar/Logo'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const route = useRoute()
const router = useRouter()

const props = defineProps({
  expanded: Boolean
})

const showProfile = ref(false)
const upgradeHover = ref(false)

const baseRoute = computed(() => store.state.navigation.baseRoute)
const currentUser = computed(() => store.state.workspace.currentUser)
const showUpgradeSubscriptionModal = computed(
  () => store.state.modals.showUpgradeSubscriptionModal
)
const enableUpgrade = computed(() => store.getters['account/enableUpgrade'])
const enableOnTesting = computed(() => store.getters['account/enableOnTesting'])

const profileName = computed(() => {
  return `${currentUser.value?.attributes?.first_name || ''} ${
    currentUser.value?.attributes?.last_name || ''
  }`
})

const showContentPillars = computed(() => {
  return process.env.VUE_APP_RELEASE_CONTENT_PILLARS == 'true'
})

const toggleUpgradeSubscriptionModal = () =>
  store.commit('modals/toggleUpgradeSubscriptionModal')
const toggleInviteUserModal = () => store.commit('modals/toggleInviteUserModal')
const setEngStartTime = (payload) =>
  store.commit('workspace/setEngStartTime', payload)
const getStateBilling = () => store.dispatch('payment/getStateBilling')
const getStateTeamUser = (payload) =>
  store.dispatch('teams/getStateTeamUser', payload)
const logoutUser = () => store.dispatch('auth/logoutUser')
const getStateUsersInvitations = () =>
  store.dispatch('invitations/getStateUsersInvitations')
const startLoader = () => store.dispatch('loading/startLoader')
const stopLoader = () => store.dispatch('loading/stopLoader')

async function logout() {
  startLoader()
  try {
    await logoutUser()
    await TokenService.removeAll()
  } finally {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      loginStatus: 'logged out',
      userId: currentUser.value?.attributes?.hash
    })
    stopLoader()
    store.reset()
    router.push({ name: 'BaseLogin' })
  }
}

function toggleProfile() {
  showProfile.value = !showProfile.value
}

async function toggleUpgrade() {
  await getStateBilling()
  await getStateTeamUser(currentUser.value.id)
  setEngStartTime(Date.now())
  toggleUpgradeSubscriptionModal()
  router.replace({
    query: { ...route.query, upgradeSubscriptionStart: true },
    params: { upgradeUsage: true }
  })
}

async function handleInviteModal() {
  startLoader()
  try {
    await getStateUsersInvitations()
    toggleInviteUserModal()
  } finally {
    stopLoader()
  }
}

function onClickOutside() {
  if (showProfile.value) {
    toggleProfile()
  }
}
</script>
<style lang="scss" scoped>
.kreep {
  animation: kreep 0.7s ease 2s infinite alternate;
}
.kreep:hover {
  animation: none !important;
}
@keyframes kreep {
  0% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }
  50% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5rem);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
